import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { brandRuthlessEmpress } from "@rubicon/antd-components";
import { Button, Flex, Typography } from "antd";
import { LabeledValue } from "antd/es/select";
import { Select } from "antd/lib";
import { FC } from "react";
import { TagRender } from "../../TagRender";
import { SelectNetwork, SelectSource } from "./Fields";

const classList = css`
    &:hover {
        cursor: default;
    }

    .ant-select-selector {
        border-radius: 2px;
    }

    .ant-select-arrow {
        display: none;
    }
`;

export interface SourceOptions extends LabeledValue {
    code: string;
}

export interface ExtendedUserIdOptionType extends LabeledValue {
    sourceId: number;
    networkId: number;
    code: string;
}

interface Props {
    selectedOptions: ExtendedUserIdOptionType[];
    sourceValue: SourceOptions | null;
    networkValue: LabeledValue | null;
    handleChangeSource: (value: SourceOptions | null) => void;
    handleChangeNetwork: (value: LabeledValue | null, allNetworks: { id: number; name: string }[]) => void;
    handleAdd: () => void;
    handleRemove: (value: ExtendedUserIdOptionType[]) => void;
}
export const ExtendedUserId: FC<Props> = ({
    selectedOptions,
    sourceValue,
    networkValue,
    handleAdd,
    handleRemove,
    handleChangeSource,
    handleChangeNetwork,
}) => {
    return (
        <>
            <Flex gap="small" style={{ marginBottom: 5 }}>
                <SelectSource value={sourceValue} handleChange={handleChangeSource} />
                <Typography.Text strong style={{ lineHeight: "32px" }}>
                    :
                </Typography.Text>
                <SelectNetwork value={networkValue} handleChange={handleChangeNetwork} sourceValue={sourceValue} />
                <Button
                    className={css`
                        &:not(:disabled) {
                            color: ${brandRuthlessEmpress};
                            border-color: ${brandRuthlessEmpress};
                        }
                    `}
                    icon={<PlusOutlined />}
                    onClick={handleAdd}
                    disabled={!sourceValue || !networkValue}
                >
                    Add Extended User ID
                </Button>
            </Flex>

            <Select
                className={classList}
                placeholder="Selected Extended User ID(s) are shown here"
                allowClear
                labelInValue
                open={false}
                mode="multiple"
                showSearch={false}
                onChange={(_, options: ExtendedUserIdOptionType[]) => handleRemove(options)}
                tagRender={TagRender}
                style={{ width: "100%", marginTop: "0.3rem" }}
                value={selectedOptions}
                options={selectedOptions}
            />
        </>
    );
};
