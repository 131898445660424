import { css } from "@emotion/css";
import { CloseOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Space, Typography, Flex } from "antd";
import { FC } from "react";
import { ClipBoardButton } from "@app/core/components";
import { useDetailsDrawerUnitLayout } from "./useDetailsDrawerUnitLayout";
import { useInventoryDetailsDrawer } from "../../useInventoryDetailsDrawer";
import { DetailsDrawerUnitLayoutToolbar } from "./DetailsDrawerUnitLayoutToolbar";
import { brandCobalt, gray7, gray8 } from "@rubicon/antd-components";
import { InventoryDetailsDrawerType } from "../../reducer";
import { AdUnit } from "@app/core/services";
import { useHistory } from "react-router-dom";

interface Props {
    adUnit: AdUnit | undefined;
    drawerType: InventoryDetailsDrawerType;
    title: string;
}

export const DetailsDrawerUnitLayout: FC<Props> = ({ adUnit, drawerType, title }) => {
    const history = useHistory();
    const { visibleCopy, copyUrl, handleHideCopy, handleVisibleCopy, gotoInventory, goBackUrl } =
        useDetailsDrawerUnitLayout();
    const { closeDetailsDrawer } = useInventoryDetailsDrawer();

    return (
        <div style={{ padding: "12px", width: "100%" }}>
            <Flex id={"unit-id"} data-sdet="inventory-details-drawer-header" gap="small" vertical justify="stretch">
                <Flex justify="space-between" style={{ marginBottom: 12 }}>
                    <Col style={{ flex: 95, display: "flex" }}>
                        <Space align="baseline">
                            {drawerType === InventoryDetailsDrawerType.AD_UNIT &&
                                adUnit?.supply.brand.entityType === "Channel" &&
                                goBackUrl && (
                                    <Button
                                        type="text"
                                        onClick={() => history.push(goBackUrl)}
                                        className={css`
                                            color: ${gray7};
                                            padding: 0;
                                            height: auto;

                                            &:hover {
                                                color: ${gray8};
                                                background-color: transparent;
                                            }
                                        `}
                                    >
                                        <ArrowLeftOutlined
                                            className={css`
                                                svg {
                                                    width: 1.15em;
                                                    height: 1.15em;
                                                }
                                            `}
                                        />
                                    </Button>
                                )}
                            <Typography.Title style={{ margin: 0 }} level={5}>
                                {title}
                            </Typography.Title>
                            <div onMouseEnter={handleVisibleCopy} onMouseLeave={handleHideCopy}>
                                <Space>
                                    <Button
                                        onClick={gotoInventory}
                                        type="link"
                                        className={css`
                                            height: 24px;
                                            padding: 0;
                                            font-size: 16px;
                                            color: ${brandCobalt};
                                        `}
                                    >
                                        Go to Inventory
                                        <ArrowRightOutlined />
                                    </Button>

                                    {visibleCopy && <ClipBoardButton copyText={copyUrl} />}
                                </Space>
                            </div>
                        </Space>
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <Button
                            type="text"
                            onClick={() => closeDetailsDrawer()}
                            className={css`
                                color: ${gray7};
                                padding: 0;
                                height: auto;

                                &:hover {
                                    color: ${gray8};
                                    background-color: transparent;
                                }
                            `}
                        >
                            <CloseOutlined
                                className={css`
                                    svg {
                                        width: 1.15em;
                                        height: 1.15em;
                                    }
                                `}
                            />
                        </Button>
                    </Col>
                </Flex>
            </Flex>
            <DetailsDrawerUnitLayoutToolbar />
        </div>
    );
};
