import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";
import { useMemo } from "react";

const POD_DEDUPING_LEVEL_HIGH = { value: 1, label: "High" };
const POD_DEDUPING_LEVEL_MEDIUM = { value: 2, label: "Medium" };
const POD_DEDUPING_LEVEL_LOW = { value: 3, label: "Low" };

const POD_DEDUPING_LEVEL_OPTIONS = [POD_DEDUPING_LEVEL_HIGH, POD_DEDUPING_LEVEL_MEDIUM, POD_DEDUPING_LEVEL_LOW];

interface UsePodDedupingLevel {
    options: { value: number | null; label: string }[];
}

const SEAT_POD_DEDUPING_LEVEL_MAP = {
    1: "High",
    2: "Medium",
    3: "Low",
};

export const usePodDedupeLevel = (): UsePodDedupingLevel => {
    const { seat } = usePublisherChannelFormInstance();

    const options = useMemo(() => {
        if (seat?.seatFlags?.podDeDupeMode) {
            const POD_DEDUPING_LEVEL_NONE_LABEL = `None (Currently set to ${
                SEAT_POD_DEDUPING_LEVEL_MAP[seat?.seatFlags?.podDeDupeMode]
            } at seat level)`;
            const POD_DEDUPING_LEVEL_NONE = { value: null, label: POD_DEDUPING_LEVEL_NONE_LABEL };
            return [...POD_DEDUPING_LEVEL_OPTIONS, POD_DEDUPING_LEVEL_NONE];
        }

        return POD_DEDUPING_LEVEL_OPTIONS;
    }, [seat]);

    return {
        options,
    };
};
