import { ShareHideCheckbox } from "./ShareHideCheckbox";
import { FC } from "react";

export const shareHideMap = (value: boolean): "Share" | "Hide" => (value ? "Share" : "Hide");

interface ShareHideCheckboxCellProps {
    index: number;
    isEditing: boolean;
    name: string;
    value: boolean;
}

export const ShareHideCheckboxCell: FC<ShareHideCheckboxCellProps> = ({ index, name, value, isEditing }) => {
    if (isEditing) {
        return <ShareHideCheckbox name={[index, name]} defaultChecked={value} />;
    }
    return <span>{shareHideMap(value)}</span>;
};
