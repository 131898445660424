import { useParams } from "react-router-dom";
import { FormInstance, notification } from "antd";
import {
    TransparencyDefaultRaw,
    useCreateTransparencyDefaultMutation,
    useUpdateTransparencyDefaultMutation,
} from "@app/core/services";
import { useState } from "react";
import { useIsEditing } from "./useIsEditing";
import { LabeledValue } from "antd/es/select";
import { mapUserIdMaskFormToApi } from "./AllowUserIdCell";
import { mapExtendedUserIdFormToApi } from "./AllowAllExtendedIdCell";
import { mapContentRuleFormToApi } from "./AllowContentCell";

interface TransparencyDefaultFormRow {
    transparency: TransparencyDefaultRaw;
    allowAllExtendedId: "all" | "specific" | "none";
    allowUserId: "hidden";
    allowContent: LabeledValue;
    allowBundleId: boolean;
    allowDomainName: boolean;
    allowIp: boolean;
    allowRef: boolean;
    allowSiteName: boolean;
    allowSitePage: boolean;
    allowStoreUrl: boolean;
    allowedExtendedIdTransparencies: LabeledValue[];
}

interface TransparencyDefaultForm {
    transparency: TransparencyDefaultFormRow[];
}

export const useTransparencyEditModeControls = (form: FormInstance) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { isEditing, setIsEditing } = useIsEditing();

    const [updateTransparencyDefault, { isLoading: isUpdating }] = useUpdateTransparencyDefaultMutation();
    const [createTransparencyDefault, { isLoading: isCreating }] = useCreateTransparencyDefaultMutation();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleModalOpen = () => setIsModalOpen(true);

    const handleOk = () => {
        setIsModalOpen(false);
        form.resetFields();
        setIsEditing(false);
    };
    const handleCancel = () => setIsModalOpen(false);

    const handleEditClick = () => {
        form.resetFields();
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        if (form.isFieldsTouched()) {
            return handleModalOpen();
        }
        form.resetFields();
        setIsEditing(false);
    };

    const handleSaveClick = (values: TransparencyDefaultForm) => {
        const payloads = values.transparency.map((value) => {
            const {
                transparency,
                allowUserId,
                allowContent,
                allowAllExtendedId,
                allowedExtendedIdTransparencies,
                ...booleanValues
            } = value;
            return {
                ...transparency,
                ...mapUserIdMaskFormToApi(allowUserId),
                ...mapContentRuleFormToApi(allowContent),
                ...mapExtendedUserIdFormToApi(allowAllExtendedId, allowedExtendedIdTransparencies),
                ...booleanValues,
                seat: {
                    id: Number(seatId),
                },
            };
        });

        const promises = payloads.map((payload) => {
            const cb = payload.id ? updateTransparencyDefault : createTransparencyDefault;

            return cb(payload).unwrap();
        });

        Promise.all(promises)
            .then(() => {
                notification.success({ message: "Transparency Defaults have been saved successfully" });
                setIsEditing(false);
                form.resetFields();
            })
            .catch((error) => {
                notification.error({ message: error.data?.errorDescription || "Something went wrong" });
            });
    };

    return {
        handleCancel,
        handleOk,
        handleModalOpen,
        open: isModalOpen,
        isEdit: isEditing,
        isSaving: isUpdating || isCreating,
        handleEditClick,
        handleCancelClick,
        handleSaveClick,
    };
};
