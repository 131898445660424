import { useContext, useState } from "react";
import {
    getPacingTypeHelpText,
    isPacingDeliveryScheduleDailyImpressionCapEligible,
    isPacingTypeEligible,
    isPacingTypeReadonly,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm";
import { usePacingFormData } from "../usePacingFormData";
import { PacingTypeOption } from "./types";
import { clearDailyImpressionCapFromSchedules } from "../../PacingDeliverySchedule/utils";

interface UsePacingTypeSelect {
    isReadonly: boolean;
    isVisible: boolean;
    helpText: string | undefined;
    onChange: (option: PacingTypeOption) => void;
}

export const usePacingTypeSelect = (): UsePacingTypeSelect => {
    const {
        forms: { adSourceFormMode, adSourceForm },
    } = useContext(AdSourceFormsContext);
    const {
        adSourceTypeId,
        pacingTypeId: selectedPacingTypeId,
        deliverySchedules,
        isDeliveryScheduleEnabled,
    } = usePacingFormData();
    const [hasChanged, setHasChanged] = useState(false);

    const isReadonly = isPacingTypeReadonly(adSourceFormMode, selectedPacingTypeId, hasChanged);

    const isVisible = isPacingTypeEligible(adSourceTypeId);

    const helpText = getPacingTypeHelpText(selectedPacingTypeId);

    const onChange = ({ id: pacingTypeId }: { id: number }) => {
        const wasImpressionCapEnabled = isPacingDeliveryScheduleDailyImpressionCapEligible(
            adSourceTypeId,
            selectedPacingTypeId,
            isDeliveryScheduleEnabled
        );
        const isImpressionCapEnabled = isPacingDeliveryScheduleDailyImpressionCapEligible(
            adSourceTypeId,
            pacingTypeId,
            isDeliveryScheduleEnabled
        );

        if (wasImpressionCapEnabled && !isImpressionCapEnabled) {
            const pacingSchedulesSansImpressionCap = clearDailyImpressionCapFromSchedules(deliverySchedules);
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name]: pacingSchedulesSansImpressionCap,
            });
        }

        setHasChanged(true);
    };

    return {
        isReadonly,
        isVisible,
        helpText,
        onChange,
    };
};
