import { FC, ReactNode } from "react";
import { Col, Row, Space, Typography, Form } from "antd";
import { Rule } from "antd/lib/form";

interface Props {
    condition: ReactNode;
    label: ReactNode;
    menu?: ReactNode;
    menuJustifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    mode?: ReactNode;
    rules?: Rule[];
    validateTrigger?: string | string[];
    value: ReactNode;
}

export const DimensionRow: FC<Props> = ({
    condition,
    label,
    menu = null,
    menuJustifyContent = "flex-end",
    mode = null,
    rules,
    validateTrigger,
    value,
}) => {
    return (
        <Form.Item name={`targeting${label}Validation`} rules={rules} validateTrigger={validateTrigger}>
            <Row gutter={8} align="top">
                <Col flex="150px">
                    <Typography.Text strong>{label}</Typography.Text>
                </Col>
                <Col flex="128px">{condition}</Col>
                {mode && <Col flex="128px">{mode}</Col>}
                <Col flex="1">{value}</Col>
                {menu && (
                    <Col flex="146px" style={{ display: "flex", justifyContent: menuJustifyContent }}>
                        <Space>{menu}</Space>
                    </Col>
                )}
            </Row>
        </Form.Item>
    );
};
