import { Form, Layout } from "antd";
import { FC } from "react";
import BrandSafetyToolbar from "./BrandSafetyToolbar";
import BrandSafetyHeader from "./BrandSafetyHeader";
import { BrandSafetyTableSwitch } from "./BrandSafetyTableSwitch";
import { useTransparencyEditModeControls } from "./TransparencyDefault/useTransparencyEditModeControls";

export const BrandSafetyPage: FC = () => {
    const [form] = Form.useForm();
    const { handleSaveClick, isSaving, handleCancel, handleCancelClick, handleEditClick, handleOk, isEdit, open } =
        useTransparencyEditModeControls(form);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSaveClick}
            wrapperCol={{ xs: 24, md: 12 }}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Layout>
                <BrandSafetyHeader
                    handleCancel={handleCancel}
                    handleCancelClick={handleCancelClick}
                    handleEditClick={handleEditClick}
                    handleOk={handleOk}
                    isEdit={isEdit}
                    isSaving={isSaving}
                    open={open}
                />
                <Layout.Content>
                    <BrandSafetyToolbar />
                    <BrandSafetyTableSwitch />
                </Layout.Content>
            </Layout>
        </Form>
    );
};
