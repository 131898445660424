import { FC } from "react";
import { Card, Col, Collapse, Row, Tag, Typography } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { SectionTitle } from "@app/core/components";
import { DEFAULT_TIME_ZONE, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { PacingDeliverySchedule } from "@app/core/services";
import { formatDateStringFromApi } from "@app/core/utils";
import { AD_SOURCE_FIELDS, cardBodyStyle } from "@app/features/seatAdSources/constants";
import { DELIVERY_SCHEDULE } from "./constants";
import { sortApiSchedules } from "./utils";

const { Text } = Typography;
const {
    PACING_DELIVERY_SCHEDULE_DATES,
    PACING_DELIVERY_SCHEDULE_IMPRESSION_GOAL,
    PACING_DELIVERY_SCHEDULE_DAILY_IMPRESSION_CAP,
} = AD_SOURCE_FIELDS;

interface Props {
    pacingDeliverySchedules: PacingDeliverySchedule[];
    timeZoneCode: string | undefined;
}

export const ReadOnlyPacingDeliverySchedule: FC<Props> = ({
    pacingDeliverySchedules,
    timeZoneCode = DEFAULT_TIME_ZONE.code,
}) => {
    const sortedPacingDeliverySchedules = sortApiSchedules(pacingDeliverySchedules);

    return (
        <Collapse
            data-sdet="read-only-delivery-schedule"
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    label: <SectionTitle title={DELIVERY_SCHEDULE} />,
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            {sortedPacingDeliverySchedules.map(
                                ({ startDate, endDate, impressionGoal, dailyCap }, index) => (
                                    <Row
                                        data-sdet={`schedule-${index}`}
                                        key={index}
                                        className={css`
                                            line-height: 42px;
                                        `}
                                        gutter={8}
                                    >
                                        <Col>
                                            <Text strong>Schedule {index + 1}</Text>
                                        </Col>
                                        <Col>
                                            <Tag>
                                                <Text type="secondary">{PACING_DELIVERY_SCHEDULE_DATES.label}: </Text>
                                                <Text>
                                                    {formatDateStringFromApi(
                                                        startDate,
                                                        timeZoneCode,
                                                        MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                                                    )}{" "}
                                                    <SwapRightOutlined />{" "}
                                                    {formatDateStringFromApi(
                                                        endDate,
                                                        timeZoneCode,
                                                        MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                                                    )}
                                                </Text>
                                            </Tag>
                                        </Col>
                                        {impressionGoal && (
                                            <Col>
                                                <Tag>
                                                    <Text type="secondary">
                                                        {PACING_DELIVERY_SCHEDULE_IMPRESSION_GOAL.label}:{" "}
                                                    </Text>
                                                    <Text>{impressionGoal}</Text>
                                                </Tag>
                                            </Col>
                                        )}
                                        {dailyCap && (
                                            <Col>
                                                <Tag>
                                                    <Text type="secondary">
                                                        {PACING_DELIVERY_SCHEDULE_DAILY_IMPRESSION_CAP.label}:{" "}
                                                    </Text>
                                                    <Text>{dailyCap}</Text>
                                                </Tag>
                                            </Col>
                                        )}
                                    </Row>
                                )
                            )}
                        </Card>
                    ),
                },
            ]}
        />
    );
};
