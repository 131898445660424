import { Form, Select } from "antd";
import { FC } from "react";

interface Props {
    index: number;
    allowUserId: boolean;
    isEditing: boolean;
    maskUserId: boolean;
}

export const mapUserIdMaskApiToReadOnly = (allowUserId: boolean, maskUserId: boolean) => {
    if (allowUserId && maskUserId) {
        return "Masked";
    }
    if (allowUserId && !maskUserId) {
        return "Shared";
    }
    return "Hidden";
};

export const ALLOW_USER_ID_OPTIONS = [
    {
        label: "Shared",
        value: "shared",
    },
    {
        label: "Hidden",
        value: "hidden",
    },
    {
        label: "Masked",
        value: "masked",
    },
];

export const mapUserIdMaskFormToApi = (allowUserId: "shared" | "hidden" | "masked") => {
    if (allowUserId === "masked") {
        return {
            allowUserId: true,
            maskUserId: true,
        };
    }
    if (allowUserId === "shared") {
        return {
            allowUserId: true,
            maskUserId: false,
        };
    }
    return {
        allowUserId: false,
        maskUserId: false,
    };
};

export const mapUserIdMaskApiToForm = (allowUserId: boolean, maskUserId: boolean) => {
    if (allowUserId && maskUserId) {
        return "masked";
    }
    if (allowUserId && !maskUserId) {
        return "shared";
    }
    return "hidden";
};

export const AllowUserIdCell: FC<Props> = ({ index, allowUserId, isEditing, maskUserId }) => {
    if (isEditing) {
        return (
            <Form.Item
                name={[index, "allowUserId"]}
                initialValue={mapUserIdMaskApiToForm(allowUserId, maskUserId)}
                style={{ marginBottom: 0 }}
            >
                <Select options={ALLOW_USER_ID_OPTIONS} style={{ minWidth: "100px" }} data-sdet="user-id-ifa-select" />
            </Form.Item>
        );
    }

    return <span>{mapUserIdMaskApiToReadOnly(allowUserId, maskUserId)}</span>;
};
