import { useHistory, useLocation } from "react-router-dom";
import { EDIT_MODE } from "../../tagParams/constants";
import { uri } from "@rubicon/utils";

export const useIsEditing = () => {
    const location = useLocation();
    const history = useHistory();
    const isEditingParam = uri.getSearchParam(location.search, EDIT_MODE.key);
    const isEditing = Boolean(isEditingParam);

    const setIsEditing = (nextIsEditing: boolean) => {
        if (nextIsEditing) {
            const nextSearch = uri.setSearchParam(location.search, EDIT_MODE.key, "enabled");
            history.push({ search: nextSearch });
            return;
        }
        const nextSearch = uri.deleteSearchParam(location.search, EDIT_MODE.key);
        history.push({ search: nextSearch });
    };

    return {
        setIsEditing,
        isEditing,
    };
};
