import { Store } from "rc-field-form/lib/interface";
import { AdUnit, AdUnitPayload, Seat } from "@app/core/services";
import {
    AD_UNIT_FORM_FIELDS,
    AD_UNIT_PRE_ROLL_TYPE,
    CUSTOM_MAX_EXTENDED,
    DEFAULT_AD_UNIT_START_DELAY,
    DEFAULT_UNWRAP_VAST,
    HierarchyFormsMode,
    INVENTORY_CUSTOM_CODE_TYPE,
    INVENTORY_GENERATED_CODE_TYPE,
    LINEARITY_BOTH_ALLOWED,
    NONE_OPTION,
    NOT_SPECIFIED_OPTION,
    OFF_WAIT_TIME,
    UNKNOWN_OPTION,
    YES_OPTION,
} from "@app/features/inventory/HierarchyForms/constants";
import {
    parseAdBreakRules,
    parseAdBreakRulesToApi,
    parseAdomainFreqCappingType,
    parseAdUnitAdditionalTime,
    parseAdUnitAdPodFillMode,
    parseAdUnitAllowPlaybackMethods,
    parseAdUnitBlockedCreativeAttributes,
    parseAdUnitBoxing,
    parseAdUnitContentChannel,
    parseAdUnitContentNetwork,
    parseAdUnitCreativeSamplesMinute,
    parseAdUnitImpressionWaitTime,
    parseAdUnitInterstitial,
    parseAdUnitLinearity,
    parseAdUnitLiveStream,
    parseAdUnitLiveStreamAcceleration,
    parseAdUnitMaxAdsPerPod,
    parseAdUnitMaxBitrate,
    parseAdUnitMaxDuration,
    parseAdUnitMaxExtended,
    parseAdUnitMaxPodSeconds,
    parseAdUnitMimes,
    parseAdUnitMinBitrate,
    parseAdUnitMinDuration,
    parseAdUnitMultiplicity,
    parseAdUnitOverrideInboundFloor,
    parseAdUnitPlacement,
    parseAdUnitPodEnforcement,
    parseAdUnitSeparationGroups,
    parseAdUnitSeparationMode,
    parseAdUnitSkip,
    parseAdUnitSkipafter,
    parseAdUnitSkipmin,
    parseAdUnitSsaiType,
    parseAdUnitStartDelay,
    parseAdUnitStatus,
    parseAdUnitSupportedApis,
    parseAdUnitSupportedProtocols,
    parseAdUnitType,
    parseAdUnitUdOptOut,
    parseAdUnitUnwrapVAST,
    parseAdUnitVideoDeliveries,
    parseAdvertiserBlockDomains,
    parseAffiliateCostModel,
    parseAffiliateCostValueFixed,
    parseAffiliateCostValuePercent,
    parseBlockedAdvertiserDomains,
    parseBuyerSeatList,
    parseChannelPlayListDefinitionMode,
    parseCodeValue,
    parseFreqCaps,
    parseFreqCapsToApi,
    parseInternalLabelValues,
    parseLabelValues,
    parseMaxAdsPerAdvertiser,
    parsePrefilterLabelValues,
    parsePublisherReauction,
    parseThirdPartyPixels,
} from "@app/features/inventory/HierarchyForms/helpers";
import { AdUnitForm } from "@app/features/inventory/HierarchyForms/AdUnitForm/useAdUnitForm";

interface GetAdUnitFormInitialValuesArgs {
    adUnit: AdUnit | undefined;
    mode: HierarchyFormsMode;
}

export const getAdUnitFormInitialValues = ({ adUnit, mode }: GetAdUnitFormInitialValuesArgs): Store => {
    const { preRoll, midRoll, postRoll } = parseAdBreakRules(mode, adUnit?.adBreakRules);
    const isCreateMode = mode === "create";
    const isCopyMode = mode === "copy";

    return {
        [AD_UNIT_FORM_FIELDS.NAME.name]: isCreateMode ? "" : isCopyMode ? `Copy of ${adUnit?.name}` : adUnit?.name,
        [AD_UNIT_FORM_FIELDS.CODE_TYPE.name]:
            isCreateMode || isCopyMode ? INVENTORY_GENERATED_CODE_TYPE.value : INVENTORY_CUSTOM_CODE_TYPE.value,
        [AD_UNIT_FORM_FIELDS.CODE.name]: isCreateMode || isCopyMode ? "" : adUnit?.code,
        [AD_UNIT_FORM_FIELDS.DESCRIPTION.name]: isCreateMode ? "" : adUnit?.description,
        [AD_UNIT_FORM_FIELDS.STATUS.name]: parseAdUnitStatus(mode, adUnit),

        // Brand Safety section
        [AD_UNIT_FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.name]: parseAdomainFreqCappingType(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAINS.name]: parseAdvertiserBlockDomains(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LIST.name]: parseBlockedAdvertiserDomains(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: parseBuyerSeatList(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name]: parseFreqCaps(mode, adUnit?.adomainFreqCappings),

        // Affiliate Section
        [AD_UNIT_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: parseAffiliateCostModel(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: parseAffiliateCostValuePercent(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: parseAffiliateCostValueFixed(mode, adUnit),

        //Supply Details section
        [AD_UNIT_FORM_FIELDS.LINEARITY.name]: parseAdUnitLinearity(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MULTIPLICITY.name]: parseAdUnitMultiplicity(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.TYPE.name]: parseAdUnitType(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.START_DELAY.name]: parseAdUnitStartDelay(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.VIDEO_PLAYBACKS.name]: parseAdUnitAllowPlaybackMethods(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.DELIVERY_METHODS.name]: parseAdUnitVideoDeliveries(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.SKIPPABLE.name]: parseAdUnitSkip(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.SKIPPABLE_AFTER.name]: parseAdUnitSkipafter(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.SKIPPABLE_MINIMUM.name]: parseAdUnitSkipmin(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.INTERSTITIAL.name]: parseAdUnitInterstitial(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.BOXING.name]: parseAdUnitBoxing(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.SSAI_TYPE.name]: parseAdUnitSsaiType(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name]: parseAdUnitImpressionWaitTime(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.CREATIVE_SAMPLES_MINUTE.name]: parseAdUnitCreativeSamplesMinute(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.UNWRAP_VAST.name]: parseAdUnitUnwrapVAST(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.LIVE_STREAM.name]: parseAdUnitLiveStream(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.CONTENT_CHANNEL.name]: parseAdUnitContentChannel(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.CONTENT_NETWORK.name]: parseAdUnitContentNetwork(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.UDE_OPT_OUT.name]: parseAdUnitUdOptOut(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.ALLOW_DEMAND_UNDER_INBOUND_FLOOR.name]: parseAdUnitOverrideInboundFloor(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name]: parseAdUnitLiveStreamAcceleration(mode, adUnit),

        // Internal Section
        [AD_UNIT_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : adUnit?.calculonUseRateOverride,
        [AD_UNIT_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: isCreateMode
            ? null
            : adUnit?.upperCalculonUseRateOverride,
        [AD_UNIT_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: parsePublisherReauction(mode, adUnit),

        // Demand Requirements section
        [AD_UNIT_FORM_FIELDS.MIMES.name]: parseAdUnitMimes(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.SUPPORTED_APIS.name]: parseAdUnitSupportedApis(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.PLACEMENT.name]: parseAdUnitPlacement(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.SUPPORTED_PROTOCOLS.name]: parseAdUnitSupportedProtocols(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MIN_DURATION.name]: parseAdUnitMinDuration(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MAX_DURATION.name]: parseAdUnitMaxDuration(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MAX_EXTENDED.name]: parseAdUnitMaxExtended(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.ADDITIONAL_TIME.name]: parseAdUnitAdditionalTime(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MIN_BITRATE.name]: parseAdUnitMinBitrate(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MAX_BITRATE.name]: parseAdUnitMaxBitrate(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.BLOCKED_ATTRIBUTES.name]: parseAdUnitBlockedCreativeAttributes(mode, adUnit),

        // Ad Pod Section
        [AD_UNIT_FORM_FIELDS.MAX_POD_SECONDS.name]: parseAdUnitMaxPodSeconds(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.MAX_ADS_PER_POD.name]: parseAdUnitMaxAdsPerPod(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.FILL_MODE.name]: parseAdUnitAdPodFillMode(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.POD_ENFORCEMENT.name]: parseAdUnitPodEnforcement(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: parseAdUnitSeparationMode(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: parseAdUnitSeparationGroups(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.POD_DEDUPE_LEVEL.name]: isCreateMode ? null : adUnit?.adUnitFlags?.podDeDupeMode,

        // Playlist Configuration Section
        [AD_UNIT_FORM_FIELDS.MAX_ADS_PER_ADV.name]: parseMaxAdsPerAdvertiser(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name]: parseChannelPlayListDefinitionMode(
            mode,
            adUnit?.playlistDefinitionMode?.id
        ),

        //Ad Break Rules Section
        [AD_UNIT_FORM_FIELDS.AD_BREAK_RULES_PRE.name]: preRoll,
        [AD_UNIT_FORM_FIELDS.AD_BREAK_RULES_MID.name]: midRoll,
        [AD_UNIT_FORM_FIELDS.AD_BREAK_RULES_POST.name]: postRoll,
        [AD_UNIT_FORM_FIELDS.CUE_POINTS.name]: isCreateMode ? [] : adUnit?.cuepoints,

        // Custom Pixels
        [AD_UNIT_FORM_FIELDS.CUSTOM_PIXELS.name]: parseThirdPartyPixels(mode, adUnit),

        // Labels
        [AD_UNIT_FORM_FIELDS.LABELS.name]: parseLabelValues(mode, adUnit),
        [AD_UNIT_FORM_FIELDS.INTERNAL_LABELS.name]: parseInternalLabelValues(mode, adUnit),

        // Waterfall Prefiltering
        [AD_UNIT_FORM_FIELDS.PREFILTER_LABEL_VALUES.name]: parsePrefilterLabelValues(mode, adUnit),
    };
};

interface ParseAdUnitFormValuesToApi {
    seat: Seat | null;
    supplyId: number;
    values: AdUnitForm;
    isSysAdmin: boolean;
    isPubAcctMgr: boolean;
    isTremorUser: boolean;
    mode: HierarchyFormsMode;
    hasInternalAccess: boolean;
}

export const parseAdUnitFormValuesToApi = ({
    seat,
    values,
    supplyId,
    isSysAdmin,
    isTremorUser,
    isPubAcctMgr,
    hasInternalAccess,
}: ParseAdUnitFormValuesToApi): AdUnitPayload => {
    const {
        // General Section
        name,
        code,
        codeType,
        description,
        status,

        // Affiliate Section
        affiliateCostModel,
        affiliateCostValueFixed,
        affiliateCostValuePercent,

        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs,
        advertiserBlockDomains,
        buyerSeatList,
        adomainFreqCappingType,
        adomainFreqCappings,

        //Demand Requirements Section
        additionalTime,
        blockedCreativeAttributes,
        maxBitrate,
        maxDuration,
        maxExtended,
        mimes,
        minBitrate,
        minDuration,
        placement,
        supportedApis,
        supportedProtocols,

        //Supply Details Section
        linearity,
        multiplicity,
        type,
        startDelay,
        allowedPlaybackMethods,
        videoDeliveries,
        skip,
        skipafter,
        skipmin,
        isInterstitial,
        isBoxingAllowed,
        ssaiType,
        impressionWaitTime,
        creativeSamplesMinute,
        unwrapVast,
        isLiveStream,
        contentChannel,
        contentNetwork,
        udeOptOut,
        overrideDynamicFloor,
        lsaEnabled,

        // Internal Section
        publisherReauction,
        upperCalculonUseRateOverride,
        calculonUseRateOverride,
        // Ad Pod Section
        maxPodSeconds,
        maxAdsPerPod,
        adPodFillMode,
        podEnforcement,
        separationGroups,
        podDeDupeMode,

        // Playlist Configuration Section
        maxAdsPerAdvertiser,
        playlistDefinitionMode,

        //Ad Break Rules Section
        adBreakRulesPre,
        adBreakRulesMid,
        adBreakRulesPost,
        cuepoints,

        // Custom Pixels
        thirdPartyPixels,

        // Labels
        labelValues,
        internalLabelValues,

        // Waterfall Prefiltering
        prefilterLabelValues,
    } = values;

    const payload: AdUnitPayload = {
        supply: { id: supplyId },
        // General Section
        name,
        code: parseCodeValue(codeType, code),
        description,
        status: { id: status },

        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs: (seatAdvertiserDomainFilterListDefs || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : [],
        buyerSeatList: buyerSeatList
            ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
            : null,
        adomainFreqCappingType: {
            id: adomainFreqCappingType.value as number,
            name: adomainFreqCappingType.label as string,
        },
        adomainFreqCappings: adomainFreqCappings ? parseFreqCapsToApi(adomainFreqCappings) : null,

        //Demand Requirements Section
        blockedCreativeAttributes: (blockedCreativeAttributes || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        minDuration,
        maxDuration,
        minBitrate,
        maxBitrate,
        maxExtended: maxExtended === CUSTOM_MAX_EXTENDED.value ? additionalTime : (maxExtended as number),
        mimes: (mimes || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        supportedApis: (supportedApis || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        placement: { id: placement.value as number, name: placement.label as string },
        supportedProtocols: (supportedProtocols || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        //Supply Details Section
        linearity: linearity === LINEARITY_BOTH_ALLOWED.value ? null : { id: linearity as number },
        multiplicity: { id: multiplicity },
        type: type
            ? { id: type.value as number, name: type.label as string }
            : { id: AD_UNIT_PRE_ROLL_TYPE.value, name: AD_UNIT_PRE_ROLL_TYPE.label },
        startDelay: startDelay ?? DEFAULT_AD_UNIT_START_DELAY,
        allowedPlaybackMethods: (allowedPlaybackMethods || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        videoDeliveries: (videoDeliveries || []).map((option) => ({
            id: option.value as number,
            name: option.label as string,
        })),
        skip: skip === UNKNOWN_OPTION.value ? null : (skip as boolean),
        skipafter: skip === YES_OPTION.value ? skipafter : null,
        skipmin: skip === YES_OPTION.value ? skipmin : null,
        isInterstitial,
        isBoxingAllowed,
        ssaiType:
            ssaiType.value !== NONE_OPTION.value
                ? { id: ssaiType.value as number, name: ssaiType.label as string }
                : null,
        impressionWaitTime:
            impressionWaitTime.value === OFF_WAIT_TIME.value
                ? null
                : { id: impressionWaitTime.value as number, name: impressionWaitTime.label as string },
        creativeSamplesMinute,
        unwrapVast: unwrapVast || DEFAULT_UNWRAP_VAST,
        isLiveStream,
        contentChannel,
        contentNetwork,
        udeOptOut,
        overrideDynamicFloor,
        lsaEnabled,
        // Ad Pod Section
        maxPodSeconds: maxPodSeconds || null,
        maxAdsPerPod: maxAdsPerPod || null,
        adUnitFlags: {
            podDeDupeMode: podDeDupeMode ?? null,
        },
        // Included into UD Configuration
        adPodFillMode: adPodFillMode ? { id: adPodFillMode } : null,
        podEnforcement: typeof podEnforcement === "number" ? { id: podEnforcement } : null,
        // Included into UD Configuration
        separationGroups: (separationGroups || []).map(({ value, label, iabCategories, industries }) => ({
            id: value,
            name: label,
            iabCategories,
            industries,
        })),

        // Playlist Configuration Section
        // Included into UD Configuration
        maxAdsPerAdvertiser: maxAdsPerAdvertiser ?? null,
        playlistDefinitionMode: playlistDefinitionMode ? { id: playlistDefinitionMode } : null,

        //Ad Break Rules Section
        adBreakRules: parseAdBreakRulesToApi(adBreakRulesPre, adBreakRulesMid, adBreakRulesPost),
        cuepoints: cuepoints ? cuepoints : null,

        // Custom Pixels
        thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
            id: pixel.value as number,
            name: pixel.label as string,
        })),

        // Labels
        labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),

        // Waterfall Prefiltering
        prefilterLabelValues: (prefilterLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
    };
    if (isTremorUser && seat?.affiliateCostEnabled) {
        Object.assign(payload, {
            // Affiliate Section
            affiliateCostModel: affiliateCostModel
                ? { id: affiliateCostModel.value, name: affiliateCostModel.label }
                : null,
            affiliateCostValuePercent: affiliateCostValuePercent,
            affiliateCostValueFixed:
                typeof affiliateCostValueFixed === "number" ? affiliateCostValueFixed * 1000 : null,
        });
    }
    if (isPubAcctMgr || isSysAdmin)
        Object.assign(payload, {
            // Internal Section
            publisherReauction: publisherReauction === NOT_SPECIFIED_OPTION.value ? null : publisherReauction,
            upperCalculonUseRateOverride,
            calculonUseRateOverride,
        });

    if (hasInternalAccess)
        Object.assign(payload, {
            // Labels
            internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
        });
    return payload;
};
