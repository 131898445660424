import { FC } from "react";
import { Form } from "antd";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    PacingDeliverySchedule,
    FormPacingDeliverySchedule,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { dealFormPacingDeliverySchedulesChange } from "@app/features/deals/DealForm/reducer";

interface Props {
    mode: DealFormMode;
}

export const AdSourceDeliverySchedule: FC<Props> = ({ mode }) => {
    const dispatch = useAppDispatch();
    const form = Form.useFormInstance();

    const pacingTypeId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE]
    );
    const startDate = Form.useWatch(CREATE_DEAL_FORM_ITEMS_NAME.START_DATE, form);
    const endDate = Form.useWatch(CREATE_DEAL_FORM_ITEMS_NAME.END_DATE, form);
    const impressions = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]);

    const onChange = (schedules: FormPacingDeliverySchedule[]) => {
        dispatch(dealFormPacingDeliverySchedulesChange(schedules));
    };

    return (
        <div style={{ marginBottom: "1.5rem" }}>
            <PacingDeliverySchedule
                name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULES}
                pacingTypeId={pacingTypeId}
                startDate={startDate}
                endDate={endDate}
                totalImpressions={impressions}
                formMode={mode}
                onChange={onChange}
            />
        </div>
    );
};
