import { FC } from "react";
import { EditModeControls } from "@app/core/components/EditModeControls";

interface Props {
    handleCancel: () => void;
    handleCancelClick: () => void;
    handleEditClick: () => void;
    handleOk: () => void;
    isEdit: boolean;
    isSaving: boolean;
    open: boolean;
}

export const TransparencyEditModeControls: FC<Props> = ({
    handleCancel,
    handleCancelClick,
    handleEditClick,
    handleOk,
    isEdit,
    isSaving,
    open,
}) => {
    return (
        <EditModeControls
            disabled={isSaving}
            discardText="Are you sure you want to proceed without saving the changes"
            isEdit={isEdit}
            isSaving={isSaving}
            open={open}
            handleDiscardModalCancel={handleCancel}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleOk={handleOk}
        />
    );
};

export default TransparencyEditModeControls;
