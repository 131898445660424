import { Form, Switch } from "antd";
import { FC } from "react";

interface Props {
    defaultChecked: boolean;
    name: string | [number, string];
    sdet?: string;
}

export const ShareHideCheckbox: FC<Props> = ({ defaultChecked, name, sdet }) => {
    return (
        <Form.Item name={name} initialValue={defaultChecked} style={{ marginBottom: 0 }}>
            <Switch checkedChildren="Share" unCheckedChildren="Hide" defaultChecked={defaultChecked} data-sdet={sdet} />
        </Form.Item>
    );
};
