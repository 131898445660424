import { FC, ReactNode } from "react";
import { Button, Col, Drawer, Input, Row, Typography } from "antd";

interface Props {
    extra?: ReactNode;
    handleAdd: () => void;
    handleChangeValue: (e: string) => void;
    handleClose: () => void;
    handleOpen: () => void;
    handleReplace: () => void;
    isLoadingAdd: boolean;
    isLoadingReplace: boolean;
    isOpen: boolean;
    placeholder: string;
    title: string;
    value: string | number | readonly string[];
    invalidValues?: string[];
    isEmpty?: boolean;
}

export const BulkUploadDrawerButton: FC<Props> = ({
    extra,
    handleAdd,
    handleChangeValue,
    handleClose,
    handleOpen,
    handleReplace,
    isLoadingAdd,
    isLoadingReplace,
    isOpen,
    placeholder,
    title,
    value,
    invalidValues,
    isEmpty,
}) => {
    return (
        <>
            <Drawer
                title={title}
                open={isOpen}
                onClose={handleClose}
                footer={
                    <Row justify="end" gutter={8}>
                        <Col>
                            <Button
                                onClick={handleReplace}
                                loading={isLoadingReplace}
                                data-sdet="btn-bulk-upload-drawer-replace-existing"
                            >
                                Replace Existing
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={handleAdd}
                                loading={isLoadingAdd}
                                data-sdet="btn-bulk-upload-drawer-add-to-existing"
                            >
                                Add To Existing
                            </Button>
                        </Col>
                    </Row>
                }
                data-sdet="drawer-bulk-upload-drawer"
            >
                {extra}
                {invalidValues && invalidValues.length > 0 && (
                    <Typography.Text type="danger">The below values are invalid</Typography.Text>
                )}
                {isEmpty && <Typography.Text type="danger">Please enter a valid value</Typography.Text>}
                <Input.TextArea
                    onChange={(e) => handleChangeValue(e.target.value)}
                    placeholder={placeholder}
                    style={{ height: "90%" }}
                    value={value}
                    data-sdet="text-area-bulk-upload-drawer"
                />
            </Drawer>
            <Button type="link" onClick={handleOpen} data-sdet="btn-bulk-upload-drawer-open">
                Bulk Load
            </Button>
        </>
    );
};
