import { FC, useState } from "react";
import { Input } from "antd";
import { Conditions, TargetingDimensionTypeLabels } from "../../constants";
import { BundleIdTargetsDimension, UsedDimensions } from "../../types";
import { ConditionSelect } from "../ConditionSelect";
import { MoreMenu } from "../MoreMenu";
import { DimensionRow } from "./DimensionRow";

interface Props {
    dimension: BundleIdTargetsDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeDimensionValues: (value: string[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

const mapBundleIdFormToApi = (formValue: string | undefined) => (formValue || "").split("\n").filter(Boolean);

export const BundleIdTargetsDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    const [text, setText] = useState<string>(dimension.values.join("\n"));
    const handleFinishedText = ({ target: { value } }) => {
        const domains = mapBundleIdFormToApi(value);
        onChangeDimensionValues(domains);
        setText(domains.join("\n"));
    };

    return (
        <DimensionRow
            rules={[
                {
                    validator: () => {
                        return new Promise((resolve, reject) => {
                            const value = mapBundleIdFormToApi(text);

                            if (!value.length || value.every((v) => !v)) {
                                return reject();
                            }
                            return resolve(true);
                        });
                    },
                    message: "Must include at least one Bundle ID",
                },
            ]}
            validateTrigger={["onBlur", "onChange"]}
            label={TargetingDimensionTypeLabels.BundleIdTargets}
            condition={
                <ConditionSelect
                    onChange={onChangeCondition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.bundleIdTargets.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.bundleIdTargets.includes
                    }
                    value={dimension.condition}
                />
            }
            value={
                <Input.TextArea
                    data-sdet="BundleIDsDimensionSelect"
                    allowClear
                    autoSize
                    onBlur={handleFinishedText}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={`Enter ${TargetingDimensionTypeLabels.BundleIdTargets} one per line`}
                    value={text}
                />
            }
            menu={
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={
                        usedDimensions.bundleIdTargets.includes || usedDimensions.bundleIdTargets.excludes
                    }
                />
            }
        />
    );
};
