import { Button, Form, Input, Space, Typography, notification } from "antd";
import { CLASSIFICATIONS_LABEL_FORM_ITEMS, CLASSIFICATIONS_LABEL_LABEL_VALUES_INPUT_SDET } from "../constants";
import { Label, useCreateLabelValuesMutation } from "@app/core/services";
import { FC } from "react";
import { getLabelValuesPayload } from "../helpers";
import { ClassificationsLabelFormLabelValuesTable } from "./ClassificationsLabelFormLabelValuesTable";
import { useNotificationError } from "@app/core/components/hooks";

interface Props {
    label: Label;
}

export const CLASSIFICATIONS_LABEL_VALUES_FORM_SDET = "classifications-label-values-form";

export const ClassificationsLabelValuesForm: FC<Props> = ({ label }) => {
    const [createClassificationsLabelValues, { isLoading, error }] = useCreateLabelValuesMutation();
    const isDistributionGroup: boolean = !!label?.isDistributionGroup;
    const [form] = Form.useForm();
    useNotificationError(error);
    return (
        <Form
            form={form}
            data-sdet={CLASSIFICATIONS_LABEL_VALUES_FORM_SDET}
            layout="vertical"
            wrapperCol={{ xs: 24, lg: 12 }}
            onFinish={({ labelValues }) => {
                const labelValuesPayload = getLabelValuesPayload(labelValues, label.id);
                labelValuesPayload.length &&
                    createClassificationsLabelValues(labelValuesPayload)
                        .unwrap()
                        .then(() => {
                            notification.success({
                                message: `Label Value(s) for ${label.key} has successfully been added`,
                            });
                            form.resetFields();
                        });
            }}
        >
            {!isDistributionGroup && (
                <Form.Item
                    label={CLASSIFICATIONS_LABEL_FORM_ITEMS.LABEL_VALUES.label}
                    name={CLASSIFICATIONS_LABEL_FORM_ITEMS.LABEL_VALUES.name}
                    extra={
                        <Space direction="vertical">
                            <Typography.Text italic type="secondary">
                                {CLASSIFICATIONS_LABEL_FORM_ITEMS.LABEL_VALUES.description}
                            </Typography.Text>
                            <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                                Add
                            </Button>
                        </Space>
                    }
                >
                    <Input.TextArea
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        data-sdet={CLASSIFICATIONS_LABEL_LABEL_VALUES_INPUT_SDET}
                        placeholder="Enter per line"
                    />
                </Form.Item>
            )}

            <ClassificationsLabelFormLabelValuesTable label={label} />
        </Form>
    );
};
