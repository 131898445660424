import {
    SLIDING_AVERAGE_WINDOW,
    useSlidingXYSeriesAverages,
    X_AXIS_TICK_COUNT,
    Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND,
    Y_AXIS_TITLE_TEXT_REVENUE_PER_SECOND,
} from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { AdSourceStat } from "../../data";
import moment from "moment-timezone";
import { useMemo } from "react";
import { Metrics } from "@app/core/components/charts/DualAxesChart";
import { MixChartProps } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";
import { useAppSelector } from "@app/core/store";
import { selectUserTimezone } from "@app/core/authClient/reducer";

export const useSeatAdSourcesLivePerformanceChart = (adSourceStat: AdSourceStat): MixChartProps => {
    const timeZoneCode = useAppSelector(selectUserTimezone);
    const time = moment(adSourceStat.nTime).tz(timeZoneCode).format("HH:mm:ss");

    const statsMetrics = {
        xValue: time,
        yValues: [
            { yValue: adSourceStat.fills, series: "Fills" },
            { yValue: adSourceStat.tries, series: "Tries" },
            { yValue: adSourceStat.impressions, series: "Impressions" },
            { yValue: adSourceStat.fallbacks, series: "Fall Backs" },
            { yValue: adSourceStat.errors, series: "Errors" },
            { yValue: adSourceStat.skips, series: "Skips" },
        ],
    };

    const revenueMetrics = {
        xValue: time,
        yValues: [{ yValue: adSourceStat.netRevenue, series: "Net Revenue" }],
    };

    const slidingStatAverages = useSlidingXYSeriesAverages(statsMetrics, X_AXIS_TICK_COUNT, SLIDING_AVERAGE_WINDOW);
    const slidingRevenueAverages = useSlidingXYSeriesAverages(
        revenueMetrics,
        X_AXIS_TICK_COUNT,
        SLIDING_AVERAGE_WINDOW
    );

    const { metricOne, metricTwo } = useMemo(() => {
        const metrics: Metrics = { metricOne: [], metricTwo: [] };

        slidingStatAverages.forEach((stat) => {
            stat.yValues.forEach((yValue) => {
                metrics.metricOne.push({
                    time: stat.xValue,
                    value1: yValue.yValue,
                    name1: yValue.series ? yValue.series : "",
                });
            });
        });

        slidingRevenueAverages.forEach((stat) => {
            stat.yValues.forEach((yValue) => {
                metrics.metricTwo.push({
                    time: stat.xValue,
                    value2: yValue.yValue,
                    name2: yValue.series ? yValue.series : "",
                });
            });
        });

        return metrics;
    }, [slidingStatAverages, slidingRevenueAverages]);

    return {
        chartId: "seat-ad-sources-live-performance-chart",
        metricOne,
        metricTwo,
        metricOneYAxisTitle: Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND,
        metricTwoYAxisTitle: Y_AXIS_TITLE_TEXT_REVENUE_PER_SECOND,
        disablePoints: false,
    };
};
