import { FC } from "react";
import { Form } from "antd";
import { MinMaxDurationSelect } from "@rubicon/components-streaming";
import { getAdSourceDuration } from "@app/features/inventory/helpers";

interface AdSourceDurationProps {
    name: string;
    type: "min" | "max";
    oppositeName: string;
    label: string;
    edit: boolean;
    duration: number | null;
}

export const AdSourceDuration: FC<AdSourceDurationProps> = ({
    edit = false,
    label,
    name,
    type,
    oppositeName,
    duration,
}) => {
    return edit ? (
        <MinMaxDurationSelect name={name} type={type} oppositeName={oppositeName} labelOverride={label} min={5} />
    ) : (
        <Form.Item label={label} wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}>
            {getAdSourceDuration(duration)}
        </Form.Item>
    );
};
