import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Typography, notification } from "antd";
import {
    ADDED_VALUES_FORM_NAME,
    CLASSIFICATION_LABEL_FORM_CREATE_INITIAL_VALUES,
    ClassificationsLabelCreateFormValues,
    ClassificationsLabelEditFormValues,
} from "./constants";
import { History } from "history";
import { Label, LabelValuePayload } from "@app/core/services";
import { Dispatch, SetStateAction } from "react";

export const getLabelFormInitialValues = (label?: Label) => {
    if (!label) return CLASSIFICATION_LABEL_FORM_CREATE_INITIAL_VALUES;

    const { key, isDistributionGroup, multiValue, enablePrefilter, isPublic } = label;

    return {
        key,
        isDistributionGroup,
        multiValue,
        enablePrefilter,
        isPublic,
        [ADDED_VALUES_FORM_NAME]: [],
    };
};

export const getLabelValuesPayload = (labelValues: string | undefined, labelId: number) =>
    labelValues
        ?.trim()
        .split("\n")
        .reduce((acc, labelValue) => {
            labelValue.trim() && acc.push({ label: { id: labelId }, value: labelValue.trim() });
            return acc;
        }, [] as LabelValuePayload[]) || [];

export const onFinishCreateLabel = async (
    values: ClassificationsLabelCreateFormValues,
    setIsSubmitting: Dispatch<SetStateAction<boolean>>,
    seatId: string | number,
    createClassificationsLabel,
    history: History
) => {
    setIsSubmitting(true);
    const { labelValues, ...rest } = values;
    const labelPayload = {
        ...rest,
        seatId,
        namespace: `s:${seatId}`,
    };
    if (values.isDistributionGroup) {
        // Labels API for distribution groups required the following properties
        Object.assign(labelPayload, {
            enablePrefilter: false,
            isPublic: true,
            multiValue: false,
        });
    }

    try {
        const createdLabel = await createClassificationsLabel({ seatId, body: labelPayload }).unwrap();
        setIsSubmitting(false);
        notification.success({
            message: (
                <span>
                    <Typography.Text strong>{createdLabel.key}</Typography.Text> has successfully been created
                </span>
            ),
        });
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE(seatId, createdLabel.id));
    } catch (err) {
        setIsSubmitting(false);
        notification.error({ message: err.message || err.data.errorDescription });
    }
};

export const onFinishUpdateLabel = async (
    labelId: number,
    values: ClassificationsLabelEditFormValues,
    seatId: string | number,
    updateClassificationsLabel
) => {
    const { labelValues, deleteLabelValues, ...rest } = values;
    const labelPayload = {
        ...rest,
        id: labelId,
        seatId,
        namespace: `s:${seatId}`,
    };
    try {
        const updatedLabel = await updateClassificationsLabel({ labelId, body: labelPayload }).unwrap();
        notification.success({
            message: (
                <span>
                    <Typography.Text strong>{updatedLabel.key}</Typography.Text> has successfully been updated
                </span>
            ),
        });
    } catch (err) {
        notification.error({ message: err.message || err.data.errorDescription });
    }
};
