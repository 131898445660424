import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import AdvertiserDomainsTable from "./AdvertiserDomains/AdvertiserDomainsTable";
import SequesteredDomainsTable from "./SequesteredDomains/SequesteredDomainsTable";
import { BuyerSeatListsPage } from "./BuyerSeatLists";
import { AdvertiserDomainDrawer } from "./AdvertiserDomains/AdvertiserDomainDrawer/AdvertiserDomainDrawer";
import { CompetitiveSeparationGroupsPage } from "./CompetitiveSeparationGroups";
import { TransparencyDefaultTable } from "./TransparencyDefault/TransparencyDefaultTable";
import { AdvancedBrandSafetyPage } from "./AdvancedBrandSafety";
import ContentTransparencyRulesTable from "./ContentTransparencyRules/ContentTransparencyRulesTable";
import { ContentTransparencyRulesDrawer } from "./ContentTransparencyRules/ContentTransparencyRulesDrawer/ContentTransparencyRulesDrawer";
import { MappingExceptionsListPage } from "./MappingExceptions";

export const BrandSafetyTableSwitch: FC = () => (
    <Switch>
        <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAINS}>
            <>
                <AdvertiserDomainsTable />
                <AdvertiserDomainDrawer />
            </>
        </Route>
        <Route
            exact
            path={[
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAINS,
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAIN_EDIT,
            ]}
        >
            <SequesteredDomainsTable />
        </Route>
        <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_DEFAULT}>
            <TransparencyDefaultTable />
        </Route>
        <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES}>
            <ContentTransparencyRulesTable />
            <ContentTransparencyRulesDrawer />
        </Route>
        <Route
            exact
            path={[
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY,
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER,
            ]}
        >
            <AdvancedBrandSafetyPage />
        </Route>
        <Route
            exact
            path={[
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS,
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_DRAWER,
            ]}
        >
            <CompetitiveSeparationGroupsPage />
        </Route>
        <Route
            exact
            path={[
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST,
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER,
            ]}
        >
            <BuyerSeatListsPage />
        </Route>
        <Route
            exact
            path={[
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE,
                ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE_DETAILS_DRAWER,
            ]}
        >
            <MappingExceptionsListPage />
        </Route>
    </Switch>
);
