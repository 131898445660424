import React, { FC } from "react";
import { Collapse, Form, Tag } from "antd";
import { LabelValue } from "@app/core/services";
import { INHERITED_SECTION_CLASSLIST } from "@app/features/inventory/HierarchyForms/constants";

interface InheritedPrefilterLabelsProps {
    prefilterLabels?: LabelValue[];
}

export const InheritedPrefilterLabels: FC<InheritedPrefilterLabelsProps> = ({ prefilterLabels }) => {
    if (!prefilterLabels?.length) return null;

    return (
        <Collapse
            ghost
            expandIconPosition="end"
            data-sdet="inherited-prefilter-labels"
            className={INHERITED_SECTION_CLASSLIST}
            items={[
                {
                    key: "0",
                    label: "View Inherited Seat Prefilter Labels",
                    children: (
                        <Form.Item style={{ marginTop: "1.5rem" }} label="Inherited Seat Prefilter Labels">
                            {prefilterLabels.map((prefilter) => (
                                <Tag key={prefilter.id}>{`${prefilter.label.key}: ${prefilter.value}`}</Tag>
                            ))}
                        </Form.Item>
                    ),
                },
            ]}
        />
    );
};
