import { LabeledValue } from "antd/es/select";
import { TargetingMode } from "./adSources";
import {
    consoleApi,
    BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG,
    BRANDS_SAFETY_ADVERTISER_DOMAIN_TYPE_TAG,
    BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG,
    BRANDS_SAFETY_ADOMAIN_TAG,
    BRANDS_SAFETY_TRANSPARENCY_DEFAULT_TAG,
    ADVANCED_BRANDS_SAFETY_TAG,
    SEAT_CONTENT_TRANSPARENCY_RULES_FIELDS_TAG,
    SEAT_CONTENT_TRANSPARENCY_RULES_TAG,
    SEAT_CONTENT_TRANSPARENCY_RULE_TAG,
    SEAT_TRANSPARENCY_DEFAULTS_TAG,
} from "./console";
import { IabCategory } from "./iabCategories";
import { SeatAvailableNetworks, SeatContentTransparencyRule } from "./seats";
import { Targeting, TargetingCreatePayload, TargetingUpdatePayload, transformTargetingArrayFromApi } from "./targeting";
import { ExtendedId } from "./extendedIds";
import { GlobalIndustry } from "@app/core/components";

export interface AdvertiserDomainRaw {
    advertiserDomainListType: AdvertiserDomainType;
    creationDate?: string;
    updateDate?: string;
    advertiserDomains?: string[];
    id: number;
    name: string;
}

export interface AdvertiserDomainType {
    name: string;
    id: number;
    creationDate?: string;
    updateDate?: string;
}

export interface BrandSafetyAdSourceType {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    abbreviation: string;
}

export interface DomainAdSource {
    id: number;
    name: string;
    seatId: number;
    typeId: number;
}

export interface AllowedExtendedIdTransparency {
    extendedId: ExtendedId;
    network: SeatAvailableNetworks;
}

export interface TransparencyDefaultRaw {
    creationDate?: string;
    updateDate?: string;
    id?: number | null;
    adSourceType: BrandSafetyAdSourceType;
    allowUserId: boolean;
    maskUserId: boolean;
    allowSiteName: boolean;
    allowDomainName: boolean;
    allowContent: boolean;
    allowSitePage: boolean;
    allowRef: boolean;
    allowIp: boolean;
    allowBundleId: boolean;
    allowStoreUrl: boolean;
    allowAllExtendedId: boolean;
    allowedExtendedIdTransparencies: AllowedExtendedIdTransparency[];
    contentTransparencyRule?: ContentTransparencyRule | null;
}

export type ControlUserIdIfaValueType = "shared" | "masked" | "hidden";

export interface SequesteredDomainRaw {
    domainAdSources: DomainAdSource[];
    sequesteredDomains: {
        advertiserDomains: Record<string, Record<number, number>>;
        ntime: number;
        otime: number;
    };
}

export interface AdvertiserDomainFormPayload {
    id: number;
    name: string;
    advertiserDomainListType: { id: number };
    advertiserDomains: string;
    seat?: {
        id: number;
    };
}

interface ContentTransparencyField {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    code: string;
    sort: number;
    entityType: "ContentMetadataTransparencyField";
}

interface AdvertiserDomainParams {
    body: AdvertiserDomainRaw & {
        seat?: {
            id: number;
        };
    };
}

interface SequesteredDomainParams {
    id: number;
    domain: string;
}

export type TransparencyDefaultPayload = TransparencyDefaultRaw & {
    seat: {
        id: number;
    };
};

export interface ContentField {
    creationDate: string;
    updateDate: string;
}

export interface ContentTransparencyRule {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    description: string;
    contentFields: ContentField[];
}

export interface ContentTransparencyRulesFormPayload extends Omit<Partial<ContentTransparencyRule>, "contentFields"> {
    contentFields: LabeledValue[] | undefined;
    seat?: {
        id: number;
    };
}

export interface ContentTransparencyRulesParams {
    body: Omit<ContentTransparencyRulesFormPayload, "contentFields"> & {
        contentFields: { name: React.ReactNode; id: string | number }[] | undefined;
    };
}

export interface AdvancedBrandSafety {
    advertiserDomains: string[];
    blockExceptionIabCategories: {
        code: string;
        creationDate: string;
        id: number;
        name: string;
        parentId: number;
        parentName: string;
        updateDate: string;
    }[];
    blockedIabCategories: IabCategory[];
    blockedIndustries: GlobalIndustry[] | null;
    id: number;
    name: string;
    seat: { id: number; name: string };
    seatAdvertiserDomainFilterListDefs: { id: number; name: string }[];
    type: {
        id: number;
        name: string;
    };
    targeting: Array<Targeting>;
    targetingMode: TargetingMode;
}

export interface AdvancedBrandSafetyItem extends AdvancedBrandSafety {
    targetingMode: TargetingMode;
}

export interface AdvancedBrandSafetyPayload {
    id: number | null;
    advertiserDomains: string[];
    blockExceptionIabCategories: { id: number }[];
    blockedIabCategories: { id: number }[];
    blockedIndustries: { id: number }[] | null;
    name: string;
    seat: { id: number };
    seatAdvertiserDomainFilterListDefs: { id: number }[];
    type: {
        id: number;
    };
    targeting: (TargetingCreatePayload | TargetingUpdatePayload)[];
    targetingMode: TargetingMode;
}

export interface AdvancedBrandSafetyParams {
    id?: number;
    body: AdvancedBrandSafetyPayload;
}

const DEFAULT_TRANSPARENCY_DEFAULTS: TransparencyDefaultRaw[] = [
    {
        adSourceType: {
            abbreviation: "UFR",
            creationDate: "2014-05-22T18:12:28.000+0000",
            id: 2,
            name: "Unreserved Fixed Rate Deal",
            updateDate: "2014-05-22T18:12:28.000+0000",
        },
        allowContent: true,
        allowSiteName: true,
        allowDomainName: true,
        allowIp: true,
        allowRef: true,
        allowSitePage: true,
        allowBundleId: true,
        allowStoreUrl: true,
        allowUserId: true,
        maskUserId: false,
        allowAllExtendedId: false,
        allowedExtendedIdTransparencies: [],
    },
    {
        adSourceType: {
            abbreviation: "IOA",
            creationDate: "2014-05-22T18:12:28.000+0000",
            id: 3,
            name: "Invite Only Auction",
            updateDate: "2014-05-22T18:12:28.000+0000",
        },
        allowContent: true,
        allowSiteName: true,
        allowDomainName: true,
        allowIp: true,
        allowRef: true,
        allowSitePage: true,
        allowBundleId: true,
        allowStoreUrl: true,
        allowUserId: true,
        maskUserId: false,
        allowAllExtendedId: false,
        allowedExtendedIdTransparencies: [],
    },
    {
        adSourceType: {
            abbreviation: "OA",
            creationDate: "2014-05-22T18:12:28.000+0000",
            id: 4,
            name: "Open Auction",
            updateDate: "2014-05-22T18:12:28.000+0000",
        },
        allowContent: true,
        allowSiteName: true,
        allowDomainName: true,
        allowIp: true,
        allowRef: true,
        allowSitePage: true,
        allowBundleId: true,
        allowStoreUrl: true,
        allowUserId: true,
        maskUserId: false,
        allowAllExtendedId: false,
        allowedExtendedIdTransparencies: [],
    },
    {
        adSourceType: {
            abbreviation: "AG",
            creationDate: "2015-03-19T18:53:54.000+0000",
            id: 6,
            name: "Automated Guaranteed Deal",
            updateDate: "2015-03-19T18:53:54.000+0000",
        },
        allowContent: true,
        allowSiteName: true,
        allowDomainName: true,
        allowIp: true,
        allowRef: true,
        allowSitePage: true,
        allowBundleId: true,
        allowStoreUrl: true,
        allowUserId: true,
        maskUserId: false,
        allowAllExtendedId: false,
        allowedExtendedIdTransparencies: [],
    },
];

export const brandSafetyApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getAdvancedBrandSafety: builder.query<AdvancedBrandSafety[], string | number>({
            query: (seatId: string | number) => `seats/${seatId}/brandSafety`,
            providesTags: (_, err) => (err ? [] : [ADVANCED_BRANDS_SAFETY_TAG]),
        }),
        getAdvancedBrandSafetyById: builder.query<AdvancedBrandSafetyItem, number>({
            query: (id) => `brandSafety/${id}`,
            providesTags: (_, err) => (err ? [] : [ADVANCED_BRANDS_SAFETY_TAG]),
            transformResponse: (response: AdvancedBrandSafety) => {
                return {
                    ...response,
                    targeting: transformTargetingArrayFromApi(response.targeting),
                };
            },
        }),
        createAdvancedBrandSafety: builder.mutation<AdvancedBrandSafety, AdvancedBrandSafetyParams>({
            query: (arg: AdvancedBrandSafetyParams) => {
                const { id, body } = arg;
                return {
                    url: `seats/${id}/brandSafety`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [ADVANCED_BRANDS_SAFETY_TAG],
        }),
        updateAdvancedBrandSafety: builder.mutation<AdvancedBrandSafety, AdvancedBrandSafetyParams>({
            query: ({ id, body }: AdvancedBrandSafetyParams) => ({
                url: `brandSafety/${id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err, { id }) =>
                err ? [] : [ADVANCED_BRANDS_SAFETY_TAG, { type: ADVANCED_BRANDS_SAFETY_TAG, id }],
        }),
        deleteAdvancedBrandSafety: builder.mutation<void, number>({
            query: (id) => ({
                url: `brandSafety/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [ADVANCED_BRANDS_SAFETY_TAG]),
        }),

        deleteAdvertiserDomain: builder.mutation<void, number>({
            query: (id) => ({
                url: `seatAdvertiserDomainFilterListDefs/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err, id) =>
                err ? [] : [BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG, { type: BRANDS_SAFETY_ADOMAIN_TAG, id }],
        }),
        getAdvertiserDomains: builder.query<AdvertiserDomainRaw[], { seatId: string; isIncludeDomains: boolean }>({
            query: ({ seatId, isIncludeDomains }) =>
                `seats/${seatId}/seatAdvertiserDomainFilterListDefs;includeDomains=${isIncludeDomains}`, // TODO when to use "typeId=2" in query params?
            providesTags: (_, err) => (err ? [] : [BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG]),
        }),
        getAdvertiserDomain: builder.query<AdvertiserDomainRaw, { id: string }>({
            query: ({ id }) => `seatAdvertiserDomainFilterListDefs/${id}`,
            providesTags: (_, err, { id }) => (err ? [] : [{ type: BRANDS_SAFETY_ADOMAIN_TAG, id }]),
        }),
        getContentTransparencyRulesFields: builder.query<ContentTransparencyField[], void>({
            query: () => "contentTransparencyRules/fields",
            providesTags: [SEAT_CONTENT_TRANSPARENCY_RULES_FIELDS_TAG],
        }),
        createAdvertiserDomain: builder.mutation<AdvertiserDomainRaw, AdvertiserDomainParams>({
            query: ({ body }: AdvertiserDomainParams) => ({
                url: `seats/${body.seat?.id}/seatAdvertiserDomainFilterListDefs`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG]),
        }),
        createContentTransparencyRules: builder.mutation<SeatContentTransparencyRule, ContentTransparencyRulesParams>({
            query: ({ body }: ContentTransparencyRulesParams) => ({
                url: `seats/${body.seat?.id}/contentTransparencyRules`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_CONTENT_TRANSPARENCY_RULES_TAG]),
        }),
        deleteContentTransparencyRule: builder.mutation<void, number>({
            query: (id) => ({
                url: `contentTransparencyRules/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err, id) =>
                err ? [] : [SEAT_CONTENT_TRANSPARENCY_RULES_TAG, { type: SEAT_CONTENT_TRANSPARENCY_RULE_TAG, id }],
        }),
        getTransparencyDefault: builder.query<TransparencyDefaultRaw[], string>({
            query: (seatId) => `seats/${seatId}/defaults/transparency`,
            providesTags: (_, err) => (err ? [] : [BRANDS_SAFETY_TRANSPARENCY_DEFAULT_TAG]),
            transformResponse: (response: TransparencyDefaultRaw[]) => {
                if (!response.length) {
                    return DEFAULT_TRANSPARENCY_DEFAULTS;
                }
                return response;
            },
        }),
        updateTransparencyDefault: builder.mutation<TransparencyDefaultRaw, TransparencyDefaultPayload>({
            query: (body) => ({
                url: `seats/${body.seat?.id}/defaults/transparency/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err) =>
                err ? [] : [BRANDS_SAFETY_TRANSPARENCY_DEFAULT_TAG, SEAT_TRANSPARENCY_DEFAULTS_TAG],
        }),
        createTransparencyDefault: builder.mutation<TransparencyDefaultRaw, TransparencyDefaultPayload>({
            query: (body) => ({
                url: `seats/${body.seat?.id}/defaults/transparency`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) =>
                err ? [] : [BRANDS_SAFETY_TRANSPARENCY_DEFAULT_TAG, SEAT_TRANSPARENCY_DEFAULTS_TAG],
        }),
        getContentTransparencyRule: builder.query<SeatContentTransparencyRule, { id: string }>({
            query: ({ id }) => `contentTransparencyRules/${id}`,
            providesTags: (_, err, { id }) => (err ? [] : [{ type: SEAT_CONTENT_TRANSPARENCY_RULE_TAG, id }]),
        }),
        updateContentTransparencyRules: builder.mutation<SeatContentTransparencyRule, ContentTransparencyRulesParams>({
            query: ({ body }: ContentTransparencyRulesParams) => ({
                url: `contentTransparencyRules/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err, { body }) =>
                err
                    ? []
                    : [
                          { type: SEAT_CONTENT_TRANSPARENCY_RULES_TAG },
                          { type: SEAT_CONTENT_TRANSPARENCY_RULE_TAG, id: body?.seat?.id },
                      ],
        }),
        updateAdvertiserDomain: builder.mutation<AdvertiserDomainRaw, AdvertiserDomainParams>({
            query: ({ body }: AdvertiserDomainParams) => ({
                url: `seatAdvertiserDomainFilterListDefs/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err, { body }) =>
                err
                    ? []
                    : [
                          { type: BRANDS_SAFETY_ADOMAIN_TAG, id: body.id },
                          { type: BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG },
                      ],
        }),
        getAdvertiserDomainTypes: builder.query<AdvertiserDomainType[], void>({
            query: () => "seatAdvertiserDomainFilterListDefs/types",
            providesTags: (_, err) => (err ? [] : [BRANDS_SAFETY_ADVERTISER_DOMAIN_TYPE_TAG]),
        }),
        getSequesteredDomains: builder.query<SequesteredDomainRaw, string>({
            query: (seatId) => `seats/sequesteredDomains/seats/${seatId}`,
            providesTags: (_, err) => (err ? [] : [BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG]),
        }),
        updateSequesteredDomain: builder.mutation<AdvertiserDomainRaw[], SequesteredDomainParams>({
            query: ({ id, domain }) => ({
                url: `seatAdvertiserDomainFilterListDefs/${id}/adomains/${domain}`,
                method: "PUT",
            }),
            invalidatesTags: (_, err, { id }) =>
                err ? [] : [{ type: BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG }, { type: BRANDS_SAFETY_ADOMAIN_TAG, id }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useDeleteAdvertiserDomainMutation,
    useGetAdvancedBrandSafetyByIdQuery,
    useGetAdvancedBrandSafetyQuery,
    useCreateAdvancedBrandSafetyMutation,
    useCreateContentTransparencyRulesMutation,
    useGetContentTransparencyRuleQuery,
    useUpdateContentTransparencyRulesMutation,
    useUpdateAdvancedBrandSafetyMutation,
    useDeleteAdvancedBrandSafetyMutation,
    useCreateAdvertiserDomainMutation,
    useUpdateAdvertiserDomainMutation,
    useGetAdvertiserDomainQuery,
    useGetAdvertiserDomainsQuery,
    useGetAdvertiserDomainTypesQuery,
    useGetTransparencyDefaultQuery,
    useDeleteContentTransparencyRuleMutation,
    useGetContentTransparencyRulesFieldsQuery,
    useUpdateTransparencyDefaultMutation,
    useCreateTransparencyDefaultMutation,
    useGetSequesteredDomainsQuery,
    useUpdateSequesteredDomainMutation,
} = brandSafetyApi;
