import { Form } from "antd";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME, DEAL_TYPES } from "@app/features/deals/constants";
import {
    dealFormPacingDeliverySchedulesChange,
    dealFormStartEndDateFieldChange,
} from "@app/features/deals/DealForm/reducer";
import { ISO_8601_FORMAT } from "@app/core/components/constants";
import { useEffect, useState } from "react";
import { useSyncedFieldsProducer } from "@app/features/syncedFields/useSyncedFieldsProducer";
import moment from "moment-timezone";
import {
    FormPacingDeliverySchedule,
    useSyncPacingDeliveryScheduleDates,
} from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";

interface UseDealEndDateField {
    handleChange: (date: moment.Moment) => void;
    value: moment.Moment;
    required: boolean;
    onClick: () => void;
    selectEndDateToggle: boolean;
}

export const useDealEndDateField = (): UseDealEndDateField => {
    const dispatch = useAppDispatch();
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const form = Form.useFormInstance();
    const { updatePacingDeliveryScheduleDates } = useSyncPacingDeliveryScheduleDates(
        CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULES,
        form,
        (schedules: FormPacingDeliverySchedule[]) => dispatch(dealFormPacingDeliverySchedulesChange(schedules))
    );
    const [selectEndDateToggle, setSelectEndDateToggle] = useState<boolean>(true);

    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]);

    const required = dealType === DEAL_TYPES.GUARANTEE;

    const handleChange = (endDate: moment.Moment): void => {
        updateSyncedFields({ endDate });
        updatePacingDeliveryScheduleDates({ endDate });
        const value = endDate.format(ISO_8601_FORMAT);
        dispatch(dealFormStartEndDateFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.END_DATE, value }));
    };
    const onClick = (): void => {
        setSelectEndDateToggle((prevState) => !prevState);
        if (value) {
            updateSyncedFields({ endDate: null });
        }
    };
    useEffect(() => {
        if (selectEndDateToggle) {
            dispatch(dealFormStartEndDateFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.END_DATE, value: null }));
        }
    }, [selectEndDateToggle, dispatch]);

    useEffect(() => {
        if (value) {
            setSelectEndDateToggle(false);
        }
    }, [value]);

    return {
        handleChange,
        value: moment(value),
        required,
        onClick,
        selectEndDateToggle,
    };
};
