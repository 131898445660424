import { FC } from "react";
import { Button, Col, Form, InputNumber, Row, Typography } from "antd";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import { DeleteOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Moment } from "moment";
import { Asterisk } from "@app/core/components/formRequiredMark";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { disablePastDates } from "@app/core/utils/disablePastDates";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { FormPacingDeliverySchedule, OnScheduleFieldChange } from "../types";
import {
    validateDateRangeIsOverlapping,
    validateDateRangeIsRequired,
    validateEndDateIsAfterStartDate,
    validateImpressionGoalIsRequired,
} from "../validators";
import { SCHEDULE_COL_SPAN as SPAN } from "./constants";
import { applyVerticalLayout } from "./utils";

const {
    PACING_DELIVERY_SCHEDULE_DATES: DATE_RANGE,
    PACING_DELIVERY_SCHEDULE_IMPRESSION_GOAL: IMPRESSION_GOAL,
    PACING_DELIVERY_SCHEDULE_DAILY_IMPRESSION_CAP: DAILY_IMPRESSION_CAP,
    START_DATE,
    END_DATE,
} = AD_SOURCE_FIELDS;

interface Props {
    rowIndex: number;
    isRequired: boolean;
    isDisabled: boolean;
    dateRangeAllowEmpty: [boolean, boolean];
    dateRangeDisabled: [boolean, boolean];
    dateRangeIsOverlapping: boolean;
    impressionGoalIsRequired: boolean;
    dailyImpressionCapIsDisabled: boolean;
    vertical: boolean;
    onClickDeleteSchedule: (scheduleIndex: number) => void;
    onScheduleFieldChange: OnScheduleFieldChange;
}

export const ScheduleRow: FC<Props> = ({
    rowIndex,
    isRequired,
    isDisabled,
    dateRangeAllowEmpty,
    dateRangeDisabled,
    dateRangeIsOverlapping,
    impressionGoalIsRequired,
    dailyImpressionCapIsDisabled,
    vertical,
    onClickDeleteSchedule,
    onScheduleFieldChange,
}) => {
    const onChangeDateRange = (dates: NoUndefinedRangeValueType<Moment> | null) =>
        onScheduleFieldChange({ dates: dates ? dates : [null, null] }, rowIndex);
    const onChangeImpressionGoal = (impressionGoal: number) => onScheduleFieldChange({ impressionGoal }, rowIndex);
    const onChangeDailyImpressionCap = (dailyCap: number) => onScheduleFieldChange({ dailyCap }, rowIndex);

    return (
        <>
            <Row data-sdet={`schedule-row-${rowIndex}`} justify="space-between" align="middle">
                <Col span={SPAN.NAME}>
                    <Form.Item
                        label={`Schedule ${rowIndex + 1}`}
                        required={isRequired}
                        colon={false}
                        className={css`
                            .ant-form-item-label {
                                padding-bottom: 0 !important;
                            }
                            .ant-form-item-control-input {
                                display: none;
                            }
                        `}
                        {...applyVerticalLayout(vertical, true)}
                    />
                </Col>
                <Col span={SPAN.DATE_RANGE}>
                    <Form.Item
                        name={[rowIndex, DATE_RANGE.name]}
                        rules={[
                            {
                                required: true,
                                validator: async (_, dates: FormPacingDeliverySchedule["dates"]) =>
                                    validateDateRangeIsRequired(dates),
                            },
                            {
                                validator: async () => validateDateRangeIsOverlapping(dateRangeIsOverlapping),
                            },
                            {
                                validator: async (_, dates: FormPacingDeliverySchedule["dates"]) =>
                                    validateEndDateIsAfterStartDate(dates),
                            },
                        ]}
                        {...applyVerticalLayout(vertical)}
                    >
                        <MomentDatePicker.RangePicker
                            allowEmpty={dateRangeAllowEmpty}
                            data-sdet={`date-range-${rowIndex}`}
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{ format: "HH:mm:ss" }}
                            placeholder={[`Select ${START_DATE.label}`, `Select ${END_DATE.label}`]}
                            style={{ width: "100%" }}
                            allowClear={false}
                            disabled={isDisabled || dateRangeDisabled}
                            disabledDate={disablePastDates}
                            onChange={onChangeDateRange}
                        />
                    </Form.Item>
                </Col>
                <Col span={SPAN.IMPRESSION_GOAL}>
                    <Form.Item
                        name={[rowIndex, IMPRESSION_GOAL.name]}
                        rules={[
                            {
                                required: impressionGoalIsRequired,
                                validator: async (_, impressionGoal: FormPacingDeliverySchedule["impressionGoal"]) =>
                                    validateImpressionGoalIsRequired(impressionGoal, impressionGoalIsRequired),
                            },
                        ]}
                        {...applyVerticalLayout(vertical)}
                    >
                        <InputNumber
                            data-sdet={`impression-goal-${rowIndex}`}
                            placeholder={`Enter ${IMPRESSION_GOAL.label}${
                                impressionGoalIsRequired ? "" : " (optional)"
                            }`}
                            addonAfter={
                                <>
                                    <Typography.Text>Impressions</Typography.Text>
                                    {impressionGoalIsRequired && <Asterisk />}
                                </>
                            }
                            min={0}
                            style={{ width: "100%" }}
                            disabled={isDisabled}
                            onChange={onChangeImpressionGoal}
                        />
                    </Form.Item>
                </Col>
                <Col span={SPAN.DAILY_IMPRESSION_CAP}>
                    <Form.Item name={[rowIndex, DAILY_IMPRESSION_CAP.name]} {...applyVerticalLayout(vertical)}>
                        <InputNumber
                            data-sdet={`daily-impression-cap-${rowIndex}`}
                            placeholder={`Enter ${DAILY_IMPRESSION_CAP.label} (optional)`}
                            addonAfter={<Typography.Text>Impressions</Typography.Text>}
                            min={0}
                            style={{ width: "100%" }}
                            disabled={isDisabled || dailyImpressionCapIsDisabled}
                            onChange={onChangeDailyImpressionCap}
                        />
                    </Form.Item>
                </Col>
                <Col span={SPAN.DELETE}>
                    <Form.Item>
                        <Button
                            data-sdet={`delete-schedule-button-${rowIndex}`}
                            disabled={isDisabled || isRequired}
                            onClick={() => onClickDeleteSchedule(rowIndex)}
                            icon={<DeleteOutlined />}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
