import moment, { Moment } from "moment-timezone";
import { PacingDeliverySchedule } from "@app/core/services";
import { FormPacingDeliverySchedule } from "./types";

const compareDates = (dateA: Moment | null, dateB: Moment | null): number => {
    if (dateA && dateB) {
        return dateA.diff(dateB);
    }
    if (dateA) {
        return -1;
    }
    if (dateB) {
        return 1;
    }
    return 0;
};

export const cloneSchedules = (schedules: FormPacingDeliverySchedule[]): FormPacingDeliverySchedule[] =>
    schedules.map((schedule) => ({
        ...schedule,
        dates: schedule.dates.map((date) => date?.clone() ?? null),
    }));

export const sortSchedules = (schedules: FormPacingDeliverySchedule[]): FormPacingDeliverySchedule[] =>
    cloneSchedules(schedules).sort(
        ({ dates: [startDateA, endDateA] }, { dates: [startDateB, endDateB] }) =>
            compareDates(startDateA, startDateB) || compareDates(endDateA, endDateB)
    );

export const sortMiddleSchedules = (schedules: FormPacingDeliverySchedule[]): FormPacingDeliverySchedule[] => {
    const newSchedules = cloneSchedules(schedules);
    const scheduleCount = newSchedules.length;

    if (scheduleCount <= 3) {
        return newSchedules;
    }

    const sortedMiddleSchedules = sortSchedules(newSchedules.slice(1, scheduleCount - 1));

    return [newSchedules[0], ...sortedMiddleSchedules, newSchedules[scheduleCount - 1]];
};

export const clearDailyImpressionCapFromSchedules = (
    schedules: FormPacingDeliverySchedule[] = []
): FormPacingDeliverySchedule[] => cloneSchedules(schedules).map((schedule) => ({ ...schedule, dailyCap: null }));

export const sortApiSchedules = (schedules: PacingDeliverySchedule[]): PacingDeliverySchedule[] =>
    schedules
        .map((schedule) => ({
            ...schedule,
        }))
        .sort((scheduleA, scheduleB) => compareDates(moment(scheduleA.startDate), moment(scheduleB.startDate)));
