import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FORM_FIELDS } from "./constants";
import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { Form, FormInstance, notification } from "antd";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { parseFreqCaps, parseFreqCapsToApi } from "../../HierarchyForms/helpers";
import { DEFAULT_ADVERTISER_FREQUENCY_CAPPING } from "../../HierarchyForms/constants";
import { AdUnit, useUpdateAdUnitBrandSafetyMutation } from "@app/core/services/console";
import { useSeatTree } from "@app/features/inventory/InventoryBrandSafety/SeatTree/useSeatTree";
import { setEditActiveKey, setIsActionsDisabled } from "@app/features/inventory/InventoryBrandSafety/reducer";

export interface AdUnitDetailsFormValues {
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: LabeledValue | null;
}

interface UseAdUnitBrandSafetyEditForm {
    adUnitForm: FormInstance<AdUnitDetailsFormValues>;
    handleSubmit: () => void;
    handleDelete: () => void;
    resetFormsFields: () => void;
    initialValues: Store | undefined;
    isDeleting: boolean;
    isUpdating: boolean;
    hasFrequencyCapping: boolean;
}

export const getInitialValuesFromAdUnit = (adUnit: AdUnit | null): Store | undefined => {
    if (!adUnit) return undefined;

    return {
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: adUnit.seatAdvertiserDomainFilterListDefs.map((list) => ({
            value: list.id,
            label: list.name,
        })),
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: adUnit.advertiserBlockDomains.join("\n"),
        [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: adUnit.buyerSeatList
            ? { value: adUnit.buyerSeatList.id, label: adUnit.buyerSeatList.name }
            : null,
        [FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.name]: adUnit.adomainFreqCappingType
            ? { value: adUnit.adomainFreqCappingType.id, label: adUnit.adomainFreqCappingType.name }
            : null,
        [FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING.name]: parseFreqCaps("edit", adUnit?.adomainFreqCappings),
    };
};

export const useAdUnitBrandSafetyEditForm = (adUnit: AdUnit): UseAdUnitBrandSafetyEditForm => {
    const dispatch = useDispatch();
    const { adUnitForm, resetFormsFields } = useSeatTree();
    const initialValues = useMemo(() => {
        return getInitialValuesFromAdUnit(adUnit);
    }, [adUnit]);
    const [updateAdUnitBrandSafety] = useUpdateAdUnitBrandSafetyMutation();
    const frequencyCapping = Form.useWatch(FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.name, adUnitForm);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const hasFrequencyCapping =
        frequencyCapping && !(frequencyCapping.value === DEFAULT_ADVERTISER_FREQUENCY_CAPPING.value);

    const handleSubmit = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsUpdating(true);
        const {
            blockedAdvertiserDomainLists,
            blockedAdvertiserDomains,
            blockedBuyerSeatList,
            adomainFreqCappingType,
            adomainFreqCappings,
        } = adUnitForm.getFieldsValue(Object.values(FORM_FIELDS).map((field) => field.name));

        try {
            await updateAdUnitBrandSafety({
                id: adUnit.id,
                advertiserBlockDomains: blockedAdvertiserDomains.length ? blockedAdvertiserDomains?.split("\n") : [],
                buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
                seatAdvertiserDomainFilterListDefs: blockedAdvertiserDomainLists.length
                    ? blockedAdvertiserDomainLists.map(({ value }) => ({ id: value as number }))
                    : null,
                adomainFreqCappingType: adomainFreqCappingType ? { id: adomainFreqCappingType.value as number } : null,
                adomainFreqCappings: parseFreqCapsToApi(adomainFreqCappings),
            }).unwrap();
            dispatch(setEditActiveKey(null));
            notification.success({ message: "Ad Unit updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            dispatch(setIsActionsDisabled(false));
            setIsUpdating(false);
        }
    };

    const handleDelete = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsDeleting(true);
        try {
            await updateAdUnitBrandSafety({
                id: adUnit.id,
                advertiserBlockDomains: [],
                buyerSeatList: null,
                seatAdvertiserDomainFilterListDefs: [],
                adomainFreqCappingType: null,
                adomainFreqCappings: [],
            }).unwrap();
            notification.success({ message: "Ad Unit updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            dispatch(setIsActionsDisabled(false));
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        if (initialValues && !isUpdating) {
            adUnitForm.setFieldsValue(initialValues);
        }
    }, [adUnitForm, initialValues, isUpdating, adUnit, resetFormsFields]);

    return {
        adUnitForm,
        isDeleting,
        isUpdating,
        handleSubmit,
        handleDelete,
        resetFormsFields,
        hasFrequencyCapping,
        initialValues,
    };
};
