import { Button, Collapse, Layout, Space, Typography } from "antd";
import { FC, useState } from "react";
import { Label } from "@app/core/services";
import { ClassificationsLabelEditKeyForm } from "./ClassificationsLabelEditKeyForm";
import { ClassificationsLabelValuesForm } from "./ClassificationsLabelValuesForm";
import { css } from "@emotion/css";
import { ClassificationsLabelDeleteButton } from "../../ClassificationsLabelsTab/ClassificationsLabelDeleteButton";
import { sectionHeaderClassname } from "@app/features/controls/constants";

export const CLASSIFICATIONS_LABEL_EDIT_FORM_SDET = "classifications-label-edit-form";
export const CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_EDIT_BUTTON_SDET = "classifications-label-edit-key-form-edit-button";

interface Props {
    label: Label;
}

export const ClassificationsLabelEditForm: FC<Props> = ({ label }) => {
    const [isEditingKey, setIsEditingKey] = useState(false);
    const isDistributionGroup = label?.isDistributionGroup;

    return (
        <>
            <Layout.Content>
                <Space direction="vertical" size="large" style={{ display: "flex" }}>
                    <Collapse
                        defaultActiveKey={[1]}
                        bordered
                        collapsible="header"
                        className={sectionHeaderClassname}
                        ghost={false}
                        items={[
                            {
                                key: "1",
                                children: (
                                    <ClassificationsLabelEditKeyForm
                                        isEditingKey={isEditingKey}
                                        setIsEditingKey={setIsEditingKey}
                                        label={label}
                                    />
                                ),
                                label: (
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        Key
                                    </Typography.Title>
                                ),
                                extra: !isDistributionGroup && !isEditingKey && (
                                    <Button
                                        data-sdet={CLASSIFICATIONS_LABEL_EDIT_KEY_FORM_EDIT_BUTTON_SDET}
                                        type="primary"
                                        onClick={() => setIsEditingKey(true)}
                                    >
                                        Edit Key
                                    </Button>
                                ),
                            },
                        ]}
                    />
                    <Collapse
                        defaultActiveKey={[2]}
                        bordered
                        collapsible="header"
                        className={sectionHeaderClassname}
                        ghost={false}
                        items={[
                            {
                                key: "2",
                                children: <ClassificationsLabelValuesForm label={label} />,
                                label: (
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        Values
                                    </Typography.Title>
                                ),
                            },
                        ]}
                    />
                </Space>
            </Layout.Content>
            <div
                className={css(`
                    position: sticky;
                    bottom: 0;
                    z-index: 999;
                    background: #FFF;
                    width: 100%;
                    padding: 12px 12px;
                    box-shadow: 0 1px 4px #00152914;
                `)}
            >
                <ClassificationsLabelDeleteButton label={label} />
            </div>
        </>
    );
};
