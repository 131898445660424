import { FC } from "react";
import { Col, Row } from "antd";
import {
    Conditions,
    CustomRuleIds,
    CustomRuleOperators,
    GroupConditions,
    TargetingDimensionTypeLabels,
    TargetingDimensionTypes,
} from "@app/features/targeting/constants";
import { isAddDisabled } from "@app/features/targeting/helpers";
import { CustomRuleGroup, CustomRuleTargetingDimension, UsedDimensions } from "@app/features/targeting/types";
import { ConditionSelect } from "@app/features/targeting/CreateTargeting/ConditionSelect";
import { MoreMenu } from "@app/features/targeting/CreateTargeting/MoreMenu";
import { DimensionRow } from "../DimensionRow";
import { CustomRulesDrawerButton } from "./CustomRulesDrawerButton";
import { getCustomRuleGroupString, isCustomRuleGroupEmpty } from "./helpers";

interface Props {
    dimension: CustomRuleTargetingDimension;
    onChangeCondition: (value: Conditions) => void;
    onChangeRuleCondition: (path: number[], value: GroupConditions) => void;
    onChangeRuleGroup: (group: CustomRuleGroup) => void;
    onChangeRuleId: (path: number[], id: CustomRuleIds) => void;
    onChangeRuleOperator: (path: number[], operator: CustomRuleOperators) => void;
    onChangeRuleValue: (path: number[], value: string | string[] | number | number[] | null) => void;
    onClickAddRule: (path: number[]) => void;
    onClickAddRuleGroup: (path: number[]) => void;
    onClickDelete: () => void;
    onClickDeleteRule: (path: number[]) => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const CustomRulesDimensionRow: FC<Props> = ({
    dimension,
    onChangeCondition,
    onChangeRuleCondition,
    onChangeRuleGroup,
    onChangeRuleId,
    onChangeRuleOperator,
    onChangeRuleValue,
    onClickAddRule,
    onClickAddRuleGroup,
    onClickDelete,
    onClickDeleteRule,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <DimensionRow
            rules={[
                {
                    validator: () => {
                        return new Promise((resolve, reject) => {
                            if (!dimension.ruleGroup.rules.length) {
                                return reject();
                            }
                            return resolve(true);
                        });
                    },
                    message: "Custom Rules must include at least one condition",
                },
            ]}
            label={TargetingDimensionTypeLabels.CustomRules}
            condition={
                <ConditionSelect
                    onChange={onChangeCondition}
                    value={dimension.condition}
                    isExcludesDisabled={
                        dimension.condition !== Conditions.Excludes && usedDimensions.customRules.excludes
                    }
                    isIncludesDisabled={
                        dimension.condition !== Conditions.Includes && usedDimensions.customRules.includes
                    }
                />
            }
            value={
                !isCustomRuleGroupEmpty(dimension.ruleGroup) && (
                    <Row>
                        <Col style={{ padding: "4px" }}>{getCustomRuleGroupString(dimension.ruleGroup)}</Col>
                    </Row>
                )
            }
            menu={
                <>
                    <CustomRulesDrawerButton
                        dimension={dimension}
                        onChangeCondition={onChangeRuleCondition}
                        onChangeId={onChangeRuleId}
                        onChangeOperator={onChangeRuleOperator}
                        onChangeRuleGroup={onChangeRuleGroup}
                        onChangeValue={onChangeRuleValue}
                        onClickAddRule={onClickAddRule}
                        onClickAddRuleGroup={onClickAddRuleGroup}
                        onClickDeleteRule={onClickDeleteRule}
                    />
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDownload={onClickDownload}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={isAddDisabled(TargetingDimensionTypes.CustomRules, usedDimensions)}
                    />
                </>
            }
        />
    );
};
