import { FC, ReactNode } from "react";
import { LabeledValue } from "antd/lib/select";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { BulkUploadDrawerButton } from "./BulkUploadDrawerButton";
import { useBulkTargetingDrawerButton } from "./useBulkTargetingDrawerButton";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/query";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    values: LabeledValue[];
    toLabelValueMapper: (input: unknown[] | null) => LabeledValue[];
    mutation: UseMutation<
        MutationDefinition<{ seatId: number; body: string[] }, BaseQueryFn, string, unknown[], string>
    >;
    label: TargetingDimensionTypeLabels;
    validBulkValuesMapper: (input: unknown) => string;
    extra?: ReactNode;
}

export const BulkUploadDrawerButtonContainer: FC<Props> = ({
    onChange,
    values,
    toLabelValueMapper,
    mutation,
    label,
    validBulkValuesMapper,
    extra,
}) => {
    const {
        handleAdd,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        invalidValues,
        isEmpty,
    } = useBulkTargetingDrawerButton(onChange, values, mutation, toLabelValueMapper, validBulkValuesMapper);

    return (
        <BulkUploadDrawerButton
            handleAdd={handleAdd}
            handleChangeValue={handleChangeValue}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleReplace={handleReplace}
            isLoadingAdd={isLoadingAdd}
            isLoadingReplace={isLoadingReplace}
            isOpen={isOpen}
            value={value}
            title={`Bulk Load ${label}`}
            placeholder={`Enter ${label} one per row`}
            invalidValues={invalidValues}
            extra={extra}
            isEmpty={isEmpty}
        />
    );
};
