import {
    AdUnit,
    AdUnitPayload,
    BrandSupply,
    useCreateAdUnitMutation,
    useDeleteAdUnitMutation,
    useUpdateAdUnitMutation,
} from "@app/core/services";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import { FormInstance, notification } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { useAdUnitFormInstance } from "./AdUnitFormInstance";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { getAdUnitFormInitialValues, parseAdUnitFormValuesToApi } from "./adUnitFormHelpers";
import {
    AD_UNIT_FORM_FIELDS,
    AD_UNITS_MULTIPLICITIES,
    CHANNEL_FORM_FIELDS,
    DEFAULT_PLAYLIST_DEFINITION_MODE,
} from "../constants";
import { LabeledValueOpotions } from "@app/features/inventory/components/Fields/FrequencyCaps/useFrequencyCaps";
import { AdBreakRuleMidRoll, AdBreakRulePostRoll, AdBreakRulePreRoll } from "@app/features/inventory/components/Fields";
import { LabelValueOption } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";
import { SeparationGroupOption } from "@app/features/inventory/components/FormItems/AdUnitCompetitiveSeparationGroups/useAdUnitCompetitiveSeparationGroups";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { INVENTORY_FLOORS_ADVANCED } from "@app/features/inventory/InventoryFloors";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";

export interface AdUnitForm {
    // General
    [AD_UNIT_FORM_FIELDS.NAME.name]: string;
    [AD_UNIT_FORM_FIELDS.CODE_TYPE.name]: string;
    [AD_UNIT_FORM_FIELDS.CODE.name]: string;
    [AD_UNIT_FORM_FIELDS.DESCRIPTION.name]: string;
    [AD_UNIT_FORM_FIELDS.STATUS.name]: number;

    // Affiliate Section
    [AD_UNIT_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: number | null;
    [AD_UNIT_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: number | null;

    // Brand Safety
    [AD_UNIT_FORM_FIELDS.ADVERTISER_FREQUENCY_CAPPING_TYPE.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAINS.name]: string;
    [AD_UNIT_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LIST.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name]: LabeledValueOpotions[] | null;

    // Demand Requirements
    [AD_UNIT_FORM_FIELDS.ADDITIONAL_TIME.name]: number;
    [AD_UNIT_FORM_FIELDS.BLOCKED_ATTRIBUTES.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.MAX_BITRATE.name]: number | null;
    [AD_UNIT_FORM_FIELDS.MAX_DURATION.name]: number;
    [AD_UNIT_FORM_FIELDS.MAX_EXTENDED.name]: number | string;
    [AD_UNIT_FORM_FIELDS.MIMES.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.MIN_BITRATE.name]: number | null;
    [AD_UNIT_FORM_FIELDS.MIN_DURATION.name]: number;
    [AD_UNIT_FORM_FIELDS.PLACEMENT.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.SUPPORTED_APIS.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.SUPPORTED_PROTOCOLS.name]: LabeledValue[];

    // Supply Details
    [AD_UNIT_FORM_FIELDS.LINEARITY.name]: string | number;
    [AD_UNIT_FORM_FIELDS.MULTIPLICITY.name]: number;
    [AD_UNIT_FORM_FIELDS.TYPE.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.START_DELAY.name]: number;
    [AD_UNIT_FORM_FIELDS.VIDEO_PLAYBACKS.name]: LabeledValue[] | null;
    [AD_UNIT_FORM_FIELDS.DELIVERY_METHODS.name]: LabeledValue[] | null;
    [AD_UNIT_FORM_FIELDS.SKIPPABLE.name]: boolean | string;
    [AD_UNIT_FORM_FIELDS.SKIPPABLE_AFTER.name]: number | null;
    [AD_UNIT_FORM_FIELDS.SKIPPABLE_MINIMUM.name]: number | null;
    [AD_UNIT_FORM_FIELDS.INTERSTITIAL.name]: boolean;
    [AD_UNIT_FORM_FIELDS.BOXING.name]: boolean;
    [AD_UNIT_FORM_FIELDS.SSAI_TYPE.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.CREATIVE_SAMPLES_MINUTE.name]: number;
    [AD_UNIT_FORM_FIELDS.UNWRAP_VAST.name]: boolean;
    [AD_UNIT_FORM_FIELDS.LIVE_STREAM.name]: boolean;
    [AD_UNIT_FORM_FIELDS.CONTENT_CHANNEL.name]: string;
    [AD_UNIT_FORM_FIELDS.CONTENT_NETWORK.name]: string;
    [AD_UNIT_FORM_FIELDS.UDE_OPT_OUT.name]: boolean;
    [AD_UNIT_FORM_FIELDS.ALLOW_DEMAND_UNDER_INBOUND_FLOOR.name]: boolean;
    [AD_UNIT_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name]: boolean;

    // Internal
    [AD_UNIT_FORM_FIELDS.LOWER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [AD_UNIT_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [AD_UNIT_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: boolean | string;

    // Demand Requirements
    [AD_UNIT_FORM_FIELDS.SUPPORTED_PROTOCOLS.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.MIMES.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.SUPPORTED_APIS.name]: LabeledValue[];
    [AD_UNIT_FORM_FIELDS.PLACEMENT.name]: LabeledValue;
    [AD_UNIT_FORM_FIELDS.MIN_DURATION.name]: number;
    [AD_UNIT_FORM_FIELDS.MAX_DURATION.name]: number;
    [AD_UNIT_FORM_FIELDS.MAX_EXTENDED.name]: number | string;
    [AD_UNIT_FORM_FIELDS.MIN_BITRATE.name]: number | null;
    [AD_UNIT_FORM_FIELDS.MAX_BITRATE.name]: number | null;
    [AD_UNIT_FORM_FIELDS.BLOCKED_ATTRIBUTES.name]: LabeledValue[];

    // Ad Pod Section
    [AD_UNIT_FORM_FIELDS.MAX_POD_SECONDS.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.MAX_ADS_PER_POD.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.FILL_MODE.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.POD_ENFORCEMENT.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: undefined | boolean;
    [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: SeparationGroupOption[] | undefined;
    [AD_UNIT_FORM_FIELDS.POD_DEDUPE_LEVEL.name]: number | null | undefined;

    // UD Configuration Section
    [AD_UNIT_FORM_FIELDS.FILL_MODE.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: undefined | boolean;
    [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: SeparationGroupOption[] | undefined;
    [AD_UNIT_FORM_FIELDS.MAX_ADS_PER_ADV.name]: number | null;

    // Playlist Configuration Section
    [AD_UNIT_FORM_FIELDS.FILL_MODE.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.POD_ENFORCEMENT.name]: number | undefined;
    [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: undefined | boolean;
    [AD_UNIT_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: SeparationGroupOption[] | undefined;
    [AD_UNIT_FORM_FIELDS.MAX_ADS_PER_ADV.name]: number | null;
    [AD_UNIT_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name]: number;

    //Ad Break Rules
    [AD_UNIT_FORM_FIELDS.AD_BREAK_RULES_PRE.name]: [AdBreakRulePreRoll];
    [AD_UNIT_FORM_FIELDS.AD_BREAK_RULES_MID.name]: [AdBreakRuleMidRoll];
    [AD_UNIT_FORM_FIELDS.AD_BREAK_RULES_POST.name]: [AdBreakRulePostRoll];
    [AD_UNIT_FORM_FIELDS.CUE_POINTS.name]: string[];

    // Custom Pixels
    [AD_UNIT_FORM_FIELDS.CUSTOM_PIXELS.name]: LabeledValue[];

    // Labels
    [AD_UNIT_FORM_FIELDS.LABELS.name]: LabelValueOption[];
    [AD_UNIT_FORM_FIELDS.INTERNAL_LABELS.name]: LabelValueOption[];

    // Waterfall Prefiltering
    [AD_UNIT_FORM_FIELDS.PREFILTER_LABEL_VALUES.name]: LabelValueOption[];
}

export interface UseAdUnitForm {
    supply: BrandSupply | undefined;
    adUnit: AdUnit | undefined;
    form: FormInstance<AdUnitForm>;
    handleCancel: () => void;
    handleCancelModal: () => void;
    handleDelete: () => void;
    handleSubmit: () => void;
    initialValues: Store;
    isDeleting: boolean;
    isEditMode: boolean;
    isPodSectionShown: boolean;
    hasSeatWriteAccess: boolean;
    isPlaylistSectionShown: boolean;
    isFloorsSectionShown: boolean;
    isInternalSectionShown: boolean;
    isAdBreakRulesSectionShown: boolean;
    isAffiliateSectionShown: boolean;
    UDConfigurationSectionShown: boolean;
    loading: boolean;
    open: boolean;
    showModal: () => void;
    handleAdvancedFloors: () => void;
    submitButtonTitle: string;
}

export const useAdUnitForm = (): UseAdUnitForm => {
    const history = useHistory();
    const { adUnit, mode, form, supplyId, useWatch, seat, seatId, publisherId, brandId, supply } =
        useAdUnitFormInstance();
    const { isTremorUser, isPubAcctMgr, isSysAdmin, hasInternalAccess, hasSeatWriteAccess } = useUserAccess();
    const [open, setOpen] = useState<boolean>(false);
    const isAffiliateSectionShown: boolean = isTremorUser && !!seat?.affiliateCostEnabled;
    const submitButtonTitle = mode !== "create" ? "Save" : "Submit";

    const isFloorsSectionShown: boolean = mode === "edit";
    const isEditMode: boolean = mode === "edit";
    const isInternalSectionShown = isSysAdmin || isPubAcctMgr;

    const initialValues: Store = getAdUnitFormInitialValues({ adUnit, mode });

    const [createAdUnit, { isLoading: isCreating }] = useCreateAdUnitMutation();
    const [updateAdUnit, { isLoading: isUpdating }] = useUpdateAdUnitMutation();
    const [deleteAdUnit, { isLoading: isDeleting }] = useDeleteAdUnitMutation();

    const handleCancelModal = (): void => setOpen(false);
    const showModal = (): void => setOpen(true);
    const handleCancel = (): void => history.goBack();

    const handleCreate = async (body: AdUnitPayload): Promise<void> => {
        if (!supplyId) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            const res = await createAdUnit({ supplyId, body }).unwrap();
            notification.success({ message: "Ad Unit created successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.AD_UNIT,
                    seatId,
                    publisherId,
                    brandId,
                    supplyId,
                    res.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleUpdate = async (body: AdUnitPayload): Promise<void> => {
        if (!adUnit) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await updateAdUnit({ ...body, id: adUnit.id }).unwrap();
            notification.success({ message: "Ad Unit updated successfully" });
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.AD_UNIT,
                    seatId,
                    publisherId,
                    brandId,
                    supplyId,
                    adUnit.id
                )
            );
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleDelete = async (): Promise<void> => {
        if (!isEditMode || !adUnit) return notification.error({ message: DEFAULT_FALLBACK_MESSAGE });
        try {
            await deleteAdUnit(adUnit.id).unwrap();
            notification.success({ message: "Ad Unit deleted successfully" });
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH(seatId));
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        }
    };
    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            const payload: AdUnitPayload = parseAdUnitFormValuesToApi({
                seat,
                mode,
                supplyId,
                isSysAdmin,
                isPubAcctMgr,
                isTremorUser,
                hasInternalAccess,
                values: form.getFieldsValue(),
            });
            if (isEditMode) return handleUpdate(payload);
            return handleCreate(payload);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    const handleAdvancedFloors = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_TABS(seatId, INVENTORY_FLOORS_ADVANCED));

    const multiplicity: number = useWatch(CHANNEL_FORM_FIELDS.MULTIPLICITY.name, form);
    const isPodSectionShown: boolean = multiplicity === AD_UNITS_MULTIPLICITIES.POD.value && !!seat?.adPodEnabled;
    const isPlaylistSectionShown: boolean =
        multiplicity === AD_UNITS_MULTIPLICITIES.PLAYLIST.value && !!seat?.adPodEnabled;

    const playlistDifinitionMode = useWatch(CHANNEL_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name, form);
    const isAdBreakRulesSectionShown: boolean = playlistDifinitionMode === DEFAULT_PLAYLIST_DEFINITION_MODE.value;

    const UDConfigurationSectionShown: boolean =
        multiplicity === AD_UNITS_MULTIPLICITIES.UDE.value && !!seat?.udeEnabled;

    return {
        adUnit,
        form,
        open,
        supply,
        showModal,
        isDeleting,
        isEditMode,
        handleCancel,
        handleDelete,
        handleSubmit,
        initialValues,
        handleCancelModal,
        isPodSectionShown,
        submitButtonTitle,
        hasSeatWriteAccess,
        isFloorsSectionShown,
        handleAdvancedFloors,
        isPlaylistSectionShown,
        isInternalSectionShown,
        isAffiliateSectionShown,
        isAdBreakRulesSectionShown,
        UDConfigurationSectionShown,
        loading: isCreating || isUpdating,
    };
};
