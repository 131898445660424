import {
    Currency,
    LabelValue,
    SeatContentTransparencyRule,
    SimpleTimeZone,
    getTimeZoneLabel,
    useGetAdSourceAuctionTypesQuery,
    useGetAdSourceFloorTypesQuery,
    useGetAdSourcePrioritiesQuery,
    useGetAdUniPlacementsQuery,
    useGetAdUnitDeliveryMethodsQuery,
    useGetAdUnitStatusesQuery,
    useGetAdUnitTypesQuery,
    useGetAdUnitWaitTimesQuery,
    useGetAdUnitsApiFrameworksQuery,
    useGetAdUnitsCreativeAttributesQuery,
    useGetAdUnitsLinearitiesQuery,
    useGetAdUnitsMimesQuery,
    useGetAdUnitsSupportedProtocolsQuery,
    useGetContentTransparencyRulesQuery,
    useGetCostModelsQuery,
    useGetCurrenciesQuery,
    useGetMarketplacesQuery,
    useGetRegionsQuery,
    useGetSeatAdSourcesStatusesQuery,
    useGetSeatLabelValuesQuery,
    useGetSsaiTypesQuery,
    useGetTimeZonesQuery,
    useSearchQuery,
} from "@app/core/services";
import {
    BulkOperationEntityType,
    BulkOperationSourceEntity,
    BulkOperationType,
} from "@app/core/services/console/bulkOperation";
import { format } from "@rubicon/utils";
import { Input, InputNumber, Radio } from "antd";
import { GenericSelectField } from "./shared/GenericSelectField";
import { SalesContactFilter } from "./BulkOperationCreatePage/BulkOperationSetupStep/BulkOperationEntitySelectSection/FindOperationEntitiesTab/OperationEntitiesFilters/SalesContactFilter";
import { disablePastDates } from "@app/core/utils/disablePastDates";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { MomentDatePicker } from "@app/core/components/MomentDatePicker";
import { numberNormalize } from "@app/core/components/helpers";
import { FC } from "react";
import { NamePath } from "antd/es/form/interface";
import { NOT_SPECIFIED_OPTION } from "@app/features/inventory/HierarchyForms/constants";
import { DerivedAdSourceListItem, DerivedAdUnit, DerivedBuyerDeal, DerivedDemandDeal } from "./reducer";

export const initialBulkOperationValues = {
    type: BulkOperationType.Edit,
};

interface EntityTypeOption {
    label: string;
    value: BulkOperationEntityType | BulkOperationSourceEntity;
}

export const getEntityTypeOptions = (
    operationType: BulkOperationType,
    hasFullOptionsAccess: boolean
): EntityTypeOption[] =>
    operationType === BulkOperationType.Edit
        ? ([
              hasFullOptionsAccess && { label: "Ad Source", value: BulkOperationEntityType.AdSource },
              { label: "Ad Unit", value: BulkOperationEntityType.AdUnit },
              hasFullOptionsAccess && { label: "Buyer Deal", value: BulkOperationEntityType.BuyerDeal },
              hasFullOptionsAccess && { label: "Demand Deal", value: BulkOperationEntityType.DemandDeal },
          ].filter(Boolean) as EntityTypeOption[])
        : [
              { label: "Publisher", value: BulkOperationSourceEntity.Publisher },
              { label: "Brand", value: BulkOperationSourceEntity.Brand },
              { label: "Supply", value: BulkOperationSourceEntity.Supply },
              { label: "Ad Unit", value: BulkOperationSourceEntity.AdUnit },
          ];

export const SOURCE_ENTITY_ID_FIELD = "sourceEntityId";
export const DESTINATION_ENTITY_NAME_FIELD = "destinationEntityName";
export const DESTINATION_ENTITY_ID_FIELD = "destinationEntityId";

export const BULK_OPERATION_FORM_ITEMS = {
    OPERATION_TYPE: {
        label: "Bulk Operation Type",
        name: "type",
    },
    // for "Edit" operation type
    ENTITY_TYPE: {
        label: "Entity Type",
        name: "entityType",
    },
    // for "Copy" operation type
    SOURCE_ENTITY: {
        label: "Entity Type",
        name: "sourceEntity",
    },
    FIELDS_TO_EDIT: {
        label: "Fields to Edit",
        name: "fieldsToEdit",
    },
    OPERATION_ENTITIES_SELECT_SECTION_ERROR_MESSAGE: {
        name: "operationEntitiesSelectSectionErrorMessage",
    },
    OPERATION_ENTITIES_EDIT_SECTION_ERROR_MESSAGE: {
        name: "operationEntitiesEditSectionErrorMessage",
    },
    OPERATION_ENTITIES_EDIT_REVIEW_ERROR_MESSAGE: {
        name: "operationEntitiesEditReviewErrorMessage",
    },
    PUBLISHER_TO_COPY: {
        label: "Select Publisher to Copy",
        name: SOURCE_ENTITY_ID_FIELD,
    },
    BRAND_TO_COPY: {
        label: "Select Brand to Copy",
        name: SOURCE_ENTITY_ID_FIELD,
    },
    SUPPLY_TO_COPY: {
        label: "Select Supply to Copy",
        name: SOURCE_ENTITY_ID_FIELD,
    },
    AD_UNIT_TO_COPY: {
        label: "Select Ad Unit to Copy",
        name: SOURCE_ENTITY_ID_FIELD,
    },
    COPY_INVENTORY_CHILDREN: {
        label: "Copy Inventory Children",
        name: "recursiveCopy",
    },
    // this field is just for storing this data in form for step 3 usage
    COPY_SCOPE: {
        name: "copyScope",
    },
    NAME_FOR_NEW_PUBLISHER: {
        label: "Name for New Publisher",
        name: DESTINATION_ENTITY_NAME_FIELD,
    },
    NAME_FOR_NEW_BRAND: {
        label: "Name for New Brand",
        name: DESTINATION_ENTITY_NAME_FIELD,
    },
    NAME_FOR_NEW_SUPPLY: {
        label: "Name for New Supply",
        name: DESTINATION_ENTITY_NAME_FIELD,
    },
    NAME_FOR_NEW_AD_UNIT: {
        label: "Name for New Ad Unit",
        name: DESTINATION_ENTITY_NAME_FIELD,
    },
    DESTINATION_SEAT: {
        label: "Destination Seat",
        name: "destinationSeat",
    },
    SELECT_SEAT_PARENT: {
        label: "Select Seat Parent",
        name: DESTINATION_ENTITY_ID_FIELD,
    },
    SELECT_PUBLISHER_PARENT: {
        label: "Select Publisher Parent",
        name: DESTINATION_ENTITY_ID_FIELD,
    },
    SELECT_BRAND_PARENT: {
        label: "Select Brand Parent",
        name: DESTINATION_ENTITY_ID_FIELD,
    },
    SELECT_SUPPLY_PARENT: {
        label: "Select Supply Parent",
        name: DESTINATION_ENTITY_ID_FIELD,
    },
};

export const OPERATION_FIND_ENTITIES_TAB = "findEntitiesTab";
export const OPERATION_SELECTED_ENTITIES_TAB = "selectedTab";

export enum AdSourceTypeIds {
    TREMOR = 1,
    FIXED_PRICE = 2,
    AUCTION_PRICE = 3,
    OPEN_AUCTION = 4,
    FALLBACK_TAG = 5,
    PROGRAMMATIC_GUARANTEED = 6,
    SERVER_SIDE_TAG_GUARANTEED = 7,
    SERVER_SIDE_TAG_NON_GUARANTEED = 8,
    CLIENT_SIDE_TAG_GUARANTEED = 9,
    MARKETPLACE = 10,
    SERVER_SIDE_DYNAMIC_PRICE = 11,
    CONDITIONAL_PROGRAMMATIC_GUARANTEED = 12,
    PREBID_AD_SOURCE = 13,
    PREBID_DEALS_CONTAINER = 14,
    LINEAR_FIXED_PRICE = 15,
    LINEAR_AUCTION_PRICE = 16,
    DIRECT_AD_SERVING = 100,
}

export const AD_SOURCES_NEXT_UI_ID_NAME_MAP: Record<number, string> = {
    [AdSourceTypeIds.PROGRAMMATIC_GUARANTEED]: "Programmatic Guaranteed",
    [AdSourceTypeIds.CONDITIONAL_PROGRAMMATIC_GUARANTEED]: "Conditional Programmatic Guaranteed",
    [AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED]: "Server-side Tag Guaranteed",
    [AdSourceTypeIds.PREBID_DEALS_CONTAINER]: "Prebid Deals Container",
    [AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED]: "Client-side Tag Guaranteed",
    [AdSourceTypeIds.SERVER_SIDE_DYNAMIC_PRICE]: "Server-side Dynamic Price",
    [AdSourceTypeIds.FIXED_PRICE]: "Fixed Price",
    [AdSourceTypeIds.SERVER_SIDE_TAG_NON_GUARANTEED]: "Server-side Tag Non-Guaranteed",
    [AdSourceTypeIds.AUCTION_PRICE]: "Auction Price",
    [AdSourceTypeIds.OPEN_AUCTION]: "Open Auction",
    [AdSourceTypeIds.FALLBACK_TAG]: "Fallback Tag",
    [AdSourceTypeIds.MARKETPLACE]: "Marketplace",
    [AdSourceTypeIds.PREBID_AD_SOURCE]: "Prebid Ad Source",
    [AdSourceTypeIds.DIRECT_AD_SERVING]: "Direct AdServing",
    [AdSourceTypeIds.LINEAR_FIXED_PRICE]: "Linear Fixed Price",
    [AdSourceTypeIds.LINEAR_AUCTION_PRICE]: "Linear Auction Price",
    [AdSourceTypeIds.TREMOR]: "Tremor",
};

export const AD_SOURCE_TYPE_PROGRAMMATIC_IDS = [6, 12, 2, 3, 4, 10, 15, 16];
export const AD_SOURCE_TYPE_TAG_BASED_IDS = [7, 9, 11, 8, 5];
export const AD_SOURCE_TYPE_THIRD_PARTY_IDS = [14, 13];

export const UNSPECIFIED_OPTION = { value: -1, label: "Unspecified" };
export const OFF_OPTION = { value: -1, label: "Off" };
export const NONE_OPTION = { value: -1, label: "None" };
export const BOTH_ALLOWED_OPTION = { value: -1, label: "Both Allowed" };

export const RESTRICTED_AD_SOURCE_FIELDS = [
    "region",
    "salesContact",
    "costModel",
    "costValueFixed",
    "costValuePercent",
];

export const MULTIPLICITY_OPTIONS = [
    { value: 1, label: "Single" },
    { value: 2, label: "Ad Pods" },
    { value: 3, label: "Playlists" },
    { value: 4, label: "UDE" },
];

export const BUYER_DEMAND_DEAL_STATUS_OPTIONS = [
    { value: "Proposed", label: "Proposed" },
    { value: "Accepted", label: "Accepted" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Rejected", label: "Rejected" },
];

export const YES_NO_BOOLEAN_OPTIONS = [
    { label: format.constants.YES, value: true },
    { label: format.constants.NO, value: false },
];

export const YES_NO_STRING_OPTIONS = [
    { label: format.constants.YES, value: "yes" },
    { label: format.constants.NO, value: "no" },
];

export const SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS = [
    AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
    AdSourceTypeIds.CONDITIONAL_PROGRAMMATIC_GUARANTEED,
    AdSourceTypeIds.FIXED_PRICE,
    AdSourceTypeIds.AUCTION_PRICE,
    AdSourceTypeIds.OPEN_AUCTION,
    AdSourceTypeIds.MARKETPLACE,
];

export const SHARE_HIDE_STRING_OPTIONS = [
    { label: "Shared", value: "shared" },
    { label: "Hidden", value: "hidden" },
];

export const SHARE_HIDE_BOOLEAN_OPTIONS = [
    { label: "Shared", value: true },
    { label: "Hidden", value: false },
];

export const ALLOW_USER_ID_OPTIONS = [{ label: "Masked", value: "masked" }, ...SHARE_HIDE_STRING_OPTIONS];

export const PUBLISHER_REAUCTION_OPTIONS = [...YES_NO_STRING_OPTIONS, NOT_SPECIFIED_OPTION];

export interface FormItemCommonConfig<T> {
    key: string;
    dataIndex: NamePath;
    title: string;
    fixed?: "left" | "right";
    width?: string | number;
    getIsDisabled?: (record: T) => boolean;
    getQueryArgs?: (seatId?: number) => unknown[];
    formProps?: Record<string, unknown>;
    props?: Record<string, unknown>;
    component: FC;
}

const idNameToLabelValue = ({ id, name }: { id: number | string; name: string }) => ({ label: name, value: id });

export const TO_OPTION_MAPPER = {
    priority: idNameToLabelValue,
    region: idNameToLabelValue,
    salesContact: ({ id, name, emailAddress }) => ({
        label: emailAddress ? `${name} [${emailAddress}]` : name,
        value: id,
    }),
    status: idNameToLabelValue,
    timeZone: (timeZone: SimpleTimeZone) => ({
        label: getTimeZoneLabel(timeZone),
        value: timeZone.id,
    }),
    currencyType: ({ code, id }: Currency) => ({ label: code, value: id }),
    costModel: idNameToLabelValue,
    adSourceFloorType: idNameToLabelValue,
    allowContent: ({ name, id }: SeatContentTransparencyRule) => ({
        label: name,
        value: String(id),
    }), // stringify id to keep consistent with addon options value type
    auctionType: idNameToLabelValue,
    labelValues: ({ label, value, id }: LabelValue) => ({
        label: `${label.key}: ${value}`,
        value: id,
    }),
    placement: idNameToLabelValue,
    supportedProtocols: idNameToLabelValue,
    mimes: idNameToLabelValue,
    supportedApis: idNameToLabelValue,
    ssaiType: idNameToLabelValue,
    impressionWaitTime: idNameToLabelValue,
    blockedCreativeAttributes: idNameToLabelValue,
    videoDeliveries: idNameToLabelValue,
    linearity: idNameToLabelValue,
    type: idNameToLabelValue,
    sharedSeats: idNameToLabelValue,
    sharedMarketplaces: idNameToLabelValue,
};

export const AD_SOURCE_FORM_ITEM_COMMON_CONFIG: FormItemCommonConfig<DerivedAdSourceListItem>[] = [
    {
        key: "name",
        dataIndex: "name",
        title: "Name",
        fixed: "left" as const,
        width: "250px",
        props: {
            "data-sdet": "ad-source-name",
        },
        component: Input,
    },
    {
        key: "priority",
        dataIndex: ["priority", "name"],
        title: "Priority",
        width: 120,
        getIsDisabled: (record: DerivedAdSourceListItem) => record.type.id === AdSourceTypeIds.FALLBACK_TAG, // id: 5
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "priority-field",
            useGetOptionsQuery: useGetAdSourcePrioritiesQuery,
            optionMapper: TO_OPTION_MAPPER.priority,
        },
        component: GenericSelectField,
    },
    // when UNSPECIFIED_OPTION is selected, send region: null to api
    {
        key: "region",
        dataIndex: "region",
        title: "Region",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "region-field",
            useGetOptionsQuery: useGetRegionsQuery,
            unspecifiedOptions: [UNSPECIFIED_OPTION],
            optionMapper: TO_OPTION_MAPPER.region,
        },
        component: GenericSelectField,
    },
    {
        key: "salesContact",
        dataIndex: "salesContact",
        title: "Sales Contact",
        getIsDisabled: (record: DerivedAdSourceListItem) => record.type.id === AdSourceTypeIds.MARKETPLACE,
        props: { labelInValue: true },
        component: SalesContactFilter,
    },
    {
        key: "status",
        dataIndex: ["status", "name"],
        title: "Status",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "status-field",
            useGetOptionsQuery: useGetSeatAdSourcesStatusesQuery,
            optionMapper: TO_OPTION_MAPPER.status,
        },
        component: GenericSelectField,
    },
    {
        key: "bookingStartDate",
        dataIndex: "bookingStartDate",
        title: "Start Date",
        props: {
            showTime: true,
            allowClear: false,
            style: { width: "100%" },
            disabledDate: disablePastDates,
            format: YEAR_MONTH_DAY_HOUR_MINUTE_AMPM,
        },
        component: MomentDatePicker,
    },
    {
        key: "bookingEndDate",
        dataIndex: "bookingEndDate",
        title: "End Date",
        props: {
            showTime: true,
            style: { width: "100%" },
            disabledDate: disablePastDates,
            format: YEAR_MONTH_DAY_HOUR_MINUTE_AMPM,
        },
        component: MomentDatePicker,
    },
    {
        key: "timeZone",
        dataIndex: "timeZone",
        title: "Time Zone",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "time-zone-field",
            useGetOptionsQuery: useGetTimeZonesQuery,
            optionMapper: TO_OPTION_MAPPER.timeZone,
        },
        component: GenericSelectField,
    },
    {
        key: "currencyType",
        dataIndex: ["currencyType", "id"],
        title: "Currency",
        getIsDisabled: (record: DerivedAdSourceListItem) => record.type.id === AdSourceTypeIds.FALLBACK_TAG,
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "currency-field",
            useGetOptionsQuery: useGetCurrenciesQuery,
            optionMapper: TO_OPTION_MAPPER.currencyType,
        },
        component: GenericSelectField,
    },
    {
        key: "costModel",
        dataIndex: "costModel",
        title: "Cost Type",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            record.type.id === AdSourceTypeIds.FALLBACK_TAG || !record.seat,
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "cost-model-field",
            useGetOptionsQuery: useGetCostModelsQuery,
            optionMapper: TO_OPTION_MAPPER.costModel,
        },
        component: GenericSelectField,
    },
    {
        key: "costValueFixed",
        dataIndex: "costValueFixed",
        title: "Cost Value Fixed",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            record.type.id === AdSourceTypeIds.FALLBACK_TAG || !record.seat,
        props: {
            "data-sdet": "cost-value-fixed-field",
        },
        component: InputNumber,
    },
    {
        key: "costValuePercent",
        dataIndex: "costValuePercent",
        title: "Cost Value Percent",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            record.type.id === AdSourceTypeIds.FALLBACK_TAG || !record.seat,
        props: {
            "data-sdet": "cost-value-percent-field",
        },
        component: InputNumber,
    },
    {
        key: "adSourceFloorType",
        dataIndex: ["adSourceFloorType", "name"],
        title: "Floor Type",
        width: 150,
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            ![AdSourceTypeIds.OPEN_AUCTION, AdSourceTypeIds.AUCTION_PRICE, AdSourceTypeIds.MARKETPLACE].includes(
                record.type.id
            ),
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "floor-type-field",
            useGetOptionsQuery: useGetAdSourceFloorTypesQuery,
            optionMapper: TO_OPTION_MAPPER.adSourceFloorType,
        },
        component: GenericSelectField,
    },
    {
        key: "bookingPrice",
        dataIndex: "bookingPrice",
        title: "Booking Price",
        getIsDisabled: (record: DerivedAdSourceListItem) => record.type.id === AdSourceTypeIds.FALLBACK_TAG,
        props: {
            "data-sdet": "booking-price-field",
        },
        component: InputNumber,
    },
    {
        key: "bookingVolume",
        dataIndex: "bookingVolume",
        title: "Impression Goal",
        formProps: {
            normalize: numberNormalize,
        },
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            ![
                AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
                AdSourceTypeIds.CONDITIONAL_PROGRAMMATIC_GUARANTEED,
                AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
                AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
            ].includes(record.type.id),
        props: {
            "data-sdet": "impression-goal-field",
        },
        component: InputNumber,
    },
    {
        key: "adSourceMinDuration",
        dataIndex: "adSourceMinDuration",
        title: "Min Duration",
        formProps: {
            normalize: numberNormalize,
        },
        props: {
            "data-sdet": "min-duration-field",
        },
        component: InputNumber,
    },
    {
        key: "adSourceMaxDuration",
        dataIndex: "adSourceMaxDuration",
        title: "Max Duration",
        formProps: {
            normalize: numberNormalize,
        },
        props: {
            "data-sdet": "max-duration-field",
        },
        component: InputNumber,
    },
    {
        key: "allowAutoscale",
        dataIndex: "allowAutoscale",
        title: "Allow Autoscale",
        getIsDisabled: (record: DerivedAdSourceListItem) => record.type.id === AdSourceTypeIds.MARKETPLACE,
        props: {
            "data-sdet": "allow-autoscale-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "alwaysSendFloor",
        dataIndex: "alwaysSendFloor",
        title: "Always Send Floor",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            ![AdSourceTypeIds.AUCTION_PRICE, AdSourceTypeIds.OPEN_AUCTION].includes(record.type.id),
        props: {
            "data-sdet": "always-send-floor-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowSiteName",
        dataIndex: "allowSiteName",
        title: "Allow Site Name",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            "data-sdet": "allow-site-name-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowDomainName",
        dataIndex: "allowDomainName",
        title: "Allow Domain Name",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            "data-sdet": "allow-domain-name-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowUserId",
        dataIndex: "allowUserId",
        title: "Allow User ID",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            "data-sdet": "allow-user-id-field",
            options: ALLOW_USER_ID_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowSitePage",
        dataIndex: "allowSitePage",
        title: "Allow Site Page",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            "data-sdet": "allow-site-page-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowRef",
        dataIndex: "allowRef",
        title: "Allow Referrer",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            "data-sdet": "allow-ref-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowContent",
        dataIndex: "allowContent",
        title: "Allow Content",
        width: 150,
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        getQueryArgs: (seatId) => [seatId],
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "allow-content-field",
            useGetOptionsQuery: useGetContentTransparencyRulesQuery,
            unspecifiedOptions: SHARE_HIDE_STRING_OPTIONS,
            optionMapper: TO_OPTION_MAPPER.allowContent,
        },
        component: GenericSelectField,
    },
    {
        key: "allowIp",
        dataIndex: "allowIp",
        title: "Allow IP",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            "data-sdet": "allow-ip-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "auctionType",
        dataIndex: "auctionType",
        title: "Default Auction Type",
        getIsDisabled: (record: DerivedAdSourceListItem) =>
            !record.seat || !SITE_DOMAIN_USER_REF_CONTENT_IP_EDITABLE_TYPE_IDS.includes(record.type.id),
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "auction-type-field",
            useGetOptionsQuery: useGetAdSourceAuctionTypesQuery,
            unspecifiedOptions: [OFF_OPTION],
            optionMapper: TO_OPTION_MAPPER.auctionType,
        },
        component: GenericSelectField,
    },
    {
        key: "allowAllExtendedId",
        dataIndex: "allowAllExtendedId",
        title: "Allow Extended ID",
        props: {
            "data-sdet": "allow-extended-id-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "allowBundleId",
        dataIndex: "allowBundleId",
        title: "Allow Bundle ID",
        props: {
            "data-sdet": "allow-bundle-id-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "labelValues",
        dataIndex: "labelValues",
        title: "Labels",
        width: 200,
        getIsDisabled: (record: DerivedAdSourceListItem) => record.type.id === AdSourceTypeIds.PREBID_DEALS_CONTAINER,
        getQueryArgs: (seatId) => [seatId],
        props: {
            sdet: "label-values-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetSeatLabelValuesQuery,
            optionMapper: TO_OPTION_MAPPER.labelValues,
        },
        component: GenericSelectField,
    },
    {
        key: "overrideDynamicFloor",
        dataIndex: "overrideDynamicFloor",
        title: "Run Under Inbound Floor",
        props: {
            "data-sdet": "run-under-inbound-floor-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
];
export const AD_SOURCE_FIELDS_TO_EDIT_OPTIONS = AD_SOURCE_FORM_ITEM_COMMON_CONFIG.map(({ key, title }) => ({
    label: title,
    value: key,
})).filter(({ value }) => value !== "name"); // "name" field always shows in table

export const AD_UNIT_FORM_ITEM_COMMON_CONFIG: FormItemCommonConfig<DerivedAdUnit>[] = [
    {
        key: "name",
        dataIndex: "name",
        title: "Name",
        fixed: "left" as const,
        props: { "data-sdet": "ad-unit-name" },
        width: "250px",
        component: Input,
    },
    {
        key: "status",
        dataIndex: ["status", "name"],
        title: "Status",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "status-field",
            useGetOptionsQuery: useGetAdUnitStatusesQuery,
            optionMapper: TO_OPTION_MAPPER.status,
        },
        component: GenericSelectField,
    },
    {
        key: "placement",
        dataIndex: ["placement", "name"],
        title: "Placement",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "placement-field",
            useGetOptionsQuery: useGetAdUniPlacementsQuery,
            optionMapper: TO_OPTION_MAPPER.placement,
        },
        component: GenericSelectField,
    },
    {
        key: "supportedProtocols",
        dataIndex: "supportedProtocols",
        title: "Supported Protocols",
        width: 170,
        props: {
            sdet: "supported-protocols-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetAdUnitsSupportedProtocolsQuery,
            optionMapper: TO_OPTION_MAPPER.supportedProtocols,
        },
        component: GenericSelectField,
    },
    {
        key: "mimes",
        dataIndex: "mimes",
        title: "Mimes",
        width: 170,
        props: {
            sdet: "mimes-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetAdUnitsMimesQuery,
            optionMapper: TO_OPTION_MAPPER.mimes,
        },
        component: GenericSelectField,
    },
    {
        key: "supportedApis",
        dataIndex: "supportedApis",
        title: "Supported APIs",
        width: 160,
        props: {
            sdet: "supported-apis-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetAdUnitsApiFrameworksQuery,
            optionMapper: TO_OPTION_MAPPER.supportedApis,
        },
        component: GenericSelectField,
    },
    {
        key: "publisherReauction",
        dataIndex: "publisherReauction",
        title: "Publisher Re-Auction",
        getIsDisabled: (record: DerivedAdUnit) => record.calculatedPublisherReauction != null,
        props: {
            "data-sdet": "publisher-reauction-field",
            options: PUBLISHER_REAUCTION_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "skip",
        dataIndex: "skip",
        title: "Skippable",
        props: {
            "data-sdet": "skippable-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "skipmin",
        dataIndex: "skipmin",
        title: "Skip Minimum",
        props: {
            "data-sdet": "skip-min-field",
        },
        component: InputNumber,
    },
    {
        key: "skipafter",
        dataIndex: "skipafter",
        title: "Skip After",
        props: {
            "data-sdet": "skip-after-field",
        },
        component: InputNumber,
    },
    {
        key: "minBitrate",
        dataIndex: "minBitrate",
        title: "Min Bitrate",
        props: {
            "data-sdet": "min-bitrate-field",
        },
        component: InputNumber,
    },
    {
        key: "maxBitrate",
        dataIndex: "maxBitrate",
        title: "Max Bitrate",
        props: {
            "data-sdet": "max-bitrate-field",
        },
        component: InputNumber,
    },
    {
        key: "minDuration",
        dataIndex: "minDuration",
        title: "Min Duration",
        formProps: {
            normalize: numberNormalize,
        },
        props: {
            "data-sdet": "min-duration-field",
        },
        component: InputNumber,
    },
    {
        key: "maxDuration",
        dataIndex: "maxDuration",
        title: "Max Duration",
        formProps: {
            normalize: numberNormalize,
        },
        props: {
            "data-sdet": "max-duration-field",
        },
        component: InputNumber,
    },
    {
        key: "ssaiType",
        dataIndex: "ssaiType",
        title: "SSAI Type",
        props: {
            sdet: "ssai-type-field",
            labelInValue: true,
            useGetOptionsQuery: useGetSsaiTypesQuery,
            unspecifiedOptions: [OFF_OPTION],
            optionMapper: TO_OPTION_MAPPER.ssaiType,
        },
        component: GenericSelectField,
    },
    {
        key: "impressionWaitTime",
        dataIndex: "impressionWaitTime",
        title: "Impression Wait Time",
        props: {
            sdet: "wait-time-field",
            labelInValue: true,
            useGetOptionsQuery: useGetAdUnitWaitTimesQuery,
            unspecifiedOptions: [OFF_OPTION],
            optionMapper: TO_OPTION_MAPPER.impressionWaitTime,
        },
        component: GenericSelectField,
    },
    {
        key: "blockedCreativeAttributes",
        dataIndex: "blockedCreativeAttributes",
        title: "Blocked Attributes",
        width: 200,
        props: {
            sdet: "blocked-attributes-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetAdUnitsCreativeAttributesQuery,
            optionMapper: TO_OPTION_MAPPER.blockedCreativeAttributes,
        },
        component: GenericSelectField,
    },
    {
        key: "videoDeliveries",
        dataIndex: "videoDeliveries",
        title: "Delivery Methods",
        width: 200,
        props: {
            sdet: "delivery-methods-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetAdUnitDeliveryMethodsQuery,
            optionMapper: TO_OPTION_MAPPER.videoDeliveries,
        },
        component: GenericSelectField,
    },
    {
        key: "linearity",
        dataIndex: "linearity",
        title: "Linearity",
        props: {
            sdet: "linearity-field",
            labelInValue: true,
            unspecifiedOptions: [BOTH_ALLOWED_OPTION],
            useGetOptionsQuery: useGetAdUnitsLinearitiesQuery,
            optionMapper: TO_OPTION_MAPPER.linearity,
        },
        component: GenericSelectField,
    },
    {
        key: "type",
        dataIndex: ["type", "name"],
        title: "Type",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "type-field",
            useGetOptionsQuery: useGetAdUnitTypesQuery,
            optionMapper: TO_OPTION_MAPPER.type,
        },
        component: GenericSelectField,
    },
    {
        key: "contentNetwork",
        dataIndex: "contentNetwork",
        title: "Content Network",
        props: {
            "data-sdet": "content-network-field",
        },
        component: Input,
    },
    {
        key: "contentChannel",
        dataIndex: "contentChannel",
        title: "Content Channel",
        props: {
            "data-sdet": "content-channel-field",
        },
        component: Input,
    },
    {
        key: "unwrapVast",
        dataIndex: "unwrapVast",
        title: "Vast Unwrapping",
        props: {
            "data-sdet": "unwrapVast-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "isLiveStream",
        dataIndex: "isLiveStream",
        title: "Live Stream",
        props: {
            "data-sdet": "live-stream-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
    {
        key: "labelValues",
        dataIndex: "labelValues",
        title: "Labels",
        width: 200,
        getQueryArgs: (seatId) => [seatId],
        props: {
            sdet: "label-values-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetSeatLabelValuesQuery,
            optionMapper: TO_OPTION_MAPPER.labelValues,
        },
        component: GenericSelectField,
    },
    {
        key: "overrideDynamicFloor",
        dataIndex: "overrideDynamicFloor",
        title: "Allow Demand Under Inbound Floor",
        props: {
            "data-sdet": "allow-demand-under-inbound-floor-field",
            options: YES_NO_BOOLEAN_OPTIONS,
        },
        component: Radio.Group,
    },
];

export const AD_UNIT_FIELDS_TO_EDIT_OPTIONS = AD_UNIT_FORM_ITEM_COMMON_CONFIG.map(({ key, title }) => ({
    label: title,
    value: key,
    // "name" field always shows in table,
    // "skipmin", "skipafter" will be added to table when "skip" field is selected
})).filter(({ value }) => !["name", "skipmin", "skipafter"].includes(value));

export const BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG: FormItemCommonConfig<DerivedBuyerDeal | DerivedDemandDeal>[] = [
    {
        key: "externalName",
        dataIndex: "externalName",
        title: "Name",
        fixed: "left" as const,
        width: "250px",
        props: { "data-sdet": "deal-name" },
        component: Input,
    },
    {
        key: "startTime",
        dataIndex: "startTime",
        title: "Start Date",
        props: {
            showTime: true,
            allowClear: false,
            style: { width: "100%" },
            disabledDate: disablePastDates,
            format: YEAR_MONTH_DAY_HOUR_MINUTE_AMPM,
        },
        component: MomentDatePicker,
    },
    {
        key: "endTime",
        dataIndex: "endTime",
        title: "End Date",
        props: {
            showTime: true,
            style: { width: "100%" },
            disabledDate: disablePastDates,
            format: YEAR_MONTH_DAY_HOUR_MINUTE_AMPM,
        },
        component: MomentDatePicker,
    },
    {
        key: "timeZone",
        dataIndex: "timeZone",
        title: "Time Zone",
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "time-zone-field",
            useGetOptionsQuery: useGetTimeZonesQuery,
            optionMapper: TO_OPTION_MAPPER.timeZone,
        },
        component: GenericSelectField,
    },
    {
        key: "currencyType",
        dataIndex: ["currencyType", "code"],
        title: "Currency",
        //can't update currency for Google
        getIsDisabled: (record: DerivedBuyerDeal | DerivedDemandDeal) => record.network?.id === 3,
        props: {
            allowClear: false,
            labelInValue: true,
            sdet: "currency-field",
            useGetOptionsQuery: useGetCurrenciesQuery,
            optionMapper: TO_OPTION_MAPPER.currencyType,
        },
        component: GenericSelectField,
    },
    {
        key: "rate",
        dataIndex: "rate",
        title: "CPM Rate",
        //can't update rate for xandr
        getIsDisabled: (record: DerivedBuyerDeal | DerivedDemandDeal) => record.network?.id === 40,
        props: {
            "data-sdet": "cpm-rate-field",
        },
        component: InputNumber,
    },
    {
        key: "bookingVolume",
        dataIndex: "bookingVolume",
        title: "Impressions",
        formProps: {
            normalize: numberNormalize,
        },
        props: {
            "data-sdet": "impressions-field",
            style: { width: "100%" },
        },
        component: InputNumber,
    },
    {
        key: "sharedSeats",
        dataIndex: "sharedSeats",
        title: "Shared Seats",
        width: 160,
        getQueryArgs: () => [{ type: "supplyseat" }],
        props: {
            sdet: "shared-seats-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useSearchQuery,
            optionMapper: TO_OPTION_MAPPER.sharedSeats,
        },
        component: GenericSelectField,
    },
    {
        key: "sharedMarketplaces",
        dataIndex: "sharedMarketplaces",
        title: "Shared Marketplaces",
        width: 200,
        props: {
            sdet: "shared-marketplaces-field",
            labelInValue: true,
            mode: "multiple",
            useGetOptionsQuery: useGetMarketplacesQuery,
            optionMapper: TO_OPTION_MAPPER.sharedMarketplaces,
        },
        component: GenericSelectField,
    },
];

export const BUYER_DEMAND_DEAL_FIELDS_TO_EDIT_OPTIONS = BUYER_DEMAND_DEAL_FORM_ITEM_COMMON_CONFIG.map(
    ({ key, title }) => ({
        label: title,
        value: key,
    })
).filter(({ value }) => value !== "externalName"); // "name" field always shows in table

export enum BulkEditAction {
    Replace = "replace",
    Add = "add",
    Remove = "remove",
    Clear = "clear",
}

export enum BulkOperationsUrlParams {
    Type = "type",
    EntityType = "entityType",
    EntityId = "entityId",
}
