import { NONE, NOT_ALLOWED } from "@app/core/components/constants";
import { AdUnit } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { AD_UNIT_DEMAND_REQUIREMENTS_LABELS, COL_SIZES, FORM_ITEM_OFFSET } from "../../../constants";

const { Item } = Form;

interface Props {
    adUnit: AdUnit;
}

export const DemandRequirements: FC<Props> = ({ adUnit }) => {
    return (
        <div data-sdet="ad-unit-demand-requirements-section">
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedProtocols} className={FORM_ITEM_OFFSET}>
                        {adUnit?.supportedProtocols?.length
                            ? adUnit.supportedProtocols.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                            : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.mimes} className={FORM_ITEM_OFFSET}>
                        {adUnit?.mimes?.length ? adUnit.mimes.map(({ id, name }) => <Tag key={id}>{name}</Tag>) : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedApis} className={FORM_ITEM_OFFSET}>
                        {adUnit?.supportedApis?.length
                            ? adUnit.supportedApis.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                            : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.placement} className={FORM_ITEM_OFFSET}>
                        {adUnit?.placement?.name ?? NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minDuration} className={FORM_ITEM_OFFSET}>
                        {adUnit?.minDuration > 0 ? `${adUnit.minDuration} Seconds` : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxDuration} className={FORM_ITEM_OFFSET}>
                        {adUnit?.maxDuration > 0 ? `${adUnit.maxDuration} Seconds` : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxExtended} className={FORM_ITEM_OFFSET}>
                        {adUnit?.maxExtended > 0 ? adUnit.maxExtended : NOT_ALLOWED}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.blockedAttributes} className={FORM_ITEM_OFFSET}>
                        {adUnit?.blockedCreativeAttributes?.length
                            ? adUnit.blockedCreativeAttributes.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                            : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minBitrate} className={FORM_ITEM_OFFSET}>
                        {adUnit?.minBitrate ?? NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxBitrate} className={FORM_ITEM_OFFSET}>
                        {adUnit?.maxBitrate ?? NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
