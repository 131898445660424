import { useMemo, useState } from "react";
import { LabeledValue } from "antd/es/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormPacingDeliverySchedulesChange, dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { usePacingTypeOptions } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/PacingSection/Fields";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import {
    getPacingTypeHelpText,
    isPacingDeliveryScheduleDailyImpressionCapEligible,
    isPacingTypeReadonly,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { clearDailyImpressionCapFromSchedules } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/PacingSection/PacingDeliverySchedule/utils";

interface UseAdSourcePacingTypeField {
    value: number | null;
    options: LabeledValue[];
    isReadonly: boolean;
    helpText: string | undefined;
    onChange: (id: number) => void;
}

export const useAdSourcePacingTypeField = (mode: DealFormMode): UseAdSourcePacingTypeField => {
    const dispatch = useAppDispatch();
    const { options } = usePacingTypeOptions();
    const [hasChanged, setHasChanged] = useState(false);

    const value = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE]);
    const adSourceTypeId = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const isDeliveryScheduleEnabled = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULE_ENABLED]
    );
    const deliverySchedules = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULES]
    );

    const isReadonly = isPacingTypeReadonly(mode, value, hasChanged);

    const helpText = getPacingTypeHelpText(value);

    const labelValueOptions = useMemo(
        () => options.map((option) => ({ label: option.name, value: option.id })),
        [options]
    );

    const onChange = (pacingTypeId: number) => {
        setHasChanged(true);
        dispatch(
            dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE, value: pacingTypeId })
        );
        const wasImpressionCapEnabled = isPacingDeliveryScheduleDailyImpressionCapEligible(
            adSourceTypeId,
            value,
            Boolean(isDeliveryScheduleEnabled)
        );
        const isImpressionCapEnabled = isPacingDeliveryScheduleDailyImpressionCapEligible(
            adSourceTypeId,
            pacingTypeId,
            Boolean(isDeliveryScheduleEnabled)
        );
        if (wasImpressionCapEnabled && !isImpressionCapEnabled) {
            const pacingSchedulesSansImpressionCap = clearDailyImpressionCapFromSchedules(deliverySchedules);
            dispatch(dealFormPacingDeliverySchedulesChange(pacingSchedulesSansImpressionCap));
        }
    };

    return {
        value,
        options: labelValueOptions,
        isReadonly,
        helpText,
        onChange,
    };
};
