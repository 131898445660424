import { FC } from "react";
import { Card, Row, Col } from "antd";
import { useUserAccess } from "@app/core/auth";
import { Seat } from "@app/core/services/console";
import {
    TimeZone,
    AdSourceDuration,
    SeatDefaultsInternalOnly,
    AdSourceAuctionTypeDefault,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";

const { AD_SOURCE_MAX_DURATION_DEFAULT, AD_SOURCE_MIN_DURATION_DEFAULT } = INVENTORY_FORM_FIELDS;

interface Props {
    edit?: boolean;
    seat: Seat;
}

const COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const SeatDefaultsSection: FC<Props> = ({ edit = false, seat }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <Row gutter={16}>
                        <Col {...COL_SIZES}>
                            <TimeZone seat={seat} edit={edit} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col {...COL_SIZES}>
                            <AdSourceDuration
                                name={AD_SOURCE_MIN_DURATION_DEFAULT.name}
                                label={AD_SOURCE_MIN_DURATION_DEFAULT.label}
                                type="min"
                                oppositeName={AD_SOURCE_MAX_DURATION_DEFAULT.name}
                                duration={seat.adSourceMinDurationDefault}
                                edit={edit}
                            />
                        </Col>
                        <Col {...COL_SIZES}>
                            <AdSourceDuration
                                name={AD_SOURCE_MAX_DURATION_DEFAULT.name}
                                label={AD_SOURCE_MAX_DURATION_DEFAULT.label}
                                type="max"
                                oppositeName={AD_SOURCE_MIN_DURATION_DEFAULT.name}
                                duration={seat.adSourceMaxDurationDefault}
                                edit={edit}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col {...COL_SIZES}>
                            <AdSourceAuctionTypeDefault seat={seat} edit={edit} />
                        </Col>
                    </Row>
                </Col>
                {isInternalOnly && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <SeatDefaultsInternalOnly seat={seat} edit={edit} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
