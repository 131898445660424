import { AdSource } from "@app/core/services";
import { AdSourceTypeIds, DefaultDemandAcquisitionCostModel } from "../../constants";
import { useDemandAcquisitionCostUtils } from "../AdSourcesSections/EnrichmentCostSection/useDemandAcquisitionCostUtils";

interface UseParseEnrichmentCostFields {
    parseEnrichmentCostFieldsToApi: (
        adSourceTypeId: number,
        demandAcquisitionCost: number | string | null,
        demandAcquisitionCostModelId: number | null
    ) => EnrichmentCostApiFields;
}

interface EnrichmentCostFields {
    demandAcquisitionCost: number | null;
    operatorCost: number | null;
}

export interface EnrichmentCostFormFields extends EnrichmentCostFields {
    demandAcquisitionCostModel: { id: number; name: string } | null;
    operatorCostModel: { id: number; name: string } | null;
}

export interface EnrichmentCostApiFields extends EnrichmentCostFields {
    demandAcquisitionCostModel: { id: number } | null;
    operatorCostModel: { id: number } | null;
}

export const parseEnrichmentCostFieldsFromApi = (adSource: AdSource | undefined): EnrichmentCostFormFields => {
    const demandAcquisitionCost = adSource?.demandAcquisitionCost;
    return {
        demandAcquisitionCost: typeof demandAcquisitionCost === "number" ? demandAcquisitionCost / 1000 : null,
        demandAcquisitionCostModel: adSource?.demandAcquisitionCostModel || null,
        operatorCost: adSource?.operatorCost || null,
        operatorCostModel: { id: 1, name: "Fixed" },
    };
};

const isAdSourceTypeDemandAcquisitionCostIneligible = (adSourceTypeId: AdSourceTypeIds): boolean =>
    adSourceTypeId === AdSourceTypeIds.MARKETPLACE || adSourceTypeId === AdSourceTypeIds.CURATOR_MARKETPLACE;

export const useParseEnrichmentCostFields = (): UseParseEnrichmentCostFields => {
    const { isDemandAcquisitionEnabled } = useDemandAcquisitionCostUtils();

    const parseEnrichmentCostFieldsToApi = (
        adSourceTypeId: number,
        demandAcquisitionCost: number | string | null,
        demandAcquisitionCostModelId: number | null
    ) => {
        const _demandAcquisitionCost = Number(demandAcquisitionCost);
        return {
            demandAcquisitionCost:
                !isDemandAcquisitionEnabled ||
                isNaN(_demandAcquisitionCost) ||
                isAdSourceTypeDemandAcquisitionCostIneligible(adSourceTypeId)
                    ? null
                    : _demandAcquisitionCost * 1000,
            demandAcquisitionCostModel:
                !isDemandAcquisitionEnabled || isAdSourceTypeDemandAcquisitionCostIneligible(adSourceTypeId)
                    ? null
                    : { id: demandAcquisitionCostModelId ?? DefaultDemandAcquisitionCostModel.id },
            operatorCost: null,
            operatorCostModel: { id: 1, name: "Fixed" },
        };
    };

    return {
        parseEnrichmentCostFieldsToApi,
    };
};
