import { FC } from "react";
import { useAppDispatch } from "@app/core/store";
import { PacingDeliveryScheduleRadio } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections/PacingSection/Fields";
import { dealFormPacingDeliverySchedulesChange, dealFormRadioGroupChange } from "@app/features/deals/DealForm/reducer";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { FormPacingDeliverySchedule } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import { Form } from "antd";

const { AD_SOURCE_PACING_DELIVERY_SCHEDULE_ENABLED, AD_SOURCE_PACING_DELIVERY_SCHEDULES } = CREATE_DEAL_FORM_ITEMS_NAME;

export const AdSourcePacingDeliveryScheduleRadio: FC = () => {
    const dispatch = useAppDispatch();
    const form = Form.useFormInstance();
    const startDate = Form.useWatch<moment.Moment | null>(CREATE_DEAL_FORM_ITEMS_NAME.START_DATE, form);
    const endDate = Form.useWatch<moment.Moment | null>(CREATE_DEAL_FORM_ITEMS_NAME.END_DATE, form);

    const onChange = (isEnabled: boolean, schedules: FormPacingDeliverySchedule[]) => {
        dispatch(
            dealFormRadioGroupChange({
                field: AD_SOURCE_PACING_DELIVERY_SCHEDULE_ENABLED,
                value: isEnabled,
            })
        );
        dispatch(dealFormPacingDeliverySchedulesChange(schedules));
    };

    return (
        <PacingDeliveryScheduleRadio
            name={AD_SOURCE_PACING_DELIVERY_SCHEDULE_ENABLED}
            deliveryScheduleName={AD_SOURCE_PACING_DELIVERY_SCHEDULES}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
        />
    );
};
