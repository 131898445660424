import { useEffect, useState } from "react";
import { FORM_FIELDS } from "./constants";
import { useDispatch } from "react-redux";
import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { FormInstance, notification } from "antd";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { useSeatTree } from "@app/features/inventory/InventoryBrandSafety/SeatTree/useSeatTree";
import { setEditActiveKey, setIsActionsDisabled } from "@app/features/inventory/InventoryBrandSafety/reducer";
import {
    Publisher,
    useUpdatePublisherBrandSafetyMutation,
    useUpdatePublisherChannelBrandSafetyMutation,
} from "@app/core/services/console";

export interface PublisherDetailsFormValues {
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: LabeledValue | null;
}

interface UsePublisherBrandSafetyEditForm {
    publisherForm: FormInstance<PublisherDetailsFormValues>;
    initialValues: Store | undefined;
    handleSubmit: () => void;
    handleDelete: () => void;
    resetFormsFields: () => void;
    isUpdating: boolean;
    isDeleting: boolean;
}

export const getInitialValuesFromPublisher = (publisher: Publisher | null): Store | undefined => {
    if (!publisher) return undefined;

    return {
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: publisher.seatAdvertiserDomainFilterListDefs.map(
            (list) => ({
                value: list.id,
                label: list.name,
            })
        ),
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: publisher.advertiserBlockDomains.join("\n"),
        [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: publisher.buyerSeatList
            ? { value: publisher.buyerSeatList.id, label: publisher.buyerSeatList.name }
            : null,
    };
};

export const usePublisherBrandSafetyEditForm = (publisher: Publisher): UsePublisherBrandSafetyEditForm => {
    const dispatch = useDispatch();
    const [updatePublisherBrandSafety] = useUpdatePublisherBrandSafetyMutation();
    const [updatePublisherChannelBrandSafety] = useUpdatePublisherChannelBrandSafetyMutation();

    const { publisherForm, resetFormsFields } = useSeatTree();
    const initialValues = getInitialValuesFromPublisher(publisher);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const isChannel: boolean = publisher.type.name === "Channel";

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleSubmit = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsUpdating(true);
        const { blockedAdvertiserDomainLists, blockedAdvertiserDomains, blockedBuyerSeatList } =
            publisherForm.getFieldsValue(Object.values(FORM_FIELDS).map((field) => field.name));
        try {
            if (isChannel) {
                await updatePublisherChannelBrandSafety({
                    id: publisher.id,
                    payload: {
                        publisher: {
                            advertiserBlockDomains: blockedAdvertiserDomains.length
                                ? blockedAdvertiserDomains?.split("\n")
                                : null,
                            buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
                            seatAdvertiserDomainFilterListDefs: (blockedAdvertiserDomainLists || []).map((option) => ({
                                id: option.value as number,
                                name: option.label as string,
                            })),
                        },
                    },
                }).unwrap();
            } else {
                await updatePublisherBrandSafety({
                    id: publisher.id,
                    advertiserBlockDomains: blockedAdvertiserDomains.length
                        ? blockedAdvertiserDomains?.split("\n")
                        : null,
                    buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
                    seatAdvertiserDomainFilterListDefs: blockedAdvertiserDomainLists.length
                        ? blockedAdvertiserDomainLists.map(({ value }) => ({ id: value as number }))
                        : null,
                }).unwrap();
            }
            dispatch(setEditActiveKey(null));
            notification.success({ message: `Publisher updated successfully` });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            resetFormsFields();
            dispatch(setIsActionsDisabled(false));
            setIsUpdating(false);
        }
    };

    const handleDelete = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsDeleting(true);
        try {
            if (isChannel) {
                await updatePublisherChannelBrandSafety({
                    id: publisher.id,
                    payload: {
                        publisher: {
                            advertiserBlockDomains: [],
                            buyerSeatList: null,
                            seatAdvertiserDomainFilterListDefs: [],
                        },
                    },
                }).unwrap();
            } else {
                await updatePublisherBrandSafety({
                    id: publisher.id,
                    advertiserBlockDomains: [],
                    buyerSeatList: null,
                    seatAdvertiserDomainFilterListDefs: [],
                }).unwrap();
            }
            notification.success({ message: `Publisher updated successfully` });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            resetFormsFields();
            dispatch(setIsActionsDisabled(false));
            setIsDeleting(false);
        }
    };

    //INFO: Set initial values to form due to upredictable behavior of antd form
    useEffect(() => {
        if (initialValues && !isUpdating) {
            publisherForm.setFieldsValue(initialValues);
        }
    }, [initialValues, isUpdating, publisherForm]);

    return {
        isUpdating,
        isDeleting,
        publisherForm,
        handleSubmit,
        handleDelete,
        resetFormsFields,
        initialValues,
    };
};
