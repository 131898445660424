import { FC, useState } from "react";
import { Card, Form, Layout } from "antd";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { FixedSaveButtons } from "@app/core/components";
import { useHistory, useParams } from "react-router-dom";
import { useCreateSeatLabelMutation } from "@app/core/services";
import { getLabelFormInitialValues, onFinishCreateLabel } from "../helpers";
import { ClassificationsLabelFormBaseFormItems } from "../ClassificationsLabelFormBaseFormItems";
import { CLASSIFICATIONS_LABEL_FORM_ITEMS } from "@app/features/controls/classifications/labels/ClassificationsLabelForm/constants";

export const CLASSIFICATIONS_LABEL_FORM_SDET = "classifications-label-form";

export const ClassificationsLabelCreateForm: FC = () => {
    const [form] = Form.useForm();
    const isDistributionGroup = Form.useWatch(CLASSIFICATIONS_LABEL_FORM_ITEMS.DISTRIBUTION_GROUP.name, form);
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { seatId } = useParams<{ seatId: string }>();
    const [createClassificationsLabel] = useCreateSeatLabelMutation();

    const initialValues = getLabelFormInitialValues();

    return (
        <Form
            data-sdet={CLASSIFICATIONS_LABEL_FORM_SDET}
            form={form}
            layout="vertical"
            wrapperCol={{ xs: 24, lg: 12 }}
            initialValues={initialValues}
            onFinish={(values) => {
                onFinishCreateLabel(values, setIsSubmitting, seatId, createClassificationsLabel, history);
            }}
        >
            <Layout.Content style={{ height: "90vh" }}>
                <Card>
                    <ClassificationsLabelFormBaseFormItems isDistributionGroup={isDistributionGroup} />
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                isLoading={isSubmitting}
                submitLabel="Submit & Add Values"
                onCancel={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_CLASSIFICATIONS_TABS(seatId, "labels"))}
            />
        </Form>
    );
};
