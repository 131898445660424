import { FC } from "react";
import { Form, Radio, RadioChangeEvent } from "antd";
import { Moment } from "moment-timezone";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { DELIVERY_SCHEDULE, FormPacingDeliverySchedule } from "../../PacingDeliverySchedule";

const { END_DATE, PACING_DELIVERY_SCHEDULE_ENABLED, START_DATE } = AD_SOURCE_FIELDS;

interface Props {
    name: string;
    deliveryScheduleName: string;
    startDate: Moment | null;
    endDate: Moment | null;
    onChange?: (isEnabled: boolean, deliverySchedule: FormPacingDeliverySchedule[]) => void;
}

export const PacingDeliveryScheduleRadio: FC<Props> = ({
    name,
    deliveryScheduleName,
    startDate,
    endDate,
    onChange: propOnChange,
}) => {
    const form = Form.useFormInstance();
    const value = Form.useWatch<boolean | undefined>(name, form);

    const isDisabled = !value && (!startDate || !endDate);

    const help = isDisabled
        ? `Please ensure the Ad Source has a valid ${START_DATE.label} and ${END_DATE.label} set before adding ${DELIVERY_SCHEDULE}s`
        : undefined;

    const onChange = ({ target: { value: isEnabled } }: RadioChangeEvent) => {
        const newSchedules = isEnabled
            ? ([
                  { dates: [startDate, null], impressionGoal: null, dailyCap: null },
                  { dates: [null, endDate], impressionGoal: null, dailyCap: null },
              ] as FormPacingDeliverySchedule[])
            : [];
        form.setFieldValue(deliveryScheduleName, newSchedules);
        if (propOnChange) {
            propOnChange(isEnabled, newSchedules);
        }
    };

    return (
        <Form.Item name={name} label={PACING_DELIVERY_SCHEDULE_ENABLED.label} help={help} {...VERTICAL_LAYOUT_FIX}>
            <Radio.Group data-sdet="pacing-delivery-schedule-radio" disabled={isDisabled} onChange={onChange}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
