import { AdUnit, Brand, BrandChannel, BrandSupply, Publisher } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";
import { useUnitData } from "../useUnitData";

interface UseUnitContent {
    drawerType: InventoryDetailsDrawerType;
    title: string;
    isLoading: boolean;
    publisherData?: Publisher;
    brandData?: Brand;
    supplyData?: BrandSupply;
    adUnitData?: AdUnit;
    channelData?: BrandChannel;
}

export const useUnitContent = (id: string | number): UseUnitContent => {
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const { brandData, publisherData, adUnitData, supplyData, channelData, isLoading } = useUnitData(id, drawerType);
    let title = "";

    switch (drawerType) {
        case InventoryDetailsDrawerType.PUBLISHER:
            title = `Publisher Details: ${publisherData?.name}`;
            break;
        case InventoryDetailsDrawerType.BRAND:
            title = `Brand Details: ${brandData?.name}`;
            break;
        case InventoryDetailsDrawerType.SUPPLY:
            title = `Supply Details: ${supplyData?.name}`;
            break;
        case InventoryDetailsDrawerType.AD_UNIT:
            if (adUnitData?.supply.brand.entityType === "Channel") {
                title = `${adUnitData?.supply.brand.name} / Ad Unit: ${adUnitData?.name}`;
                break;
            }
            title = `Ad Unit: ${adUnitData?.name}`;
            break;
        case InventoryDetailsDrawerType.CHANNEL:
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            title = `Channel Details: ${channelData?.name}`;
            break;
    }

    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        drawerType,
        title,
        isLoading,
        publisherData,
        brandData,
        supplyData,
        adUnitData,
        channelData,
    };
};
