import { NONE } from "@app/core/components/constants";
import { TransparencyDefaultRaw, useGetTransparencyDefaultQuery } from "@app/core/services";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";
import { Table, Form } from "antd";
import { useParams } from "react-router-dom";
import { AllowAllExtendedIdCell } from "./AllowAllExtendedIdCell";
import { AllowUserIdCell } from "./AllowUserIdCell";
import { useIsEditing } from "./useIsEditing";
import { AllowContentCell } from "./AllowContentCell";
import { ShareHideCheckboxCell } from "./ShareHideCheckboxCell";

const getColumns = () => [
    {
        dataIndex: "adSourceType",
        key: "adSourceType",
        sorter: false,
        title: "Ad Source Type",
    },
    {
        dataIndex: "allowSiteName",
        key: "allowSiteName",
        sorter: false,
        title: "Supply Name / App Name",
    },
    {
        dataIndex: "allowDomainName",
        key: "allowDomainName",
        sorter: false,
        title: "Domain Name",
    },
    {
        dataIndex: "allowSitePage",
        key: "allowSitePage",
        sorter: false,
        title: "Supply Page / Content URL",
    },
    {
        dataIndex: "allowRef",
        key: "allowRef",
        sorter: false,
        title: "Refer URL",
    },
    {
        dataIndex: "allowIp",
        key: "allowIp",
        sorter: false,
        title: "Full IP Address",
    },
    {
        dataIndex: "allowBundleId",
        key: "allowBundleId",
        sorter: false,
        title: "Bundle ID",
    },
    {
        dataIndex: "allowStoreUrl",
        key: "allowStoreUrl",
        sorter: false,
        title: "Store URL",
    },
    {
        dataIndex: "allowUserId",
        key: "allowUserId",
        sorter: false,
        title: "User ID / IFA",
    },
    {
        dataIndex: "allowContent",
        key: "allowContent",
        sorter: false,
        title: "Content",
    },
    {
        dataIndex: "allowAllExtendedId",
        key: "allowAllExtendedId",
        sorter: false,
        title: "Extended User ID",
    },
];

const getTableRows = (data: TransparencyDefaultRaw[] | undefined, isEditing) => {
    if (!data) {
        return [];
    }
    return data.map((datum, i) => {
        const {
            adSourceType,
            allowUserId,
            maskUserId,
            allowSiteName,
            allowDomainName,
            allowContent,
            contentTransparencyRule,
            allowSitePage,
            allowRef,
            allowIp,
            allowBundleId,
            allowStoreUrl,
            allowAllExtendedId,
            allowedExtendedIdTransparencies,
        } = datum;

        return {
            key: adSourceType.id,
            adSourceType: AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] || NONE,
            allowSiteName: (
                <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowSiteName" value={allowSiteName} />
            ),
            allowBundleId: (
                <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowBundleId" value={allowBundleId} />
            ),
            allowDomainName: (
                <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowDomainName" value={allowDomainName} />
            ),
            allowRef: <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowRef" value={allowRef} />,
            allowIp: <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowIp" value={allowIp} />,
            allowSitePage: (
                <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowSitePage" value={allowSitePage} />
            ),
            allowStoreUrl: (
                <ShareHideCheckboxCell isEditing={isEditing} index={i} name="allowStoreUrl" value={allowStoreUrl} />
            ),
            allowUserId: (
                <AllowUserIdCell index={i} isEditing={isEditing} allowUserId={allowUserId} maskUserId={maskUserId} />
            ),
            allowContent: (
                <AllowContentCell
                    index={i}
                    isEditing={isEditing}
                    allowContent={allowContent}
                    contentTransparencyRule={contentTransparencyRule}
                />
            ),
            allowAllExtendedId: (
                <>
                    <AllowAllExtendedIdCell
                        index={i}
                        allowedExtendedIdTransparencies={allowedExtendedIdTransparencies}
                        isEditing={isEditing}
                        allowAllExtendedId={allowAllExtendedId}
                    />
                    <Form.Item noStyle name={[i, "transparency"]} initialValue={datum} />
                </>
            ),
        };
    });
};

export const TransparencyDefaultTable = () => {
    const { isEditing } = useIsEditing();
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetTransparencyDefaultQuery(seatId);

    return (
        <Form.List name="transparency">
            {() => (
                <Table
                    data-sdet="transparency-default-list-table"
                    size="small"
                    columns={getColumns()}
                    dataSource={getTableRows(data, isEditing)}
                    loading={isFetching}
                    scroll={{ x: 1000 }}
                    showSorterTooltip={false}
                    pagination={false}
                />
            )}
        </Form.List>
    );
};
