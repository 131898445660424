import { FC, useRef } from "react";
import { useDealCurrencyConversion } from "@app/features/deals/useDealCurrencyConversion";
import { AdStat, getFills, getImpressions, getSkips, getTotalRevenue, getTries } from "@app/features/adStats";
import { useSelectAdStatDealById } from "@app/features/adStats/reducer";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import {
    FILLS_LABEL,
    IMPRESSIONS_LABEL,
    NET_REVENUE_LABEL,
    SKIPS_LABEL,
    TRIES_LABEL,
} from "@app/core/components/charts/constants";
import { ValueMetric1, ValueMetric2 } from "@app/core/components/charts/DualAxesChart";
import { RevenueTypes } from "@app/core/clients/console";
import moment from "moment-timezone";
import { MixCountAreaCurrencyLineChart } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";
import { selectUserTimezone } from "@app/core/authClient/reducer";
import { useAppSelector } from "@app/core/store";
import { Loading } from "@app/core/components";
import { X_AXIS_TICK_COUNT } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";

export interface DealsChartStats {
    time: number;
    tries: number;
    fills: number;
    impressions: number;
    netRev: number;
    skips: number;
}

/**
 * Events / Second
 *
 * @param existingMetrics The existing metrics for Events / Second
 * @param stats The current state of the stats coming in from AdStats
 * @param timeZoneCode The selected time zone code
 * @returns The Events per second metrics with a new set of metrics added for the current time
 */
const getEventMetrics = (existingMetrics, stats: DealsChartStats, timeZoneCode: string) => {
    const time = moment(stats.time).tz(timeZoneCode).format("HH:mm:ss");
    const newMetrics = [
        ...existingMetrics,
        {
            time,
            value1: stats?.skips || 0,
            name1: SKIPS_LABEL,
        },
        {
            time,
            value1: stats?.tries || 0,
            name1: TRIES_LABEL,
        },
        {
            time,
            value1: stats?.fills || 0,
            name1: FILLS_LABEL,
        },
        {
            time,
            value1: stats?.impressions || 0,
            name1: IMPRESSIONS_LABEL,
        },
    ];

    const timeMap = new Map<string, number>();
    newMetrics.forEach((metric) => {
        const key = `${metric.time}-${metric.name1}`;
        const count = timeMap.get(key) ?? 0;
        timeMap.set(key, count + 1);
    });
    const dedupedMetrics = newMetrics.filter((metric) => {
        const key = `${metric.time}-${metric.name1}`;
        const count = timeMap.get(key) ?? 0;
        const isDuplicate = count > 1;
        if (isDuplicate) {
            timeMap.set(key, count - 1);
            return false;
        }
        return true;
    });

    const newEventCount = dedupedMetrics.length - existingMetrics.length;
    if (dedupedMetrics.length > X_AXIS_TICK_COUNT * newEventCount) {
        dedupedMetrics.splice(0, newEventCount);
    }

    return dedupedMetrics;
};

/**
 * Revenue / Second
 *
 * @param existingMetrics The existing metrics for Revenue / Second
 * @param stats The current state of the stats coming in from AdStats
 * @param timeZoneCode The selected time zone code
 * @returns The Revenue per second metrics with a new set of metrics added for the current time
 */
const getRevenueMetrics = (existingMetrics, stats: DealsChartStats, timeZoneCode: string) => {
    const time = moment(stats.time).tz(timeZoneCode).format("HH:mm:ss");
    const newMetrics = [
        ...existingMetrics,
        {
            time,
            value2: stats?.netRev || 0,
            name2: NET_REVENUE_LABEL,
        },
    ];

    const newEventCount = newMetrics.length - existingMetrics.length;

    if (newMetrics.length > X_AXIS_TICK_COUNT * newEventCount) {
        newMetrics.splice(0, newEventCount);
    }

    const timeSet = new Set<string>();
    const filteredNewMetrics = newMetrics.filter((metric) => {
        if (timeSet.has(`${metric.time}-${metric.name1}`)) {
            return false;
        }
        timeSet.add(`${metric.time}-${metric.name1}`);
        return true;
    });
    return filteredNewMetrics;
};

interface DealsLiveChartProps {
    dealId: number;
    sourceCurrencyCode: string | undefined;
    chartId?: string;
}

export const DealsLiveChart: FC<DealsLiveChartProps> = ({ dealId, sourceCurrencyCode, chartId }) => {
    const { preferredCurrency, currencyConversions, currencyConversionMode } =
        useDealCurrencyConversion(sourceCurrencyCode);
    const revenueType = RevenueTypes.NET_REVENUE;
    const previousStat = useRef<DealsChartStats>();
    const eventsMetric = useRef<ValueMetric1[]>([]);
    const revMetrics = useRef<ValueMetric2[]>([]);
    const timeZoneCode = useAppSelector(selectUserTimezone);
    const timeZoneCodeRef = useRef<string>();
    const previousOTimeRef = useRef<number>();

    const isTimeZoneChangeRequest = timeZoneCodeRef.current && timeZoneCode !== timeZoneCodeRef.current;
    if (isTimeZoneChangeRequest) {
        previousStat.current = undefined;
        eventsMetric.current = [];
        revMetrics.current = [];
        timeZoneCodeRef.current = undefined;
    }

    const statData = useSelectAdStatDealById<{ chartStats: DealsChartStats; oTime: number } | undefined>(
        dealId,
        (adStat: AdStat | null) => {
            if (!adStat) {
                return;
            }
            return {
                chartStats: {
                    time: adStat.ntime,
                    skips: getSkips(adStat),
                    tries: getTries(adStat),
                    fills: getFills(adStat, preferredCurrency, currencyConversionMode),
                    impressions: getImpressions(adStat, preferredCurrency, currencyConversionMode),
                    netRev: getTotalRevenue(
                        adStat,
                        preferredCurrency,
                        currencyConversions,
                        currencyConversionMode,
                        revenueType
                    ),
                },
                oTime: adStat.otime,
            };
        }
    );
    const stats = statData?.chartStats;
    const oTime = statData?.oTime;

    const haveRecievedTwoConsecutiveStats = stats && previousStat.current && oTime && previousOTimeRef.current;
    if (haveRecievedTwoConsecutiveStats) {
        const statsHaveSameTimeZone = oTime === previousOTimeRef.current;
        if (statsHaveSameTimeZone && previousStat.current) {
            const isNewStatBucket = stats.time > previousStat.current.time;
            if (isNewStatBucket) {
                const statDiff = {
                    time: stats.time,
                    skips: stats.skips - previousStat.current.skips,
                    tries: stats.tries - previousStat.current.tries,
                    fills: stats.fills - previousStat.current.fills,
                    impressions: stats.impressions - previousStat.current.impressions,
                    netRev: stats.netRev - previousStat.current.netRev,
                };
                eventsMetric.current = getEventMetrics(eventsMetric.current, statDiff, timeZoneCode);
                revMetrics.current = getRevenueMetrics(revMetrics.current, statDiff, timeZoneCode);
            }
        }
    }

    previousStat.current = stats;
    previousOTimeRef.current = oTime;
    timeZoneCodeRef.current = timeZoneCode;
    if (!eventsMetric.current.length || !revMetrics.current.length || isTimeZoneChangeRequest) {
        return <Loading position="relative" />;
    }

    return (
        <>
            <TogglableChart
                metricOne={eventsMetric.current}
                metricTwo={revMetrics.current}
                chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                    <MixCountAreaCurrencyLineChart
                        height={300}
                        chartId={`deal-live-chart-${chartId}`}
                        metricOne={filteredMetricOne}
                        metricTwo={filteredMetricTwo}
                        metricOneYAxisTitle="Events / Sec"
                        metricTwoYAxisTitle="Rev / Sec"
                    />
                )}
            />
        </>
    );
};
