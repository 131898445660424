import { Title } from "@app/core/components";
import { Form, Collapse, Space, Button } from "antd";
import { ItemType } from "rc-collapse/es/interface";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { WaterfallPrefilteringSection } from "@app/features/inventory/components/Fields";
import { ConfirmationModal } from "@app/features/inventory/components/ConfirmationModal";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { AdPodSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdPodSection";
import { FloorsSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/FloorsSection";
import { LabelsSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/LabelsSection";
import { useAdUnitForm } from "@app/features/inventory/HierarchyForms/AdUnitForm/useAdUnitForm";
import { GeneralSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/GeneralSection";
import { InternalSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/InternalSection";
import { AdBreakRules } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdBreakRulesSection";
import { AffiliateSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/AffiliateSection";
import { INVENTORY_HEADER_COLLAPSE_CLASS_LIST } from "@app/features/inventory/InventorySeat/constants";
import { BrandSafetySection } from "@app/features/inventory/HierarchyForms/AdUnitForm/BrandSafetySection";
import { CustomPixelsSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/CustomPixelsSection";
import { UDConfigurationSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/UDConfigurationSection";
import { DemandRequirementsSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/DemandRequirementsSection";
import { SupplyDetailsSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/SupplySection/SupplyDetailsSection";
import { PlaylistConfigurationSection } from "@app/features/inventory/HierarchyForms/AdUnitForm/PlaylistConfigurationSection";
import { useAdUnitFormDefinitionsFields } from "@app/features/inventory/HierarchyForms/AdUnitForm/useAdUnitFormDefinitionsFields";

export const AdUnitForm = () => {
    const {
        form,
        open,
        adUnit,
        loading,
        supply,
        showModal,
        isDeleting,
        isEditMode,
        handleSubmit,
        handleDelete,
        handleCancel,
        initialValues,
        handleCancelModal,
        isPodSectionShown,
        handleAdvancedFloors,
        hasSeatWriteAccess,
        isPlaylistSectionShown,
        submitButtonTitle,
        isFloorsSectionShown,
        isInternalSectionShown,
        UDConfigurationSectionShown,
        isAffiliateSectionShown,
        isAdBreakRulesSectionShown,
    } = useAdUnitForm();

    const {
        UDDefinitionsFields,
        adPodDefinitionsFields,
        labelsDefinitionsFields,
        generalDefinitionsFields,
        internalDefinitionsFields,
        brandSafetyDefinitionsFields,
        customPixelsDefinitionsFields,
        supplyDetailsDefinitionsFields,
        demandRequirementsDefinitionsFields,
        affiliateDefinitionsFields,
        playlistConfigurationDefinitionsFields,
    } = useAdUnitFormDefinitionsFields();

    return (
        <Form form={form} name="adUnitForm" layout="vertical" initialValues={initialValues}>
            <Collapse
                bordered
                ghost={false}
                className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                defaultActiveKey={Array.from({ length: 14 }, (_, i) => String(i - 1))}
                collapsible="header"
                items={
                    [
                        {
                            key: "0",
                            children: <GeneralSection />,
                            label: <Title level={5} title="General" />,
                            extra: <HelpKeysDrawer panelLabel="General" definitionsFields={generalDefinitionsFields} />,
                        },
                        isAffiliateSectionShown && {
                            key: "1",
                            children: <AffiliateSection />,
                            label: <Title level={5} title="Affiliate (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Affiliate" definitionsFields={affiliateDefinitionsFields} />
                            ),
                        },
                        {
                            key: "2",
                            children: <BrandSafetySection />,
                            label: <Title level={5} title="Brand Safety" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Brand Safety"
                                    definitionsFields={brandSafetyDefinitionsFields}
                                />
                            ),
                        },
                        isInternalSectionShown && {
                            key: "3",
                            children: <InternalSection />,
                            label: <Title level={5} title="Internal (Internal only)" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Internal" definitionsFields={internalDefinitionsFields} />
                            ),
                        },
                        {
                            key: "4",
                            children: <DemandRequirementsSection />,
                            label: <Title level={5} title="Demand Requirements" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Demand Requirements"
                                    definitionsFields={demandRequirementsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "5",
                            children: <SupplyDetailsSection />,
                            label: <Title level={5} title="Supply Details" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Supply Details"
                                    definitionsFields={supplyDetailsDefinitionsFields}
                                />
                            ),
                        },
                        isPodSectionShown && {
                            key: "6",
                            children: <AdPodSection />,
                            label: <Title level={5} title="Ad Pod" />,
                            extra: <HelpKeysDrawer panelLabel="Ad Pod" definitionsFields={adPodDefinitionsFields} />,
                        },
                        isPlaylistSectionShown && {
                            key: "7",
                            children: <PlaylistConfigurationSection />,
                            label: <Title level={5} title="Playlist Configuration" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Playlist Configuration"
                                    definitionsFields={playlistConfigurationDefinitionsFields}
                                />
                            ),
                        },
                        isAdBreakRulesSectionShown && {
                            key: "8",
                            children: <AdBreakRules />,
                            label: <Title level={5} title="Ad Break Rules" />,
                        },
                        UDConfigurationSectionShown && {
                            key: "9",
                            children: <UDConfigurationSection />,
                            label: <Title level={5} title="UD Configuration" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="UD Configuration" definitionsFields={UDDefinitionsFields} />
                            ),
                        },
                        {
                            key: "10",
                            children: <CustomPixelsSection />,
                            label: <Title level={5} title="Custom Pixels" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Custom Pixels"
                                    definitionsFields={customPixelsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "11",
                            children: <LabelsSection />,
                            label: <Title level={5} title="Labels" />,
                            extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                        },
                        {
                            key: "12",
                            children: (
                                <WaterfallPrefilteringSection inheritedPrefilterLabels={supply?.prefilterLabelValues} />
                            ),
                            label: <Title level={5} title="Waterfall Prefiltering" />,
                        },
                        isFloorsSectionShown && {
                            key: "13",
                            children: <FloorsSection />,
                            label: (
                                <Space>
                                    <Title level={5} title="Floors" />
                                    {hasSeatWriteAccess && (
                                        <Button onClick={handleAdvancedFloors} type="link" size="small">
                                            Advanced Floors
                                        </Button>
                                    )}
                                </Space>
                            ),
                        },
                    ].filter(Boolean) as ItemType[]
                }
            />
            <AffixFormButtonsBar
                loading={loading}
                disabled={loading}
                onSubmit={handleSubmit}
                onDelete={showModal}
                onCancel={handleCancel}
                isDeleting={isDeleting}
                isDeleteButtonShown={isEditMode}
                submitButtonTitle={submitButtonTitle}
            />
            <ConfirmationModal
                open={open}
                onOk={handleDelete}
                name={adUnit?.name}
                onDelete={handleDelete}
                confirmLoading={isDeleting}
                onCancel={handleCancelModal}
            />
        </Form>
    );
};
