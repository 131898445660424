import { FormInstance } from "antd";
import { Moment } from "moment";
import { END_DATE_INDEX, FIRST_SCHEDULE_INDEX, MINIMUM_SCHEDULES, START_DATE_INDEX } from "./constants";
import { FormPacingDeliverySchedule } from "./types";
import { cloneSchedules } from "./utils";

interface UseSyncPacingDeliveryScheduleDates {
    updatePacingDeliveryScheduleDates: (dates: { startDate?: Moment | null; endDate?: Moment | null }) => void;
}

export const useSyncPacingDeliveryScheduleDates = (
    deliveryScheduleName: string,
    form: FormInstance,
    onChange?: (schedules: FormPacingDeliverySchedule[]) => void
): UseSyncPacingDeliveryScheduleDates => {
    const updatePacingDeliveryScheduleDates = ({ startDate, endDate }) => {
        const schedules: FormPacingDeliverySchedule[] = form.getFieldValue(deliveryScheduleName) ?? [];

        if (schedules.length < MINIMUM_SCHEDULES || (!startDate && !endDate)) {
            return;
        }

        const newSchedules = cloneSchedules(schedules);

        if (endDate) {
            newSchedules[newSchedules.length - 1].dates[END_DATE_INDEX] = endDate;
        }

        if (startDate) {
            newSchedules[FIRST_SCHEDULE_INDEX].dates[START_DATE_INDEX] = startDate;
        }

        const trimmedSchedules = newSchedules.map((schedule, scheduleIndex) => ({
            ...schedule,
            dates: schedule.dates.map((scheduleDate, dateIndex) => {
                if (!scheduleDate) {
                    return null;
                }
                const isFirstStartDate = scheduleIndex === FIRST_SCHEDULE_INDEX && dateIndex === START_DATE_INDEX;
                const isLastEndDate = scheduleIndex === newSchedules.length - 1 && dateIndex === END_DATE_INDEX;
                const isStartDateSameOrBefore =
                    !isFirstStartDate && startDate && scheduleDate.isSameOrBefore(startDate);
                const isEndDateSameOrAfter = !isLastEndDate && endDate && scheduleDate.isSameOrAfter(endDate);
                if (isStartDateSameOrBefore || isEndDateSameOrAfter) {
                    return null;
                }
                return scheduleDate;
            }),
        }));

        form.setFieldValue(deliveryScheduleName, trimmedSchedules);

        if (onChange) {
            onChange(trimmedSchedules);
        }
    };

    return {
        updatePacingDeliveryScheduleDates,
    };
};
