import { FC } from "react";
import { Col, Form, Row } from "antd";
import { css } from "@emotion/css";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { SCHEDULE_COL_SPAN as SPAN } from "./constants";
import { applyVerticalLayout } from "./utils";

const {
    PACING_DELIVERY_SCHEDULE_DATES: DATE_RANGE,
    PACING_DELIVERY_SCHEDULE_IMPRESSION_GOAL: IMPRESSION_GOAL,
    PACING_DELIVERY_SCHEDULE_DAILY_IMPRESSION_CAP: DAILY_IMPRESSION_CAP,
} = AD_SOURCE_FIELDS;

interface Props {
    isImpressionGoalRequired: boolean;
    vertical: boolean;
}

const colCss = css`
    .ant-form-item {
        margin-bottom: 8px !important;
    }
    .ant-form-item-label {
        padding: 0 !important;
    }
    .ant-form-item-control-input {
        display: none;
    }
`;

export const ScheduleHeaderRow: FC<Props> = ({ isImpressionGoalRequired, vertical }) => {
    return (
        <Row data-sdet="schedule-header-row" justify="space-between">
            <Col span={SPAN.NAME} />
            <Col span={SPAN.DATE_RANGE} className={colCss}>
                <Form.Item
                    label={DATE_RANGE.label}
                    extra={DATE_RANGE.extra}
                    required
                    {...applyVerticalLayout(vertical)}
                />
            </Col>
            <Col span={SPAN.IMPRESSION_GOAL} className={colCss}>
                <Form.Item
                    label={IMPRESSION_GOAL.label}
                    extra={IMPRESSION_GOAL.extra}
                    required={isImpressionGoalRequired}
                    {...applyVerticalLayout(vertical)}
                />
            </Col>
            <Col span={SPAN.DAILY_IMPRESSION_CAP} className={colCss}>
                <Form.Item
                    label={DAILY_IMPRESSION_CAP.label}
                    extra={DAILY_IMPRESSION_CAP.extra}
                    {...applyVerticalLayout(vertical)}
                />
            </Col>
            <Col span={SPAN.DELETE} />
        </Row>
    );
};
