import { consoleApi, EXTENDED_IDS_TAG } from "./console";

export interface ExtendedId {
    code?: string;
    creationDate?: string;
    id: number;
    name: string;
    seat?: unknown;
    updateDate?: string;
}

export const extendedIdsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getExtendedIds: builder.query<ExtendedId[], number>({
            query: (seatId: number) => `extendedId;seatId=${seatId}`,
            providesTags: (_, __, id) => [{ type: EXTENDED_IDS_TAG, id }],
        }),
    }),
    overrideExisting: false,
});

export const { useGetExtendedIdsQuery } = extendedIdsApi;
