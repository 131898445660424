import { ContentTransparencyRule } from "@app/core/services";
import { Form, Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { FC, useMemo } from "react";
import { useContentTransparencyRules } from "../ContentTransparencyRules/useContentTransparencyRules";

interface Props {
    allowContent: boolean;
    contentTransparencyRule: ContentTransparencyRule | undefined | null;
    index: number;
    isEditing: boolean;
}

export const mapContentRuleApiToReadOnly = (
    allowContent: boolean,
    contentTransparencyRule: ContentTransparencyRule | undefined | null
) => {
    if (allowContent && contentTransparencyRule) {
        // custom
        return contentTransparencyRule.name;
    }
    if (allowContent && !contentTransparencyRule) {
        return "Shared";
    }
    return "Hidden";
};

export const ALLOW_CONTENT_STATIC_OPTIONS = [
    {
        label: "Shared",
        value: "shared",
    },
    {
        label: "Hidden",
        value: "hidden",
    },
];

export const mapContentRuleApiToForm = (
    allowContent: boolean,
    contentTransparencyRule: ContentTransparencyRule | undefined | null
) => {
    if (allowContent && contentTransparencyRule) {
        // Custom
        return { label: contentTransparencyRule.name, value: contentTransparencyRule.id };
    }
    if (allowContent && !contentTransparencyRule) {
        // Shared
        return ALLOW_CONTENT_STATIC_OPTIONS[0];
    }
    // Hidden
    return ALLOW_CONTENT_STATIC_OPTIONS[1];
};

export const mapContentRuleFormToApi = (controlContent: LabeledValue) => {
    if (controlContent.value === "hidden") {
        return {
            allowContent: false,
            contentTransparencyRule: null,
        };
    }
    if (controlContent.value === "shared") {
        return {
            allowContent: true,
            contentTransparencyRule: null,
        };
    }

    return {
        allowContent: true,
        contentTransparencyRule: { name: controlContent.label, id: controlContent.value } as ContentTransparencyRule,
    };
};

export const AllowContentCell: FC<Props> = ({ index, isEditing, allowContent, contentTransparencyRule }) => {
    const { data: contentTransparencyRules, isLoading } = useContentTransparencyRules();
    const options = useMemo(() => {
        return [
            ...(contentTransparencyRules || []).map((v) => ({ value: v.id, label: v.name })),
            ...ALLOW_CONTENT_STATIC_OPTIONS,
        ];
    }, [contentTransparencyRules]);

    if (isEditing) {
        return (
            <Form.Item
                name={[index, "allowContent"]}
                initialValue={mapContentRuleApiToForm(allowContent, contentTransparencyRule)}
                style={{ marginBottom: 0 }}
            >
                <Select
                    labelInValue
                    options={options}
                    style={{ minWidth: "100px" }}
                    data-sdet="content-select"
                    loading={isLoading}
                />
            </Form.Item>
        );
    }

    return <span>{mapContentRuleApiToReadOnly(allowContent, contentTransparencyRule)}</span>;
};
