import { FC } from "react";
import { Alert, Card, Collapse, Form } from "antd";
import { css } from "@emotion/css";
import { Moment } from "moment-timezone";
import { SectionTitle } from "@app/core/components";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { cardBodyStyle } from "@app/features/seatAdSources/constants";
import { AdSourceFormMode } from "../../../SeatAdSourcesForm";
import { DELIVERY_SCHEDULE } from "./constants";
import { AddScheduleRow, ScheduleRow, ScheduleHeaderRow } from "./rows";
import { FormPacingDeliverySchedule } from "./types";
import { usePacingDeliverySchedule } from "./usePacingDeliverySchedule";

interface Props {
    name: string;
    pacingTypeId: number | null | undefined;
    startDate: Moment | null;
    endDate: Moment | null;
    totalImpressions: number | null;
    formMode: AdSourceFormMode | DealFormMode;
    vertical?: boolean;
    onChange?: (schedules: FormPacingDeliverySchedule[]) => void;
}

export const PacingDeliverySchedule: FC<Props> = ({
    name,
    pacingTypeId,
    startDate,
    endDate,
    totalImpressions,
    formMode,
    vertical = false,
    onChange: propOnChange,
}) => {
    const {
        impressionGoalIsRequired,
        dailyImpressionCapIsDisabled,
        overlappingDateRanges,
        rules,
        isDisabled,
        onScheduleFieldChange,
        onAddSchedule,
        onDeleteSchedule,
    } = usePacingDeliverySchedule(name, pacingTypeId, startDate, endDate, totalImpressions, formMode, propOnChange);

    return (
        <Collapse
            data-sdet="pacing-delivery-schedule"
            defaultActiveKey={["1"]}
            bordered
            items={[
                {
                    key: "1",
                    label: <SectionTitle title={DELIVERY_SCHEDULE} />,
                    children: (
                        <>
                            <Form.List name={name} rules={rules}>
                                {(_, __, { errors }) => (
                                    <>
                                        {errors.map((error, errorIndex) => (
                                            <Alert
                                                data-sdet={`schedule-error-${errorIndex}`}
                                                key={errorIndex}
                                                type="error"
                                                showIcon
                                                className={css`
                                                    border: 1px solid rgb(217, 217, 217);
                                                `}
                                                message={error?.toString() ?? "Please check Schedules and try again."}
                                            />
                                        ))}
                                    </>
                                )}
                            </Form.List>
                            <Card bordered={false} styles={{ body: cardBodyStyle }}>
                                <Form.List name={name} rules={rules}>
                                    {(fields) => (
                                        <>
                                            {fields.map((field, rowIndex) => {
                                                const isFirstSchedule = rowIndex === 0;
                                                const isLastSchedule = rowIndex === fields.length - 1;
                                                return (
                                                    <div key={field.key}>
                                                        {isFirstSchedule && (
                                                            <ScheduleHeaderRow
                                                                isImpressionGoalRequired={impressionGoalIsRequired}
                                                                vertical={vertical}
                                                            />
                                                        )}
                                                        {isLastSchedule && (
                                                            <AddScheduleRow onAddSchedule={onAddSchedule} />
                                                        )}
                                                        <ScheduleRow
                                                            rowIndex={rowIndex}
                                                            isRequired={isFirstSchedule || isLastSchedule}
                                                            isDisabled={isDisabled(rowIndex)}
                                                            dateRangeAllowEmpty={
                                                                isLastSchedule ? [false, true] : [false, false]
                                                            }
                                                            dateRangeDisabled={[isFirstSchedule, isLastSchedule]}
                                                            dateRangeIsOverlapping={overlappingDateRanges.has(rowIndex)}
                                                            impressionGoalIsRequired={impressionGoalIsRequired}
                                                            dailyImpressionCapIsDisabled={dailyImpressionCapIsDisabled}
                                                            vertical={vertical}
                                                            onClickDeleteSchedule={onDeleteSchedule}
                                                            onScheduleFieldChange={onScheduleFieldChange}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </>
                    ),
                },
            ]}
        />
    );
};
