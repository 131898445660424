import { useUserAccess } from "@app/core/auth";
import { useAppSelector } from "@app/core/store";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useHistory, useParams } from "react-router-dom";
import { ItemType } from "antd/lib/menu/interface";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
    selectIsPageInventoryDetailsDrawer,
} from "../../reducer";
import { useInventoryDetailsDrawer } from "../../useInventoryDetailsDrawer";
import { UnitUrlIds } from "../../useInventoryDetailsDrawerUrlId";
import { downloadTags } from "@app/features/inventory/helpers";
import {
    AdUnit,
    BrandSupply,
    BulkOperationSourceEntity,
    useGetAdUnitQuery,
    useGetBrandByIdQuery,
    useGetBrandChannelQuery,
    useGetChannelQuery,
    useGetPublisherByIdQuery,
    useGetSupplyQuery,
} from "@app/core/services";
import { BulkOperationsUrlState } from "@app/core/components";
import { CHANNEL_AD_UNIT_QUERY_PARAMETER } from "@app/features/inventory/DetailsDrawer/DetailsDrawerContent/UnitContent/LadleContent/useLadleContent";

interface UrlParams extends UnitUrlIds {
    seatId: string;
}

interface DetailsDrawerUnitLayoutToolbar {
    handleEdit: () => void;
    handleCopy: () => void;
    hasSeatWriteAccess: boolean;
    supplyData: BrandSupply | undefined;
    handleCreativeReview: () => void;
    handleDiagnostics: () => void;
    handlePermissions: () => void;
    handleAddChannel: () => void;
    handleAddBrand: () => void;
    handleAddSupply: () => void;
    handleAddAdUnit: () => void;
    handleTagParams: () => void;
    moreMenuItems: ItemType[];
    isCopyAvailable: boolean;
    isBrand: boolean;
    isChannel: boolean;
    isChannelAdUnit: boolean;
    isPublisher: boolean;
    isSupply: boolean;
}

export const getIsChannelAdUnit = (adUnit: AdUnit | undefined) => {
    return adUnit?.entityType === "ChannelAdUnit" || adUnit?.supply.brand.entityType === "Channel";
};

export const useDetailsDrawerUnitLayoutToolbar = (): DetailsDrawerUnitLayoutToolbar => {
    const history = useHistory();
    const { hasSeatWriteAccess } = useUserAccess();
    const { seatId, publisherId, brandId, adUnitId, supplyId } = useParams<UrlParams>();
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    const isDetailsUnitPage = useAppSelector(selectIsPageInventoryDetailsDrawer);
    const { closeDetailsDrawer } = useInventoryDetailsDrawer();
    const isPublisher = detailsDrawerType === InventoryDetailsDrawerType.PUBLISHER;
    const isBrand = detailsDrawerType === InventoryDetailsDrawerType.BRAND;
    const isSupply = detailsDrawerType === InventoryDetailsDrawerType.SUPPLY;
    const isAdUnit = detailsDrawerType === InventoryDetailsDrawerType.AD_UNIT;
    const isChannel = detailsDrawerType === InventoryDetailsDrawerType.CHANNEL;
    const isBrandChannel = detailsDrawerType === InventoryDetailsDrawerType.BRAND_CHANNEL;

    const { data: publisherChannelData } = useGetChannelQuery(Number(publisherId), {
        skip: !isChannel || isNaN(Number(publisherId)),
    });
    const { data: brandChannelData } = useGetBrandChannelQuery(Number(brandId), {
        skip: !isBrandChannel || isNaN(Number(brandId)),
    });
    const { data: publisherData } = useGetPublisherByIdQuery(Number(publisherId), {
        skip: !isPublisher || isNaN(Number(publisherId)),
    });
    const { data: brandData } = useGetBrandByIdQuery(Number(brandId), {
        skip: !isBrand || isNaN(Number(brandId)),
    });
    const { data: supplyData } = useGetSupplyQuery(Number(supplyId), {
        skip: !isSupply || isNaN(Number(supplyId)),
    });
    const { data: adUnitData } = useGetAdUnitQuery(Number(adUnitId), {
        skip: !isAdUnit || isNaN(Number(adUnitId)),
    });

    const isChannelAdUnit = getIsChannelAdUnit(adUnitData);
    const isCopyAvailable = !isChannel && !isBrandChannel && !isChannelAdUnit;

    const handleAddFloor = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId));
    const handleCopyPublisher = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_COPY(Number(seatId), Number(publisherId)));
    const handleCopyWithChildren = (id: number): void => {
        const routeState: BulkOperationsUrlState = {
            type: "copy",
            entityType: detailsDrawerType.toLowerCase() as BulkOperationSourceEntity,
            entityId: String(id),
            withChildren: true,
        };

        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BULK_OPERATIONS_CREATE(Number(seatId)),
            state: routeState,
        });
    };
    const handleCopyBrand = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY(
                Number(seatId),
                Number(publisherId),
                Number(brandId)
            )
        );
    const handleCopySupply = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY(
                Number(seatId),
                Number(publisherId),
                Number(brandId),
                Number(supplyId)
            )
        );
    const handleLadle = () => {
        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_LADLE(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            ) as string,
            search: `?${CHANNEL_AD_UNIT_QUERY_PARAMETER}=${
                adUnitId || publisherChannelData?.adUnit?.id || brandChannelData?.adUnit?.id
            }`,
        });
    };

    const handleGetTags = () => {
        downloadTags(seatId);
    };

    const handleCopyAdUnit = () => {
        if (seatId && publisherId && brandId && supplyId && adUnitId) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY(
                    seatId,
                    publisherId,
                    brandId,
                    supplyId,
                    adUnitId
                )
            );
        }
    };
    const handleChangeHistory = (): void => {
        if (isChannel && publisherChannelData) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                    isDetailsUnitPage,
                    seatId,
                    detailsDrawerType,
                    Number(publisherId)
                ) as string
            );
            return;
        }

        if (isPublisher && publisherData) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                    isDetailsUnitPage,
                    seatId,
                    detailsDrawerType,
                    Number(publisherId)
                ) as string
            );
            return;
        }

        if (isBrand && brandData) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                    isDetailsUnitPage,
                    seatId,
                    detailsDrawerType,
                    Number(publisherId),
                    Number(brandId)
                ) as string
            );
            return;
        }

        if (isBrandChannel && brandChannelData) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                    isDetailsUnitPage,
                    seatId,
                    detailsDrawerType,
                    Number(publisherId),
                    Number(brandId)
                ) as string
            );
            return;
        }

        if (isSupply && supplyData) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                    isDetailsUnitPage,
                    seatId,
                    detailsDrawerType,
                    Number(publisherId),
                    Number(brandId),
                    Number(supplyId)
                ) as string
            );
            return;
        }

        if (isAdUnit && adUnitData) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                    isDetailsUnitPage,
                    seatId,
                    detailsDrawerType,
                    Number(publisherId),
                    Number(brandId),
                    Number(supplyId),
                    Number(adUnitId)
                ) as string
            );
            return;
        }
    };
    const adsTxtPubId = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_ADS_TXT(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId),
                Number(brandId)
            ) as string
        );
    };
    const floorAnalysis = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            ) as string
        );
    };

    const moreMenuItems: ItemType[] = [
        {
            label: "Floor Analysis",
            key: "floorAnalysis",
            onClick: floorAnalysis,
        },
        hasSeatWriteAccess && {
            label: "Change History",
            key: "changeHistory",
            onClick: handleChangeHistory,
        },
        (isPublisher || isBrand || isChannel) &&
            hasSeatWriteAccess && {
                label: "Ads.txt Pub ID",
                key: "adsTxtPubId",
                onClick: adsTxtPubId,
            },
        isPublisher &&
            hasSeatWriteAccess && {
                label: "Copy Publisher",
                key: "copyPublisher",
                onClick: handleCopyPublisher,
            },
        isPublisher &&
            hasSeatWriteAccess && {
                label: "Copy Publisher + Children",
                key: "copyPublisherChildren",
                onClick: () => handleCopyWithChildren(Number(publisherId)),
            },
        isBrand &&
            hasSeatWriteAccess && {
                label: "Copy Brand",
                key: "copyBrand",
                onClick: handleCopyBrand,
            },
        isBrand &&
            hasSeatWriteAccess && {
                label: "Copy Brand + Children",
                key: "copyBrandChildren",
                onClick: () => handleCopyWithChildren(Number(brandId)),
            },
        isSupply &&
            hasSeatWriteAccess && {
                label: "Copy Supply",
                key: "copySupply",
                onClick: handleCopySupply,
            },
        isSupply &&
            hasSeatWriteAccess && {
                label: "Copy Supply + Children",
                key: "copySupplyChildren",
                onClick: () => handleCopyWithChildren(Number(supplyId)),
            },
        isAdUnit &&
            hasSeatWriteAccess && {
                label: "Copy Ad Unit",
                key: "copyAdUnit",
                onClick: handleCopyAdUnit,
            },
        (isAdUnit || isChannel) && {
            label: "Get Tag",
            key: "getTag",
            onClick: () => {
                handleGetTags();
            },
        },
        (isAdUnit || isChannel || isBrandChannel) && {
            label: "Ladle",
            key: "ladle",
            onClick: handleLadle,
        },
        { label: "Add a Floor", key: "addFloor", onClick: handleAddFloor },
    ].filter(Boolean) as ItemType[];

    const handleEdit = (): void => {
        closeDetailsDrawer();
        if (isPublisher)
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_EDIT(Number(seatId), Number(publisherId))
            );

        if (isChannel)
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_EDIT(Number(seatId), Number(publisherId))
            );

        if (isBrand)
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_EDIT(
                    Number(seatId),
                    Number(publisherId),
                    Number(brandId)
                )
            );
        if (isBrandChannel) {
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_EDIT(
                    Number(seatId),
                    Number(publisherId),
                    Number(brandId)
                )
            );
        }
        if (isSupply) {
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_EDIT(
                    Number(seatId),
                    Number(publisherId),
                    Number(brandId),
                    Number(supplyId)
                )
            );
        }
        if (isAdUnit && seatId && publisherId && brandId && supplyId && adUnitId) {
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_EDIT(
                    seatId,
                    publisherId,
                    brandId,
                    supplyId,
                    adUnitId
                )
            );
        }
    };
    const handleCopy = (): void => {
        closeDetailsDrawer();
        if (isPublisher)
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_COPY(Number(seatId), Number(publisherId))
            );

        if (isBrand)
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY(
                    Number(seatId),
                    Number(publisherId),
                    Number(brandId)
                )
            );
        if (isSupply) {
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY(
                    Number(seatId),
                    Number(publisherId),
                    Number(brandId),
                    Number(supplyId)
                )
            );
        }
        if (isAdUnit && seatId && publisherId && brandId && supplyId && adUnitId) {
            return history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY(
                    seatId,
                    publisherId,
                    brandId,
                    supplyId,
                    adUnitId
                )
            );
        }
    };

    const handleCreativeReview = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            )
        );
    };

    const handleDiagnostics = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            ) as string
        );
    };

    const handlePermissions = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PERMISSIONS(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId)
            ) as string
        );
    };

    const handleAddChannel = () => {
        //INFO: channel for brand only
        if (publisherId) {
            history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_CREATE(seatId, publisherId));
        }
    };

    const handleAddBrand = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CREATE(seatId, Number(publisherId)));
    };

    const handleAddSupply = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_CREATE(
                seatId,
                Number(publisherId),
                Number(brandId)
            )
        );
    };

    const handleAddAdUnit = () => {
        if (seatId && publisherId && brandId && supplyId) {
            history.push(
                ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_CREATE(
                    seatId,
                    publisherId,
                    brandId,
                    supplyId
                )
            );
        }
    };

    const handleTagParams = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(
                isDetailsUnitPage,
                seatId,
                detailsDrawerType,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnitId)
            )
        );
    };

    return {
        handleEdit,
        handleCopy,
        handleDiagnostics,
        handlePermissions,
        handleCreativeReview,
        handleAddChannel,
        handleAddBrand,
        handleAddSupply,
        handleAddAdUnit,
        handleTagParams,
        hasSeatWriteAccess,
        moreMenuItems,
        isPublisher,
        isChannel,
        supplyData,
        isBrand,
        isSupply,
        isCopyAvailable,
        isChannelAdUnit,
    };
};
