import { Button, Tooltip } from "antd";
import { CONTROLS_ROUTES } from "../constants";
import { Link } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { TransparencyEditModeControls } from "./TransparencyDefault/TransparencyEditModeControls";

export const ALLOWED_CONTENT_TRANSPARENCY_RULES = 3;

export const BRAND_SAFETY_LABELS = {
    [CONTROLS_ROUTES.ADVERTISER_DOMAINS]: "Advertiser Domain Lists",
    [CONTROLS_ROUTES.SEQUESTERED_DOMAINS]: "Sequestered Advertiser Domains",
    [CONTROLS_ROUTES.TRANSPARENCY_DEFAULT]: "Transparency Default",
    [CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES]: "Content Transparency Rules",
    [CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY]: "Advanced Brand Safety",
    [CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS]: "Competitive Separation Groups",
    [CONTROLS_ROUTES.BUYER_SEAT_LIST]: "Buyer Seat List",
    [CONTROLS_ROUTES.MAPPING_EXCEPTIONS]: "Mapping Exceptions",
} as const;

interface TransparencyEditModeControlsProps {
    handleCancel: () => void;
    handleCancelClick: () => void;
    handleEditClick: () => void;
    handleOk: () => void;
    isEdit: boolean;
    isSaving: boolean;
    open: boolean;
}

export const BRAND_SAFETY_LIST = {
    [CONTROLS_ROUTES.ADVERTISER_DOMAINS]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.ADVERTISER_DOMAINS],
        extra: (seatId: string) => (
            <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_CREATE(seatId)}>
                <Button type="primary">New List</Button>
            </Link>
        ),
        hasSearch: true,
    },
    [CONTROLS_ROUTES.SEQUESTERED_DOMAINS]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.SEQUESTERED_DOMAINS],
        hasSearch: true,
    },
    [CONTROLS_ROUTES.TRANSPARENCY_DEFAULT]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.TRANSPARENCY_DEFAULT],
        extra: (_, __, ___, props: TransparencyEditModeControlsProps) => <TransparencyEditModeControls {...props} />,

        hasSearch: false,
    },
    [CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES],
        extra: (seatId: string, canEditSeat: boolean, overreached: boolean) => (
            <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_CREATE(seatId)}>
                {overreached ? (
                    <Tooltip title="Remove at least one rule to create a new rule">
                        <Button type="primary" disabled={!canEditSeat || overreached}>
                            Add Rule
                        </Button>
                    </Tooltip>
                ) : (
                    <Button type="primary">Add Rule</Button>
                )}
            </Link>
        ),
        hasSearch: false,
    },
    [CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY],
        extra: (seatId: string) => (
            <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_CREATE_PAGE(seatId)}>
                <Button type="primary">New Advanced Brand Safety</Button>
            </Link>
        ),
        hasSearch: true,
    },
    [CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS],
        extra: (seatId: string) => {
            return (
                <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_CREATE_PAGE(seatId)}>
                    <Button type="primary">Add</Button>
                </Link>
            );
        },
        hasSearch: true,
    },
    [CONTROLS_ROUTES.BUYER_SEAT_LIST]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.BUYER_SEAT_LIST],
        extra: (seatId: string, canEditSeat: boolean) => {
            if (!canEditSeat) {
                return null;
            }
            return (
                <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_CREATE_PAGE(seatId)}>
                    <Button type="primary">New List</Button>
                </Link>
            );
        },
        hasSearch: false,
    },
    [CONTROLS_ROUTES.MAPPING_EXCEPTIONS]: {
        label: BRAND_SAFETY_LABELS[CONTROLS_ROUTES.MAPPING_EXCEPTIONS],
        extra: (seatId: string, canEditSeat: boolean) => {
            if (!canEditSeat) {
                return null;
            }
            return (
                <Link to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_CREATE_PAGE(seatId)}>
                    <Button type="primary">Add Mapping Exceptions</Button>
                </Link>
            );
        },
        hasSearch: true,
    },
};

export const BRAND_SAFETY_HEADER_SDET = {
    [CONTROLS_ROUTES.ADVERTISER_DOMAINS]: "advertiser-domains-header",
    [CONTROLS_ROUTES.SEQUESTERED_DOMAINS]: "sequestered-domains-header",
    [CONTROLS_ROUTES.TRANSPARENCY_DEFAULT]: "transparency-default-header",
    [CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES]: "content-transparency-header",
    [CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY]: "advanced-brand-safety-header",
    [CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS]: "competitive-separation-groups-header",
    [CONTROLS_ROUTES.BUYER_SEAT_LIST]: "`buyer-seat-header",
};

export const BRAND_SAFETY_SEARCH_FILTER_SDET = {
    [CONTROLS_ROUTES.ADVERTISER_DOMAINS]: "advertiser-domains-search-filter",
    [CONTROLS_ROUTES.SEQUESTERED_DOMAINS]: "sequestered-domains-search-filter",
    [CONTROLS_ROUTES.TRANSPARENCY_DEFAULT]: "transparency-default-search-filter",
    [CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES]: "content-transparency-search-filter",
    [CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY]: "advanced-brand-safety-search-filter",
    [CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS]: "competitive-separation-groups-search-filter",
    [CONTROLS_ROUTES.BUYER_SEAT_LIST]: "buyer-seat-search-filter",
};
