import { FC } from "react";
import { Button, Col, Form, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { brandRuthlessEmpress } from "@rubicon/antd-components";
import { SCHEDULE } from "../constants";

interface Props {
    onAddSchedule: () => void;
}

export const AddScheduleRow: FC<Props> = ({ onAddSchedule }) => (
    <Row align="middle">
        <Col>
            <Form.Item>
                <Button
                    data-sdet="add-schedule-button"
                    type="dashed"
                    className={css`
                        color: ${brandRuthlessEmpress};
                        border-color: ${brandRuthlessEmpress};
                    `}
                    icon={<PlusOutlined />}
                    onClick={onAddSchedule}
                >
                    {`Add ${SCHEDULE}`}
                </Button>
            </Form.Item>
        </Col>
    </Row>
);
