const POD_DEDUPING_LEVEL_HIGH = { value: 1, label: "High" };
const POD_DEDUPING_LEVEL_MEDIUM = { value: 2, label: "Medium" };
const POD_DEDUPING_LEVEL_LOW = { value: 3, label: "Low" };
const POD_DEDUPING_LEVEL_OPTIONS = [POD_DEDUPING_LEVEL_HIGH, POD_DEDUPING_LEVEL_MEDIUM, POD_DEDUPING_LEVEL_LOW];

interface UsePodDedupingLevel {
    options: typeof POD_DEDUPING_LEVEL_OPTIONS;
}

export const usePodDedupeMode = (): UsePodDedupingLevel => {
    return {
        options: POD_DEDUPING_LEVEL_OPTIONS,
    };
};
