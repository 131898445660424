import { FC } from "react";
import { Col, Row, Typography } from "antd";
import { format } from "@rubicon/utils";
import { useUserAccess } from "@app/core/auth";
import { AdSource } from "@app/core/services";
import { AD_SOURCE_FIELDS, TARGET_DATE_AND_DELIVERY_PERCENTAGE_LABEL } from "../../constants";
import {
    formatTargetDateAndDeliveryPercentage,
    isDailyImpressionCapEligible,
    getIsDailyImpressionCapEnabled,
    isDailyImpressionCapEnabledEligible,
    isDeliveryPercentageEligible,
    isFallbackOpportunityEstimateEligible,
    isPacingPeriodEligible,
    isPacingRedistributionEligible,
    isPacingTypeEligible,
    isTargetDateEligible,
    isPacingDeliveryScheduleEnabled,
    isPacingDeliveryScheduleEligible,
} from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { ReadOnlyPacingDeliverySchedule } from "../../SeatAdSourcesForm/AdSourcesSections";

const { Text, Paragraph } = Typography;
const {
    DAILY_IMPRESSION_CAP,
    DAILY_IMPRESSION_CAP_ENABLED,
    FALLBACK_OPPORTUNITY,
    PACING_PERIOD,
    PACING_REDISTRIBUTION,
    PACING_TYPE,
    PACING_DELIVERY_SCHEDULE_ENABLED,
} = AD_SOURCE_FIELDS;

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesPacingDetails: FC<Props> = ({ adSource }) => {
    const adSourceTypeId = adSource.type.id;
    const pacingTypeId = adSource.pacingType?.id;
    const isDailyImpressionCapEnabled =
        isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId) &&
        getIsDailyImpressionCapEnabled(adSource.dailyCapVolume);
    const {
        showFallbackOpportunity,
        seatAccessFlags: { hasPacingDeliverySchedulesAccess },
    } = useUserAccess();

    const showPacingType = isPacingTypeEligible(adSourceTypeId);
    const showPacingDeliveryScheduleEnabled = isPacingDeliveryScheduleEligible(
        adSourceTypeId,
        pacingTypeId,
        hasPacingDeliverySchedulesAccess
    );
    const showPacingDeliverySchedule = isPacingDeliveryScheduleEnabled(
        adSourceTypeId,
        pacingTypeId,
        adSource.pacingDeliveryScheduleEnabled,
        hasPacingDeliverySchedulesAccess
    );
    const showPacingPeriod = isPacingPeriodEligible(adSourceTypeId, pacingTypeId);
    const showPacingRedistribution = isPacingRedistributionEligible(adSourceTypeId, pacingTypeId);
    const showDailyImpressionCapEnabled = isDailyImpressionCapEnabledEligible(adSourceTypeId, pacingTypeId);
    const showDailyImpressionCap = isDailyImpressionCapEligible(
        adSourceTypeId,
        pacingTypeId,
        isDailyImpressionCapEnabled
    );
    const showFallbackOpportunityEstimate = isFallbackOpportunityEstimateEligible(
        adSourceTypeId,
        pacingTypeId,
        showFallbackOpportunity
    );
    const showTargetDateAndDeliveryPercentage =
        isTargetDateEligible(adSourceTypeId, pacingTypeId) &&
        isDeliveryPercentageEligible(adSourceTypeId, pacingTypeId);

    return (
        <div data-sdet="seat-ad-sources-pacing-details">
            {(showPacingType || showPacingPeriod) && (
                <Row>
                    {showPacingType && (
                        <Col sm={8}>
                            <Text strong>{PACING_TYPE.label}</Text>
                            <Paragraph>{format.asSelf(adSource.pacingType?.name)}</Paragraph>
                        </Col>
                    )}
                    {showPacingDeliveryScheduleEnabled && (
                        <Col sm={8}>
                            <Text strong>{PACING_DELIVERY_SCHEDULE_ENABLED.label}</Text>
                            <Paragraph>{format.asYesOrNo(adSource.pacingDeliveryScheduleEnabled)}</Paragraph>
                        </Col>
                    )}
                    {showPacingPeriod && (
                        <Col sm={8}>
                            <Text strong>{PACING_PERIOD.label}</Text>
                            <Paragraph>{format.asSelf(adSource.pacingPeriod?.name)}</Paragraph>
                        </Col>
                    )}
                </Row>
            )}
            {showPacingDeliverySchedule && (
                <Row>
                    <Col sm={24}>
                        <ReadOnlyPacingDeliverySchedule
                            pacingDeliverySchedules={adSource.pacingDeliverySchedules}
                            timeZoneCode={adSource.timeZone?.code}
                        />
                    </Col>
                </Row>
            )}
            {(showPacingRedistribution ||
                showDailyImpressionCapEnabled ||
                showDailyImpressionCap ||
                showFallbackOpportunityEstimate) && (
                <Row>
                    {showPacingRedistribution && (
                        <Col sm={8}>
                            <Text strong>{PACING_REDISTRIBUTION.label}</Text>
                            <Paragraph>{format.asYesOrNo(adSource.redistributePacing)}</Paragraph>
                        </Col>
                    )}
                    {showDailyImpressionCapEnabled && (
                        <Col sm={8}>
                            <Text strong>{DAILY_IMPRESSION_CAP_ENABLED.label}</Text>
                            <Paragraph>{format.asYesOrNo(isDailyImpressionCapEnabled)}</Paragraph>
                        </Col>
                    )}
                    {showDailyImpressionCap && (
                        <Col sm={8}>
                            <Text strong>{DAILY_IMPRESSION_CAP.label}</Text>
                            <Paragraph>{format.asSelf(adSource.dailyCapVolume)}</Paragraph>
                        </Col>
                    )}
                    {showFallbackOpportunityEstimate && (
                        <Col sm={8}>
                            <Text strong>{FALLBACK_OPPORTUNITY.label}</Text>
                            <Paragraph>{format.asSelf(adSource.dailyRequestOpportunityFallback)}</Paragraph>
                        </Col>
                    )}
                </Row>
            )}
            {showTargetDateAndDeliveryPercentage && (
                <Row>
                    <Col sm={8}>
                        <Text strong>{TARGET_DATE_AND_DELIVERY_PERCENTAGE_LABEL}</Text>
                        <Paragraph>
                            {formatTargetDateAndDeliveryPercentage(
                                adSource.customCurveImps,
                                adSource.bookingVolume,
                                adSource.customCurveStartDate
                            )}
                        </Paragraph>
                    </Col>
                </Row>
            )}
        </div>
    );
};
