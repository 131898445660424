import { FC, ReactEventHandler, ReactNode, useState } from "react";
import { css } from "@emotion/css";
import { useParams } from "react-router-dom";
import { NavBar } from "@app/core/components";

interface Props {
    src: (seatId: string) => string;
    title: ReactNode;
    navBarExtra?: ReactNode;
    name?: string;
    onLoad?: ReactEventHandler<HTMLIFrameElement>;
}

export const SeatIFrame: FC<Props> = ({ src, title, navBarExtra, name, onLoad }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            <NavBar label={title} extra={navBarExtra} />
            <iframe
                className={css`
                    border: 0;
                    flex: 1;
                `}
                src={src(seatId)}
                title={`${title} - Magnite`}
                allow={`clipboard-write ${src(seatId)}`}
                name={name}
                onLoad={(event) => {
                    // only call onLoad once
                    if (!loaded && onLoad) {
                        setLoaded(true);
                        onLoad(event);
                    }
                }}
            />
        </>
    );
};
