import { Select, SelectProps } from "antd";
import { LoadOnScrollSelect } from "@app/core/components/LoadOnScrollSelect";
import { ReactNode } from "react";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

type ValueType<T> = T | T[];

type InternalValueType =
    | {
          label: ReactNode;
          value: unknown;
      }
    | {
          label: ReactNode;
          value: unknown;
      }[];

interface Props<T = unknown> extends Omit<SelectProps, "options" | "value"> {
    fieldAsLabel: string[] | ((value: T) => ReactNode);
    fieldAsValue: string[] | ((value: T) => string | number);
    hasMore?: boolean;
    loadMore?: () => void;
    onChange?: (value: ValueType<T>) => void;
    options?: T[] | (T & { disabled: boolean })[] | { label: string; options: T[] }[];
    tagRender?: (props: CustomTagProps) => JSX.Element;
    value?: T | T[] | null;
}

function getByPath<T>(obj: T, path: string[] | ((value: T) => string | number)) {
    if (Array.isArray(path)) {
        return path.reduce((o, key) => (o && typeof o[key] !== "undefined" ? o[key] : undefined), obj);
    }
    return path(obj);
}

export function SelectWithValue<T>(props: Props<T>) {
    const { fieldAsLabel, fieldAsValue, ...selectProps } = props;
    const { hasMore, loadMore, mode, onChange, options = [], tagRender, value } = selectProps;

    const getLabel = (value: T) =>
        Array.isArray(fieldAsLabel) ? (getByPath(value, fieldAsLabel) as unknown as string) : fieldAsLabel(value);

    const getValues = (value: ValueType<T>): InternalValueType => {
        if (mode && !value) {
            return [];
        }

        if (!value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return null;
        }
        if (Array.isArray(value)) {
            return value.map((item) => ({
                value: getByPath(item, fieldAsValue),
                label: getLabel(item),
            }));
        }
        return {
            value: getByPath(value, fieldAsValue),
            label: getLabel(value),
        };
    };

    const internalOptions = options.map((item) => {
        if (item?.options) {
            return {
                label: item.label,
                search: item.search || "",
                options: item.options.map((value) => ({
                    label: getLabel(value),
                    value: getByPath(value, fieldAsValue),
                    disabled: value.disabled,
                })),
            };
        }

        return {
            label: getLabel(item),
            value: getByPath(item, fieldAsValue),
            disabled: item.disabled,
            search: item.search || "",
        };
    });
    const flatOptions = options
        .map((item) => {
            if (item?.options) {
                return item.options;
            }
            return item;
        })
        .flat(1);

    const handleChange = (selected: InternalValueType) => {
        if (Array.isArray(selected)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                selected.map(
                    (item) =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        options.find((option) => getByPath(option, fieldAsValue) === item.value) ||
                        (Array.isArray(value)
                            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              value?.find((val) => getByPath(val, fieldAsValue) === item.value)
                            : item)
                )
            );
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange(flatOptions.find((option) => getByPath(option, fieldAsValue) === selected.value));
        }
    };

    const commonProps = {
        ...selectProps,
        labelInValue: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: getValues(value),
        onChange: handleChange,
        options: internalOptions,
        tagRender: tagRender ? (props) => tagRender?.({ ...props, value }) : tagRender,
    };

    if (loadMore) {
        return <LoadOnScrollSelect {...commonProps} hasMore={Boolean(hasMore)} loadMore={loadMore} />;
    }

    return <Select {...commonProps} />;
}
