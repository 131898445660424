import { useMemo, useRef } from "react";
import { selectUserTimezone } from "@app/core/authClient/reducer";
import { AdSourceListItem } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import {
    useHistoryAdSourceAdStat,
    useLiveAdSourceAdStat,
    useLiveAdSourceAdStatsById,
} from "@app/features/adStats/adSource";
import { AdSourceStat, LiveAdSourceListItem, LoadableAdSourceStat, LoadableAdSourceStats } from "./types";
import { useMapAdSourceAdStats } from "./useMapAdSourceAdStats";
import {
    adSourceStatContainsData,
    diffAdSourceStats,
    getDiffInSeconds,
    scaleAdSourceStat,
    scaleAdSourceStats,
} from "./utils";
import { MomentRange } from "@app/core/utils/types";
import { filterAdStatsByNtime } from "@app/features/adStats/adSource/filters";

const HISTORY_AD_STAT_SCALE = 300;

export const useConnectLiveAdSourceAdStat = (adSourceId: number, throttle = false): LoadableAdSourceStat => {
    const liveAdStat = useLiveAdSourceAdStat(adSourceId, throttle);
    const { mapSingleAdSourceAdStat } = useMapAdSourceAdStats();
    const liveAdSourceStat = liveAdStat ? mapSingleAdSourceAdStat(liveAdStat) : undefined;

    const loadingState = !liveAdSourceStat
        ? "loading"
        : adSourceStatContainsData(liveAdSourceStat)
        ? "loaded"
        : "loadedNoData";

    return {
        loadingState,
        data: loadingState === "loaded" ? liveAdSourceStat : undefined,
    };
};

export const useConnectLiveAdSourceAdStatPerSecond = (adSourceId: number): LoadableAdSourceStat => {
    const newLoadableStat = useConnectLiveAdSourceAdStat(adSourceId);
    const oldStatRef = useRef<AdSourceStat>();
    const statPerSecondRef = useRef<AdSourceStat | undefined>();

    const timeZone = useAppSelector(selectUserTimezone);
    const timeZoneRef = useRef<string>();

    if (newLoadableStat.loadingState === "loadedNoData") {
        return newLoadableStat;
    }

    const isTimeZoneChangeRequest = timeZoneRef.current && timeZone !== timeZoneRef.current;
    if (isTimeZoneChangeRequest) {
        oldStatRef.current = undefined;
        statPerSecondRef.current = undefined;
        timeZoneRef.current = undefined;
    }

    const newStat = newLoadableStat.data;
    const oldStat = oldStatRef.current;
    const haveRecievedTwoConsecutiveStats = newStat && oldStat;
    if (haveRecievedTwoConsecutiveStats) {
        const statsHaveSameTimeZone = newStat.oTime === oldStat.oTime;
        if (statsHaveSameTimeZone) {
            const isNewStatBucket = newStat.nTime > oldStat.nTime;
            if (isNewStatBucket) {
                const statDiff = diffAdSourceStats(newStat, oldStat);
                const elapsedSeconds = getDiffInSeconds(statDiff.nTime);
                statPerSecondRef.current = scaleAdSourceStat(statDiff, elapsedSeconds, newStat.nTime, newStat.oTime);
            }
        }
    }

    oldStatRef.current = newStat;
    timeZoneRef.current = timeZone;

    if (!statPerSecondRef.current || isTimeZoneChangeRequest) {
        return {
            loadingState: "loading",
            data: undefined,
        };
    }

    return {
        loadingState: "loaded",
        data: statPerSecondRef.current,
    };
};

export const useConnectLiveAdSourcesAdStats = (
    adSources: AdSourceListItem[],
    throttle = false
): LiveAdSourceListItem[] => {
    const adSourceIds = adSources?.map((adSource) => adSource.id) || [];
    const liveAdStatsById = useLiveAdSourceAdStatsById(adSourceIds, throttle);
    const { mapAdSourceAdStatsById } = useMapAdSourceAdStats();
    const liveAdSourceStatsById = mapAdSourceAdStatsById(liveAdStatsById);

    return adSources?.map((adSource) => ({
        adSource,
        liveData: liveAdSourceStatsById[adSource.id],
    }));
};

export const useConnectHistoryAdSourceAdStats = (
    adSourceId: number,
    momentRange: MomentRange
): LoadableAdSourceStats => {
    const historyStats = useHistoryAdSourceAdStat(adSourceId);
    const loadingState = !historyStats ? "loading" : historyStats.length > 0 ? "loaded" : "loadedNoData";
    const inRangeStats = historyStats ? filterAdStatsByNtime(historyStats, momentRange) : undefined;
    const { mapMultipleAdSourceAdStats } = useMapAdSourceAdStats();
    const historyAdSourceStats = mapMultipleAdSourceAdStats(inRangeStats || []);

    return {
        loadingState,
        data: Boolean(historyAdSourceStats?.length) ? historyAdSourceStats : undefined,
    };
};

export const useConnectHistoryAdSourceAdStatsPerSecond = (
    adSourceId: number,
    momentRange: MomentRange
): LoadableAdSourceStats => {
    const { loadingState, data: historyStats } = useConnectHistoryAdSourceAdStats(adSourceId, momentRange);
    const memoizedHistoryStats = useMemo(() => historyStats, [historyStats]);

    return {
        loadingState,
        data:
            historyStats && historyStats.length > 0
                ? scaleAdSourceStats(memoizedHistoryStats, HISTORY_AD_STAT_SCALE)
                : undefined,
    };
};
