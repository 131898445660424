import { ResizableDrawer } from "@rubicon/antd-components";
import { drawerRightClosePosition } from "@app/features/controls/constants";
import { FC, useState } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components/SelectWithValue";
import {
    ExtendedId,
    SeatAvailableNetworks,
    useGetAvailableNetworksQuery,
    useGetExtendedIdsQuery,
} from "@app/core/services";
import { useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { LabeledValue } from "antd/es/select";

interface Props {
    index: number;
    isOpen: boolean;
    onClose: () => void;
}

const formatKeyValue = (a, b) => `${a}:${b}`;
const getValue = (extendedId, network) => formatKeyValue(extendedId.id, network.id);
const getLabel = (extendedId, network) => formatKeyValue(extendedId.name, network.name);

const hasAlreadySelectedValue = (
    values: LabeledValue[],
    extendedId: { id: number } | null,
    network: { id: number } | null
) => {
    return Boolean(extendedId && network && values.some((v) => v.value === getValue(extendedId, network)));
};

export const AllowedExtendedIdTransparenciesDrawer: FC<Props> = ({ index, isOpen, onClose }) => {
    const form = Form.useFormInstance();
    const { seatId } = useParams<{ seatId: string }>();
    const [extendedId, setExtendedId] = useState<ExtendedId | null>(null);
    const [network, setNetwork] = useState<SeatAvailableNetworks | null>(null);

    const { data: extendedIdOptions, isFetching: isExtendedIdsLoading } = useGetExtendedIdsQuery(Number(seatId));
    const { data: networks, isFetching: isNetworkLoading } = useGetAvailableNetworksQuery(
        { seatId: Number(seatId), extendedId: extendedId?.code ?? null },
        { skip: !extendedId }
    );
    const formValue = Form.useWatch(["transparency", index, "allowedExtendedIdTransparencies"]);

    return (
        <ResizableDrawer
            title="Extended User ID"
            open={isOpen}
            onClose={onClose}
            placement="right"
            width="45%"
            data-sdet="transparency-extended-user-form"
            className={drawerRightClosePosition}
        >
            <Row>
                <Col xs={24}>
                    <Form.Item layout="vertical" label="Extended User ID" wrapperCol={{ xs: 24 }}>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col flex="8">
                                <SelectWithValue<ExtendedId>
                                    style={{ width: "100%" }}
                                    options={extendedIdOptions}
                                    value={extendedId}
                                    onChange={(v) => {
                                        setExtendedId(v as ExtendedId);
                                        setNetwork(null);
                                    }}
                                    loading={isExtendedIdsLoading}
                                    fieldAsLabel={["name"]}
                                    fieldAsValue={["id"]}
                                    placeholder="Select Source"
                                />
                            </Col>
                            :
                            <Col flex="8">
                                <SelectWithValue<SeatAvailableNetworks>
                                    style={{ width: "100%" }}
                                    options={networks}
                                    value={network}
                                    onChange={(v) => setNetwork(v as SeatAvailableNetworks)}
                                    loading={isNetworkLoading}
                                    fieldAsLabel={["name"]}
                                    fieldAsValue={["id"]}
                                    placeholder="Select Network"
                                />
                            </Col>
                            <Col flex="2">
                                <Button
                                    block
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        if (!extendedId || !network) {
                                            return;
                                        }
                                        const nextVal = {
                                            value: getValue(extendedId, network),
                                            label: getLabel(extendedId, network),
                                        };

                                        const nextFormValue = [...formValue, nextVal];

                                        form.setFieldValue(
                                            ["transparency", index, "allowedExtendedIdTransparencies"],
                                            nextFormValue
                                        );

                                        setExtendedId(null);
                                        setNetwork(null);
                                    }}
                                    disabled={
                                        !extendedId ||
                                        !network ||
                                        hasAlreadySelectedValue(formValue, extendedId, network)
                                    }
                                >
                                    Add Extended User ID
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Form.Item name={[index, "allowedExtendedIdTransparencies"]} wrapperCol={{ xs: 24 }}>
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            options={formValue || []}
                            labelInValue
                            placeholder="Selected Extended User ID(s) are shown here"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </ResizableDrawer>
    );
};
