import { Card } from "antd";
import { FC } from "react";
import {
    QPSLimitsField,
    MaxAdsUserField,
    MokaIntegration,
    AdSourceTypeLimitsField,
    ViewabilityMesurementField,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/ExchangeProtectionsFormSections/InventoryLimitsSection/Fields";

export const InventoryLimitsSection: FC = () => (
    <Card>
        <AdSourceTypeLimitsField />
        <QPSLimitsField />
        <MaxAdsUserField />
        <ViewabilityMesurementField />
        <MokaIntegration />
    </Card>
);
