import { useUpdateSeatInContext, useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import { Store } from "rc-field-form/lib/interface";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Form, FormInstance, notification } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { Seat, UpdateSeatPayload, useGetSeatSelfQuery } from "@app/core/services/console";
import {
    getAdvancedFeaturesFormInitialValues,
    parseAdvancedFeaturesFormValuesToApi,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/advancedFeaturesFormUtils";

export interface AdvancedFeaturesForm {
    // Inventory Management
    [INVENTORY_FORM_FIELDS.SSP_CONNECT_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.CHANNELS_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name]: boolean;
    // CTV Tools
    [INVENTORY_FORM_FIELDS.SEAT_POD_DEDUPING_LEVEL.name]: number;
    [INVENTORY_FORM_FIELDS.CONTENT_METADATA_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.SEAT_CONTENT_METADATA_KEYS.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.AD_POD_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.LINEAR_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.LSA_ENABLED.name]: boolean;
    // Identity and Regulations
    [INVENTORY_FORM_FIELDS.DMP_CREATION_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.DMPS.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.IDL_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.OZTAM_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.MASK_DEVICE_ID.name]: boolean;
    [INVENTORY_FORM_FIELDS.GDPR_GOOGLE_CONSENT_ALL.name]: boolean;
    [INVENTORY_FORM_FIELDS.ENABLE_AUDIENCE_TARGETING.name]: boolean;
    [INVENTORY_FORM_FIELDS.ENABLE_MAGNITE_MATCH.name]: boolean;
    [INVENTORY_FORM_FIELDS.ENABLE_LIVERAMP_ATS.name]: boolean;
    // Creative Control
    [INVENTORY_FORM_FIELDS.ENABLE_ADVERTISER_WHITELIST.name]: boolean;
    [INVENTORY_FORM_FIELDS.CREATIVE_BLOCK_MODE.name]: number;
    [INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [INVENTORY_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: number | null;
    [INVENTORY_FORM_FIELDS.IGNORE_BCAT_BADV_ENABLED.name]: boolean;
    // Ad Responses
    [INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.ALLOW_AD_RESPONSE_PRICE_OVERRIDE.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_AD_SOURCE_TYPE.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY.name]: boolean;
    [INVENTORY_FORM_FIELDS.EMIT_PRICEING_IN_VAST.name]: boolean;
    [INVENTORY_FORM_FIELDS.SEAT_FORMAT_OVERRIDE_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.TELARIA_CHAIN_DISABLED.name]: boolean;
    // Demand Management
    [INVENTORY_FORM_FIELDS.AUTHORIZED_MARKETPLACES.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.ALLOW_FORWARD_MARKET_DEAL_BLOCKS.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_HIGH_CPM.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_TAKE_RATE_TRANSPARENCY.name]: boolean;
    [INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name]: LabeledValue[];
    [INVENTORY_FORM_FIELDS.ENABLE_DEMAND_ACQUISITION_COST.name]: boolean;
    [INVENTORY_FORM_FIELDS.ASAP_FEATHERING.name]: boolean;
    //Unified Decisioning
    [INVENTORY_FORM_FIELDS.UD_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.UD_FEE.name]: number | null;
    [INVENTORY_FORM_FIELDS.UDE_CPM_FEE_CURRENCY.name]: LabeledValue;
    [INVENTORY_FORM_FIELDS.UD_LID_DISCOVERY_MODE.name]: LabeledValue | null;
    [INVENTORY_FORM_FIELDS.ALLOW_BLIND_COMPETITION.name]: boolean;
    [INVENTORY_FORM_FIELDS.MEDIATION_ENABLED.name]: boolean;
    // Admin Tools
    [INVENTORY_FORM_FIELDS.ALERTS_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.TEST_CREATIVES_ENABLED.name]: boolean;
    [INVENTORY_FORM_FIELDS.TEST_CREATIVE_ID.name]: number | null;
    [INVENTORY_FORM_FIELDS.CLICK_TRACKING.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_VAST_UNWRAPPING.name]: boolean;
    [INVENTORY_FORM_FIELDS.ALLOW_OR_TARGETING.name]: boolean;
    [INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_TYPE.name]: number;
    [INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.name]: string;
}

interface UseAdvancedFeaturesForm {
    isUpdating: boolean;
    isFetching: boolean;
    handleCancel: () => void;
    hasInternalAccess: boolean;
    seat: Seat | null | undefined;
    initialValues: Store | undefined;
    form: FormInstance<AdvancedFeaturesForm>;
    handleSubmit: () => void;
}

export const useAdvancedFeaturesForm = (): UseAdvancedFeaturesForm => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();
    const [form] = Form.useForm<AdvancedFeaturesForm>();
    const { data, isFetching } = useGetSeatSelfQuery(seatId);
    const hasInternalAccess: boolean = isSysAdmin || isPubAcctMgr;

    const initialValues: Store | undefined = getAdvancedFeaturesFormInitialValues(data?.seat);

    const { updateSeatSelf, isLoading: isUpdating } = useUpdateSeatInContext();
    const handleCancel = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_ADVANCED_DETAILS(seatId));

    const handleSubmit = async (): Promise<void> => {
        try {
            await form.validateFields();
            if (!data?.seat) return;

            const values = form.getFieldsValue();
            const body: UpdateSeatPayload = parseAdvancedFeaturesFormValuesToApi(
                data?.seat,
                values,
                isSysAdmin,
                isPubAcctMgr,
                isTremorUser
            );

            try {
                await updateSeatSelf(body);
                notification.success({ message: "Seat updated successfully" });
                handleCancel();
            } catch (err) {
                notification.error({ message: err.data.errorDescription });
            }
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };

    return {
        form,
        isFetching,
        isUpdating,
        handleCancel,
        handleSubmit,
        initialValues,
        seat: data?.seat,
        hasInternalAccess,
    };
};
