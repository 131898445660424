import { Form, Radio } from "antd";
import { CHANNEL_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";
import { usePodDedupeLevel } from "./usePodDedupeLevel";

export const PodDedupeLevel = () => {
    const { options } = usePodDedupeLevel();

    return (
        <Form.Item name={CHANNEL_FORM_FIELDS.POD_DEDUPE_LEVEL.name} label={CHANNEL_FORM_FIELDS.POD_DEDUPE_LEVEL.label}>
            <Radio.Group size="large" options={options} />
        </Form.Item>
    );
};
