import { Form, Radio } from "antd";
import { usePodDedupeMode } from "./usePodDedupeMode";
import { AD_UNIT_FORM_FIELDS } from "@app/features/inventory/HierarchyForms/constants";

export const PodDedupeMode = () => {
    const { options } = usePodDedupeMode();

    return (
        <Form.Item name={AD_UNIT_FORM_FIELDS.POD_DEDUPE_LEVEL.name} label={AD_UNIT_FORM_FIELDS.POD_DEDUPE_LEVEL.label}>
            <Radio.Group size="large" options={options} />
        </Form.Item>
    );
};
