import { FC } from "react";
import { CollapsePanelProps, Typography } from "antd";
import { TagsCollapsePanel } from "@rubicon/antd-components";
import { InventoryLabelValue, InventoryLabelValueEntityType } from "../constants";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useParams } from "react-router-dom";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { Brand, Supply } from "@app/core/services";

export interface ReadOnlyInventoryTargetsPanelProps extends CollapsePanelProps {
    targetsPublishers: InventoryLabelValue[];
    targetsAdUnits: InventoryLabelValue[];
    targetsBrands: InventoryLabelValue[];
    targetsSupply: InventoryLabelValue[];
}

const getPublisherId = (opt: { id: number }): number => {
    return opt.id;
};

const getBrandIdWithParent = (opt: Pick<Brand, "id" | "publisher">): { brandId: number; publisherId: number } => {
    return { brandId: opt.id, publisherId: getPublisherId(opt.publisher) };
};

const getSupplyIdWithParent = (
    opt: Pick<Supply, "id" | "brand">
): { supplyId: number; brandId: number; publisherId: number } => {
    return { supplyId: opt.id, ...getBrandIdWithParent(opt.brand) };
};

const getInventoryDetailsUrl = (value: string, seatId: string): string => {
    const { origin, pathname } = window.location;
    const { id, entityType, ...opts } = JSON.parse(value);

    let url = `${origin}${pathname}#`;

    switch (entityType) {
        case InventoryLabelValueEntityType.PUBLISHER: {
            url += ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                InventoryDetailsDrawerType.PUBLISHER,
                seatId,
                id
            ) as string;
            break;
        }
        case InventoryLabelValueEntityType.BRAND: {
            const publisherId = getPublisherId(opts.publisher);
            url += ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                InventoryDetailsDrawerType.BRAND,
                seatId,
                publisherId,
                id
            ) as string;
            break;
        }
        case InventoryLabelValueEntityType.SUPPLY: {
            const { publisherId, brandId } = getBrandIdWithParent(opts.brand);
            url += ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                InventoryDetailsDrawerType.SUPPLY,
                seatId,
                publisherId,
                brandId,
                id
            ) as string;
            break;
        }
        case InventoryLabelValueEntityType.ADUNIT: {
            const { publisherId, brandId, supplyId } = getSupplyIdWithParent(opts.supply);

            url += ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                InventoryDetailsDrawerType.AD_UNIT,
                seatId,
                publisherId,
                brandId,
                supplyId,
                id
            ) as string;
            break;
        }
        case InventoryLabelValueEntityType.CHANNEL: {
            if (opts.hasOwnProperty("publisher")) {
                const publisherId = getPublisherId(opts.publisher);
                url += ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.BRAND_CHANNEL,
                    seatId,
                    publisherId,
                    id
                ) as string;
            } else {
                url += ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT(
                    InventoryDetailsDrawerType.CHANNEL,
                    seatId,
                    id
                ) as string;
            }
        }
    }
    return url;
};

const mapInventoryValue = ({ label, value }: InventoryLabelValue, seatId: string) => {
    const url = getInventoryDetailsUrl(value, seatId);
    const [category, rest] = label.split(": ");

    const subCategoryNames = rest.split("> ");
    const values = {
        label: (
            <>
                <Typography.Text type="secondary">{category}: </Typography.Text>
                {subCategoryNames.map((name, i) => {
                    if (i === subCategoryNames.length - 1) {
                        return (
                            <Typography.Text strong key={name}>
                                {name}
                            </Typography.Text>
                        );
                    }
                    return `${name} > `;
                })}
            </>
        ),
        value,
    };

    if (url) {
        Object.assign(values, { linkAttr: { href: url } });
    }

    return values;
};

export const ReadOnlyInventoryTargetsPanel: FC<ReadOnlyInventoryTargetsPanelProps> = ({
    targetsBrands,
    targetsSupply,
    targetsAdUnits,
    targetsPublishers,
    ...rest
}) => {
    const { seatId } = useParams<{ seatId: string }>();
    const targets = targetsPublishers.concat(targetsBrands, targetsAdUnits, targetsSupply);
    const values = targets.map(({ label, value }) =>
        mapInventoryValue({ label, value } as InventoryLabelValue, seatId)
    );

    return <TagsCollapsePanel values={values} {...rest} />;
};
