import { useState } from "react";
import { Form } from "antd";
import moment, { Moment } from "moment-timezone";
import { AD_SOURCE_FIELDS } from "../../../../constants";
import { isPacingTypeAsap } from "../../../utils/pacingUtils";
import { END_DATE_INDEX } from "./constants";
import { FormPacingDeliverySchedule, OnScheduleFieldChange } from "./types";
import { validateAllOrNoneImpressionGoals, validateAtLeastTwoSchedules, validateScheduleDates } from "./validators";
import { cloneSchedules, sortMiddleSchedules } from "./utils";

const {
    PACING_DELIVERY_SCHEDULE_DATES: DATE_RANGE,
    PACING_DELIVERY_SCHEDULE_IMPRESSION_GOAL: IMPRESSION_GOAL,
    PACING_DELIVERY_SCHEDULE_DAILY_IMPRESSION_CAP: DAILY_IMPRESSION_CAP,
} = AD_SOURCE_FIELDS;

interface UsePacingDeliverySchedule {
    impressionGoalIsRequired: boolean;
    dailyImpressionCapIsDisabled: boolean;
    overlappingDateRanges: Set<number>;
    rules: { validator: (_: unknown, schedules: FormPacingDeliverySchedule[]) => Promise<void> }[];
    isDisabled: (rowIndex: number) => boolean;
    onScheduleFieldChange: OnScheduleFieldChange;
    onAddSchedule: () => void;
    onDeleteSchedule: (scheduleIndex: number) => void;
}

const getEmptySchedule = (): FormPacingDeliverySchedule => ({
    dates: [null, null],
    impressionGoal: null,
    dailyCap: null,
});

export const usePacingDeliverySchedule = (
    name: string,
    pacingTypeId: number | null | undefined,
    startDate: Moment | null,
    endDate: Moment | null,
    totalImpressions: number | null,
    formMode: string,
    propOnChange?: (schedules: FormPacingDeliverySchedule[]) => void
): UsePacingDeliverySchedule => {
    const form = Form.useFormInstance();
    const schedules = Form.useWatch<FormPacingDeliverySchedule[] | undefined>(name) ?? [
        getEmptySchedule(),
        getEmptySchedule(),
    ];
    const lastScheduleIndex = schedules.length - 1;

    const impressionGoalIsRequired = schedules.some(({ impressionGoal }) => Boolean(impressionGoal));

    const dailyImpressionCapIsDisabled = !isPacingTypeAsap(pacingTypeId);

    const [overlappingDateRanges, setOverlappingDateRanges] = useState(new Set<number>());

    const rules = [
        { validator: (_, schedules: FormPacingDeliverySchedule[]) => validateAtLeastTwoSchedules(schedules) },
        {
            validator: (_, schedules: FormPacingDeliverySchedule[]) =>
                validateScheduleDates(schedules, startDate, endDate, setOverlappingDateRanges),
        },
        {
            validator: (_, schedules: FormPacingDeliverySchedule[]) =>
                validateAllOrNoneImpressionGoals(schedules, totalImpressions),
        },
    ];

    const isDisabled = (rowIndex: number): boolean => {
        if (formMode === "edit") {
            const scheduleEndDate = schedules[rowIndex]?.dates[END_DATE_INDEX];
            const hasSurpassedEndDate = scheduleEndDate && scheduleEndDate.isBefore(moment());
            return Boolean(hasSurpassedEndDate);
        }
        return false;
    };

    const updateSchedules = (newSchedules: FormPacingDeliverySchedule[]) => {
        form.setFieldValue(name, newSchedules);

        if (propOnChange) {
            propOnChange(newSchedules);
        }
        const hasScheduleFieldErrors =
            form.getFieldsError().filter((fieldError) => fieldError.name[0] === name && fieldError.errors.length)
                .length > 0;
        if (hasScheduleFieldErrors) {
            const scheduleFieldNames = schedules
                .map((_, rowIndex) => [
                    [name, rowIndex, DATE_RANGE.name],
                    [name, rowIndex, IMPRESSION_GOAL.name],
                    [name, rowIndex, DAILY_IMPRESSION_CAP.name],
                ])
                .flat();
            form.validateFields([name, ...scheduleFieldNames]);
        }
    };

    const onScheduleFieldChange: OnScheduleFieldChange = ({ dates, impressionGoal, dailyCap }, updateIndex) => {
        if (!schedules?.[updateIndex]) {
            return;
        }

        const isChangeDates = dates !== undefined;
        const isChangeImpressionGoal = impressionGoal !== undefined;
        const isChangeDailyCap = dailyCap !== undefined;

        if (![isChangeDates, isChangeImpressionGoal, isChangeDailyCap].some(Boolean)) {
            return;
        }

        const newSchedules = cloneSchedules(schedules);

        if (isChangeImpressionGoal) {
            newSchedules[updateIndex].impressionGoal = impressionGoal;
        }
        if (isChangeDailyCap) {
            newSchedules[updateIndex].dailyCap = dailyCap;
        }
        if (isChangeDates) {
            newSchedules[updateIndex].dates = dates;
        }

        const sortedSchedules = isChangeDates ? sortMiddleSchedules(newSchedules) : newSchedules;
        updateSchedules(sortedSchedules);
    };

    const onAddSchedule = () => {
        const newSchedules = cloneSchedules(schedules);
        newSchedules.splice(lastScheduleIndex, 0, getEmptySchedule());
        updateSchedules(newSchedules);
    };

    const onDeleteSchedule = (scheduleIndex: number) => {
        const newSchedules = cloneSchedules(schedules).filter((_, rowIndex) => rowIndex !== scheduleIndex);
        updateSchedules(newSchedules);
    };

    return {
        impressionGoalIsRequired,
        dailyImpressionCapIsDisabled,
        overlappingDateRanges,
        rules,
        isDisabled,
        onScheduleFieldChange,
        onAddSchedule,
        onDeleteSchedule,
    };
};
