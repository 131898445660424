import { FC } from "react";
import { Card, Row, Col } from "antd";

import {
    FillMode,
    MaxAdsPerPod,
    MaxPodSeconds,
    PodEnforcement,
    CompetitiveSeparationMode,
    CompetitiveSeparationGroups,
    PodDedupeLevel,
} from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormSections/AdvancedFeaturesSection/Sections/AdPodSection/Fields";

const GUTTER = 32;
const COL_SIZES = { xs: 24, sm: 24, md: 24, lg: 18, xl: 14, xxl: 11 };
const INPUTS_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };
export const AdPodSection: FC = () => {
    return (
        <Card bordered={false}>
            <Row>
                <Col {...COL_SIZES}>
                    <Row gutter={GUTTER}>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxPodSeconds />
                        </Col>
                        <Col {...INPUTS_COL_SIZES}>
                            <MaxAdsPerPod />
                        </Col>
                    </Row>
                    <FillMode />
                    <PodEnforcement />
                    <PodDedupeLevel />
                    <CompetitiveSeparationMode />
                </Col>
            </Row>
            <CompetitiveSeparationGroups />
        </Card>
    );
};
