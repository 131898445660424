import { AdResponsePriceOverride, Targeting } from "@app/core/services";
import { parseCategoryToApi, targetingToTargetingBlock } from "@app/features/targeting";
import { parseBookingPriceToApi, parseCostModelToApi, parseCostPercent } from ".";
import { AD_SOURCE_FIELDS } from "../../constants";
import {
    getSegmentsFromTargetingForm,
    getIsRangedCost,
    getAudiencesFromTargetingForm,
    getDemandFeeFromEnrichmentCost,
    getAudienceEnrichmentCost,
} from "../AdSourcesSections/EnrichmentCostSection/utils";
import { AdSourcesForm } from "../SeatAdSourcesForm";
import { DEFAULT_TIME_ZONE } from "@app/core/components/constants";
import { EnrichmentCostApiFields } from "./useParseEnrichmentCostFields";
import { convertLocalDateToApi } from "@app/core/utils/date";
import { parseAdditionalAdvertiserDomainsToApi } from "@app/features/deals/DealForm/utils";
import { parseAuctionTypeToApi } from "./parseAuctionTypeToApi";
import { parseCostFixedToApi } from "./parseCostFixedToApi";
import { parseCuratorMarketplaceIdsToApi } from "./parseCuratorMarketplaceIdsToApi";
import { parseMarketplaceInfoListToApi } from "./parseMarketplaceInfoListToApi";
import { parsePacingToApi } from "./pacingUtils";
import { parsePriceModelFieldsToApi } from "./parsePriceModelFieldsToApi";
import { parseRegionSelectToApi } from "./parseRegionSelect";
import { parseVastDynamicPricingLocationToApi } from "./parsVastDynamicPricingLocationToApi";
import { parseBlockedIndustriesToApi } from "@app/core/components/BlockedIndustriesSelect/utils";

export const parseAdSourceFromFormToApi = ({
    form,
    seatId,
    targeting,
    adResponsePriceOverride,
    showFallbackOpportunity,
    enrichmentCostFields,
    hasPacingDeliverySchedulesAccess,
}: {
    form: AdSourcesForm;
    seatId: string;
    targeting?: Targeting[];
    adResponsePriceOverride?: AdResponsePriceOverride;
    showFallbackOpportunity: boolean;
    enrichmentCostFields: EnrichmentCostApiFields;
    hasPacingDeliverySchedulesAccess: boolean;
}) => {
    const contentTransparencyRule = form.allowContent && "id" in form.allowContent ? form.allowContent : null;
    if (contentTransparencyRule && "value" in contentTransparencyRule) {
        delete contentTransparencyRule["value"];
    }
    const adSourceType = form[AD_SOURCE_FIELDS.TYPE.name];
    const targetingBlocks = targeting?.map(targetingToTargetingBlock) || [];
    const adSourceAudiences = getAudiencesFromTargetingForm(targetingBlocks);
    const adSourceSegments = getSegmentsFromTargetingForm(targetingBlocks);
    const isRangedCost = getIsRangedCost(adSourceType);
    const adSourceEc = getAudienceEnrichmentCost(adSourceAudiences, adSourceSegments, isRangedCost, 1);
    const demandFee = getDemandFeeFromEnrichmentCost(adSourceEc);
    const timeZone = form[AD_SOURCE_FIELDS.TIME_ZONE.name];
    const timeZoneCode = timeZone?.code || DEFAULT_TIME_ZONE.code;

    const {
        pacingType,
        pacingPeriod,
        redistributePacing,
        dailyCapVolume,
        dailyRequestOpportunityFallback,
        customCurveStartDate,
        customCurveImps,
        pacingDeliveryScheduleEnabled,
        pacingDeliverySchedules,
    } = parsePacingToApi(
        adSourceType?.id,
        form[AD_SOURCE_FIELDS.PACING_TYPE.name]?.id,
        form[AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.name],
        form[AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP.name],
        form[AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY.name],
        form[AD_SOURCE_FIELDS.TARGET_DATE.name],
        form[AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name],
        showFallbackOpportunity,
        form[AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED.name],
        form[AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name],
        timeZoneCode,
        form[AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED.name],
        hasPacingDeliverySchedulesAccess
    );

    return {
        [AD_SOURCE_FIELDS.TYPE.name]: form[AD_SOURCE_FIELDS.TYPE.name],
        [AD_SOURCE_FIELDS.NAME.name]: form[AD_SOURCE_FIELDS.NAME.name],
        [AD_SOURCE_FIELDS.DESCRIPTION.name]: form[AD_SOURCE_FIELDS.DESCRIPTION.name],
        [AD_SOURCE_FIELDS.PRIORITY.name]: form[AD_SOURCE_FIELDS.PRIORITY.name],
        [AD_SOURCE_FIELDS.UD_PRIORITY.name]: form[AD_SOURCE_FIELDS.UD_PRIORITY.name],
        [AD_SOURCE_FIELDS.ASSIGNEE.name]: form[AD_SOURCE_FIELDS.ASSIGNEE.name],
        [AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name]: form[AD_SOURCE_FIELDS.ASSOCIATED_MARKETPLACE.name],
        [AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.name]: parseCuratorMarketplaceIdsToApi(
            form[AD_SOURCE_FIELDS.ASSOCIATED_CURATOR_MARKETPLACES.name]
        ),
        [AD_SOURCE_FIELDS.STATUS.name]: form[AD_SOURCE_FIELDS.STATUS.name],
        [AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name]: form[AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name],
        [AD_SOURCE_FIELDS.TAG_URL.name]: form[AD_SOURCE_FIELDS.TAG_URL.name],
        [AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.name]: form[AD_SOURCE_FIELDS.ASSIGNED_ADVERTISER_DOMAIN.name],
        [AD_SOURCE_FIELDS.COST_MODEL.name]: parseCostModelToApi(form[AD_SOURCE_FIELDS.COST_MODEL.name]),
        [AD_SOURCE_FIELDS.COST_PERCENT.name]: parseCostPercent(form[AD_SOURCE_FIELDS.COST_PERCENT.name]),
        [AD_SOURCE_FIELDS.COST_FIXED.name]: parseCostFixedToApi(form[AD_SOURCE_FIELDS.COST_FIXED.name]),
        [AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name]: form[AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name],
        [AD_SOURCE_FIELDS.REGION.name]: parseRegionSelectToApi(form[AD_SOURCE_FIELDS.REGION.name]),
        [AD_SOURCE_FIELDS.SALES_CONTACT.name]: form[AD_SOURCE_FIELDS.SALES_CONTACT.name],
        [AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name]: form[AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name],
        [AD_SOURCE_FIELDS.MODE.name]: form[AD_SOURCE_FIELDS.MODE.name],
        [AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name]: form[AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name],
        [AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name]: parseAdditionalAdvertiserDomainsToApi(
            form[AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name]
        ),
        seat: { id: Number(seatId) },
        [AD_SOURCE_FIELDS.START_DATE.name]: convertLocalDateToApi(form[AD_SOURCE_FIELDS.START_DATE.name], timeZoneCode),
        [AD_SOURCE_FIELDS.END_DATE.name]: convertLocalDateToApi(form[AD_SOURCE_FIELDS.END_DATE.name], timeZoneCode),
        [AD_SOURCE_FIELDS.TIME_ZONE.name]: timeZone,
        [AD_SOURCE_FIELDS.MIN_DURATION.name]: form[AD_SOURCE_FIELDS.MIN_DURATION.name] ?? null,
        [AD_SOURCE_FIELDS.MAX_DURATION.name]: form[AD_SOURCE_FIELDS.MAX_DURATION.name] ?? null,
        [AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name]:
            form[AD_SOURCE_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name],
        [AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name]: form[AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name] || null,
        [AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name]: form[AD_SOURCE_FIELDS.SHARE_OF_VOICE_PERCENT.name] || null,
        ...parsePriceModelFieldsToApi(adSourceType?.id),
        [AD_SOURCE_FIELDS.FIXED_CPM.name]:
            form[AD_SOURCE_FIELDS.FIXED_CPM.name] === null
                ? null
                : parseBookingPriceToApi(Number(form[AD_SOURCE_FIELDS.FIXED_CPM.name])),
        [AD_SOURCE_FIELDS.CURRENCY.name]: form[AD_SOURCE_FIELDS.CURRENCY.name],
        [AD_SOURCE_FIELDS.FLOOR_TYPE.name]: form[AD_SOURCE_FIELDS.FLOOR_TYPE.name],
        [AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name]: form[AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name],
        [AD_SOURCE_FIELDS.AUCTION_TYPE.name]: parseAuctionTypeToApi(
            adSourceType?.id,
            form[AD_SOURCE_FIELDS.AUCTION_TYPE.name]
        ),
        [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name]: parseVastDynamicPricingLocationToApi(
            form[AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_LOCATION.name]
        ),
        [AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name]:
            form[AD_SOURCE_FIELDS.VAST_DYNAMIC_PRICING_MULTIPLIER.name],
        [AD_SOURCE_FIELDS.PACING_PERIOD.name]: pacingPeriod,
        [AD_SOURCE_FIELDS.PACING_TYPE.name]: pacingType,
        [AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP.name]: dailyCapVolume,
        [AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY.name]: dailyRequestOpportunityFallback,
        [AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.name]: redistributePacing,
        [AD_SOURCE_FIELDS.TARGET_DATE.name]: customCurveStartDate,
        [AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name]: customCurveImps,
        [AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED.name]: pacingDeliveryScheduleEnabled,
        [AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name]: pacingDeliverySchedules,
        [AD_SOURCE_FIELDS.PIXELS.name]: form[AD_SOURCE_FIELDS.PIXELS.name],
        [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: form[AD_SOURCE_FIELDS.SUPPLY_NAME.name],
        [AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.name]: form[AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.name],
        [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: form.allowDomainName === "override" ? true : form.allowDomainName,
        [AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name]: form[AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name] || null,
        [AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name]: form[AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.name],
        [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: form[AD_SOURCE_FIELDS.SUPPLY_PAGE.name],
        [AD_SOURCE_FIELDS.REFER_URL.name]: form[AD_SOURCE_FIELDS.REFER_URL.name],
        [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]: form[AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name],
        [AD_SOURCE_FIELDS.BUNDLE_ID.name]: form.allowBundleId === "override" ? true : form.allowBundleId,
        [AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name]: form[AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name] || null,
        [AD_SOURCE_FIELDS.STORE_URL.name]: form[AD_SOURCE_FIELDS.STORE_URL.name],
        [AD_SOURCE_FIELDS.USER_ID.name]: form.allowUserId === "masked" ? true : form.allowUserId,
        maskUserId: form[AD_SOURCE_FIELDS.USER_ID.name] === "masked",
        [AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name]: form[AD_SOURCE_FIELDS.BVOD_CONNECT_ID.name],
        [AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name]: form[AD_SOURCE_FIELDS.BVOD_CONNECT_DEMOGRAPHIC.name],
        [AD_SOURCE_FIELDS.CONTENT.name]:
            form.allowContent && "value" in form.allowContent ? form.allowContent.value : true,
        [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]:
            form.allowAllExtendedId === "specific" ? false : form.allowAllExtendedId,
        [AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]: form[AD_SOURCE_FIELDS.EXTENDED_USER_ID.name] || [],
        contentTransparencyRule,
        [AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name]: form[AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name],
        [AD_SOURCE_FIELDS.LABEL.name]: form[AD_SOURCE_FIELDS.LABEL.name],
        [AD_SOURCE_FIELDS.DEMAND.name]: form[AD_SOURCE_FIELDS.DEMAND.name]
            ? parseMarketplaceInfoListToApi(
                  adSourceType?.id,
                  form[AD_SOURCE_FIELDS.DEMAND.name],
                  timeZoneCode,
                  adSourceEc,
                  isRangedCost
              )
            : [],
        [AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name]: form[AD_SOURCE_FIELDS.PBS_BIDDER_CONFIGS.name] || [],
        [AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name]: form[AD_SOURCE_FIELDS.EXCLUDE_DEMAND.name],
        [AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name]: form[AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name],
        demandFee,
        [AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name]: form[AD_SOURCE_FIELDS.IAB_CATEGORY_BLOCK_MODE.name] || null,
        [AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name]: form[AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name]?.length
            ? form[AD_SOURCE_FIELDS.IAB_BLOCKED_CATEGORIES.name]?.map(parseCategoryToApi)
            : null,
        [AD_SOURCE_FIELDS.INDUSTRIES_MODE.name]: form[AD_SOURCE_FIELDS.INDUSTRIES_MODE.name]?.id
            ? { id: form[AD_SOURCE_FIELDS.INDUSTRIES_MODE.name].id }
            : null,
        [AD_SOURCE_FIELDS.BLOCKED_INDUSTRIES.name]: parseBlockedIndustriesToApi(
            form[AD_SOURCE_FIELDS.BLOCKED_INDUSTRIES.name]
        ),
        ...enrichmentCostFields,
        [AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]: form[
            AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name
        ]?.length
            ? form[AD_SOURCE_FIELDS.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES.name]?.map(({ value }) => ({ id: value }))
            : null,
        [AD_SOURCE_FIELDS.AD_RESPONSE_PRICE_OVERRIDE.name]: adResponsePriceOverride || {
            id: 1,
        },
    };
};
