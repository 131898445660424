import { Label } from "@app/core/services";
import { Col, Form, Row, Typography } from "antd";
import { FC } from "react";

export const CLASSIFICATIONS_LABEL_EDIT_KEY_READ_ONLY_SDET = "classifications-label-edit-key-read-only";

interface Props {
    label: Label;
}

export const ClassificationsLabelEditKeyReadOnly: FC<Props> = ({ label }) => {
    const isDistributionGroup: boolean = !!label?.isDistributionGroup;
    return (
        <Form
            data-sdet={CLASSIFICATIONS_LABEL_EDIT_KEY_READ_ONLY_SDET}
            layout="vertical"
            wrapperCol={{ xs: 24, lg: 12 }}
        >
            <Form.Item label="Label Key">
                <Typography.Text>{label.key}</Typography.Text>
            </Form.Item>
            <Row gutter={16}>
                <Col xs={24} sm={12} lg={6}>
                    <Form.Item label="Inventory Distribution Group">
                        <Typography.Text>{label.isDistributionGroup ? "Yes" : "No"}</Typography.Text>
                    </Form.Item>
                </Col>
                {!isDistributionGroup && (
                    <Col xs={24} sm={12} lg={6}>
                        <Form.Item label="Multi-Instance">
                            <Typography.Text>{label.multiValue ? "Yes" : "No"}</Typography.Text>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            {!isDistributionGroup && (
                <Row gutter={16}>
                    <Col xs={24} sm={12} lg={6}>
                        <Form.Item label="Waterfall Pre-Filter">
                            <Typography.Text>{label.enablePrefilter ? "Yes" : "No"}</Typography.Text>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                        <Form.Item label="Label Visibility">
                            <Typography.Text>{label.isPublic ? "Public" : "Internal"}</Typography.Text>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </Form>
    );
};
