import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Col, Row, Tag, Typography } from "antd";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { formatDateStringFromApi } from "@app/core/utils";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES, AdSourceTypes } from "@app/features/seatAdSources/constants";
import { useSeatAuthContext } from "@app/core/auth";
import { format, formatNumber } from "@rubicon/utils";

interface Props {
    adSource: AdSource;
}
const { Paragraph, Text } = Typography;

export const SeatAdSourcesDeliveryDetails: FC<Props> = ({ adSource }) => {
    const { context } = useSeatAuthContext();

    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSource.type.name];

    if (adSourceTypeName === AdSourceTypes.PREBID_DEALS_CONTAINER) {
        return null;
    }

    const isDurationFieldsVisible = adSourceTypeName !== AdSourceTypes.TREMOR_NETWORK;

    const isShareOfVoiceEnabledVisible =
        adSourceTypeName === AdSourceTypes.SERVER_SIDE_TAG_GUARANTEED ||
        adSourceTypeName === AdSourceTypes.CLIENT_SIDE_TAG_GUARANTEED;

    const isShareOfVoicePercentVisible = isShareOfVoiceEnabledVisible && adSource.shareOfVoicePercent;

    const isTotalImpressionsVisible =
        adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED ||
        (isShareOfVoiceEnabledVisible && !isShareOfVoicePercentVisible);

    const isShareOfVoiceOrTotalImpressionsVisible =
        isShareOfVoiceEnabledVisible || isShareOfVoicePercentVisible || isTotalImpressionsVisible;

    const isDistributionGroupsFieldVisible =
        context?.distributionGroupsEnabled && adSourceTypeName === AdSourceTypes.PROGRAMATIC_GUARANTEED;

    return (
        <>
            <Row>
                <Col sm={8}>
                    <Text strong>Start Date</Text>
                    <Paragraph>
                        {formatDateStringFromApi(
                            adSource?.bookingStartDate,
                            adSource?.timeZone?.code,
                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                        )}
                    </Paragraph>
                </Col>
                <Col sm={8}>
                    <Text strong>End Date</Text>
                    <Paragraph>
                        {adSource?.bookingEndDate !== null
                            ? formatDateStringFromApi(
                                  adSource?.bookingEndDate,
                                  adSource?.timeZone?.code,
                                  MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                              )
                            : format.constants.NOT_SPECIFIED}
                    </Paragraph>
                </Col>
                <Col sm={8}>
                    <Text strong>Time Zone</Text>
                    <Paragraph>
                        {format.asSelf(adSource?.timeZone?.name)} ({format.asSelf(adSource?.timeZone?.code)})
                    </Paragraph>
                </Col>
            </Row>
            {isShareOfVoiceOrTotalImpressionsVisible && (
                <Row>
                    {isShareOfVoiceEnabledVisible && (
                        <Col sm={8}>
                            <Text strong>Share Of Voice Enabled</Text>
                            <Paragraph>{format.asYesOrNo(adSource?.shareOfVoicePercent)}</Paragraph>
                        </Col>
                    )}
                    {isShareOfVoicePercentVisible && (
                        <Col sm={8}>
                            <Text strong>Share Of Voice Percent</Text>
                            <Paragraph>{formatNumber.asPercent(adSource?.shareOfVoicePercent)}</Paragraph>
                        </Col>
                    )}
                    {isTotalImpressionsVisible && (
                        <Col sm={8}>
                            <Text strong>Total Impressions</Text>
                            <Paragraph>{formatNumber.asNumber(adSource?.bookingVolume)}</Paragraph>
                        </Col>
                    )}
                </Row>
            )}
            {isDurationFieldsVisible && (
                <Row>
                    <Col sm={8}>
                        <Text strong>Min Duration</Text>
                        <Paragraph>{format.asSelf(adSource?.adSourceMinDuration)}</Paragraph>
                    </Col>
                    <Col sm={8}>
                        <Text strong>Max Duration</Text>
                        <Paragraph>{format.asSelf(adSource?.adSourceMaxDuration)}</Paragraph>
                    </Col>
                </Row>
            )}
            {isDistributionGroupsFieldVisible && (
                <Row>
                    <Col sm={8}>
                        <Text strong>Distribution Groups</Text>
                        <Paragraph>
                            {adSource?.inventoryDistributionGroups && adSource?.inventoryDistributionGroups.length > 0
                                ? adSource?.inventoryDistributionGroups.map((label) => (
                                      <Tag key={label.labelValue?.id}>
                                          {label?.labelValue?.label?.key}: {label?.cap}
                                      </Tag>
                                  ))
                                : format.constants.DEFAULT_DASHES}
                        </Paragraph>
                    </Col>
                </Row>
            )}
        </>
    );
};
