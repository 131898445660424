import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import { Form, Radio } from "antd";
import { usePodDedupeMode } from "./usePodDedupeMode";
import { FC } from "react";
import { Seat } from "@app/core/services";

interface Props {
    seat: Seat;
    edit?: boolean;
}

const SEAT_POD_DEDUPING_LEVEL_MAP = {
    1: "High",
    2: "Medium",
    3: "Low",
};

export const PodDedupeMode: FC<Props> = ({ edit = false, seat }) => {
    const { options } = usePodDedupeMode();
    const { seatFlags } = seat;

    if (!seatFlags) {
        return null;
    }

    return (
        <Form.Item
            name={INVENTORY_FORM_FIELDS.SEAT_POD_DEDUPING_LEVEL.name}
            label={INVENTORY_FORM_FIELDS.SEAT_POD_DEDUPING_LEVEL.label}
        >
            {edit ? (
                <Radio.Group size="large" options={options} />
            ) : (
                SEAT_POD_DEDUPING_LEVEL_MAP[seatFlags.podDeDupeMode]
            )}
        </Form.Item>
    );
};
