import { Card, Row, Col, Collapse } from "antd";
import { FC } from "react";
import { MinMaxDurationSelect } from "@rubicon/components-streaming";
import {
    mainStepSectionClassList,
    AD_SOURCE_SECTIONS,
    GUTTER,
    COL_SIZES,
    cardBodyStyle,
    AD_SOURCE_SECTIONS_NAME,
    AD_SOURCE_FIELDS,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import {
    StartDatePicker,
    EndDatePicker,
    TimeZoneSelect,
    ShareOfVoiceRadio,
    TotalImpressionsInput,
    ShareOfVoiceInput,
    InventoryDistributionGroupsList,
} from "./Fields";
import { AdSourceFormMode } from "../..";

const { MIN_DURATION, MAX_DURATION } = AD_SOURCE_FIELDS;

interface Props {
    mode: AdSourceFormMode | undefined;
}

export const DeliverySection: FC<Props> = ({ mode }) => {
    const isPbsDealType = useIsPbsDealType();
    const isVisible = !isPbsDealType;

    if (!isVisible) {
        return null;
    }

    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.DELIVERY,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.DELIVERY]}
                            section={AD_SOURCE_SECTIONS.DELIVERY}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <StartDatePicker />
                                </Col>
                                <Col {...COL_SIZES}>
                                    <EndDatePicker />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <TimeZoneSelect />
                                </Col>
                                <Col {...COL_SIZES}></Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <ShareOfVoiceRadio />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <TotalImpressionsInput />
                                    <ShareOfVoiceInput />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <MinMaxDurationSelect
                                        name={MIN_DURATION.name}
                                        type="min"
                                        oppositeName={MAX_DURATION.name}
                                        min={5}
                                    />
                                </Col>
                                <Col {...COL_SIZES}>
                                    <MinMaxDurationSelect
                                        name={MAX_DURATION.name}
                                        type="max"
                                        oppositeName={MIN_DURATION.name}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES}>
                                    <InventoryDistributionGroupsList mode={mode} />
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
