import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { CurrencyConversionModes, RevenueTypes } from "@app/core/clients/console";
import { MixCountAreaCurrencyLineChart } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";
import { TogglableChart } from "@app/core/components/charts/TogglableChart/TogglableChart";
import {
    FILLS_LABEL,
    IMPRESSIONS_LABEL,
    NET_REVENUE_LABEL,
    PERFORMANCE_REQUESTS_LABEL,
    PERFORMANCE_AD_POD_REQUESTS_LABEL,
    PERFORMANCE_PLAYLIST_REQUESTS_LABEL,
    PERFORMANCE_POD_SLOT_REQUESTS_LABEL,
    PERFORMANCE_FALL_THROUGHS_LABEL,
    PERFORMANCE_ERRORS_LABEL,
    PERFORMANCE_MOKAS_LABEL,
    PERFORMANCE_FALL_BACKS_LABEL,
    PERFORMANCE_SEAT_REJECTIONS_LABEL,
    PERFORMANCE_USER_REJECTIONS_LABEL,
    PERFORMANCE_BLOCKED_LABEL,
    PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL,
    PERFORMANCE_LSA_FILLS_LABEL,
    PERFORMANCE_LSA_IMPS_LABEL,
    PERFORMANCE_LSA_POD_REQUESTS_LABEL,
    PERFORMANCE_LSA_SLOT_REQUESTS_LABEL,
} from "@app/core/components/charts/constants";
import { useAppSelector } from "@app/core/store";
import {
    AdStat,
    getRequests,
    getTotalRevenue,
    getFills,
    getImpressions,
    getPodRequests,
    getPlaylistRequests,
    getPodSlotsRequests,
    getFallThroughs,
    getErrors,
    getMokas,
    getFallbacks,
    getSeatRejections,
    getUserRejections,
    getBlackListed,
    getLsaPotentialFills,
    getLsaPodRequests,
    getLsaFills,
    getLsaSlotRequests,
    getLsaImps,
} from "@app/features/adStats";
import { FC, useMemo, useRef } from "react";
import moment from "moment-timezone";
import { selectUserTimezone } from "@app/core/authClient/reducer";
import { Loading } from "@app/core/components";
import { ValueMetric1, ValueMetric2 } from "@app/core/components/charts/DualAxesChart";
import { X_AXIS_TICK_COUNT } from "@app/features/seatAdSources/seatAdSourcesCharts/charts";
import { Currency, CurrencyConversion } from "@app/core/services";

interface Props {
    adStat: AdStat;
    preferredCurrency: Currency | undefined;
    currencyConversions: CurrencyConversion[];
    currencyConversionMode: CurrencyConversionModes;
    chartId?: string;
}

export interface SeatChartStats {
    time: number;
    adPodRequests: number;
    playlistRequests: number;
    fills: number;
    impressions: number;
    netRev: number;
    requests: number;
    podSlotRequests: number;
    fallThroughs: number;
    errors: number;
    mokas: number;
    fallbacks: number;
    seatRejections: number;
    userRejections: number;
    blackListed: number;
    lsaPotentialFills: number;
    lsaPodRequests: number;
    lsaFills: number;
    lsaSlotRequests: number;
    lsaImps: number;
}

const getEventMetrics = (
    existingMetrics,
    stats: SeatChartStats,
    isTremorUser: boolean,
    timeZoneCode: string,
    lsaEnabled = false
) => {
    const time = moment(stats.time).tz(timeZoneCode).format("HH:mm:ss");
    let newMetrics = [
        ...existingMetrics,
        {
            time,
            value1: stats?.requests || 0,
            name1: PERFORMANCE_REQUESTS_LABEL,
        },
        {
            time,
            value1: stats?.adPodRequests || 0,
            name1: PERFORMANCE_AD_POD_REQUESTS_LABEL,
        },
        {
            time,
            value1: stats?.playlistRequests || 0,
            name1: PERFORMANCE_PLAYLIST_REQUESTS_LABEL,
        },
        {
            time,
            value1: stats?.fills || 0,
            name1: FILLS_LABEL,
        },
        {
            time,
            value1: stats?.impressions || 0,
            name1: IMPRESSIONS_LABEL,
        },
        {
            time,
            value1: stats?.podSlotRequests || 0,
            name1: PERFORMANCE_POD_SLOT_REQUESTS_LABEL,
        },
        {
            time,
            value1: stats?.fallThroughs || 0,
            name1: PERFORMANCE_FALL_THROUGHS_LABEL,
        },
        {
            time,
            value1: stats?.errors || 0,
            name1: PERFORMANCE_ERRORS_LABEL,
        },
        {
            time,
            value1: stats?.fallbacks || 0,
            name1: PERFORMANCE_FALL_BACKS_LABEL,
        },
        {
            time,
            value1: stats?.seatRejections || 0,
            name1: PERFORMANCE_SEAT_REJECTIONS_LABEL,
        },
        {
            time,
            value1: stats?.userRejections || 0,
            name1: PERFORMANCE_USER_REJECTIONS_LABEL,
        },
        {
            time,
            value1: stats?.blackListed || 0,
            name1: PERFORMANCE_BLOCKED_LABEL,
        },
    ];

    if (isTremorUser) {
        newMetrics.push({
            time,
            value1: stats?.mokas || 0,
            name1: PERFORMANCE_MOKAS_LABEL,
        });
    }

    if (lsaEnabled) {
        newMetrics = newMetrics.concat([
            {
                time,
                value1: stats?.lsaPotentialFills || 0,
                name1: PERFORMANCE_LSA_POTENTIAL_FILLS_LABEL,
            },
            {
                time,
                value1: stats?.lsaFills || 0,
                name1: PERFORMANCE_LSA_FILLS_LABEL,
            },
            {
                time,
                value1: stats?.lsaImps || 0,
                name1: PERFORMANCE_LSA_IMPS_LABEL,
            },
            {
                time,
                value1: stats?.lsaPodRequests || 0,
                name1: PERFORMANCE_LSA_POD_REQUESTS_LABEL,
            },
            {
                time,
                value1: stats?.lsaSlotRequests || 0,
                name1: PERFORMANCE_LSA_SLOT_REQUESTS_LABEL,
            },
        ]);
    }

    const timeMap = new Map<string, number>();
    newMetrics.forEach((metric) => {
        const key = `${metric.time}-${metric.name1}`;
        const count = timeMap.get(key) ?? 0;
        timeMap.set(key, count + 1);
    });
    const dedupedMetrics = newMetrics.filter((metric) => {
        const key = `${metric.time}-${metric.name1}`;
        const count = timeMap.get(key) ?? 0;
        const isDuplicate = count > 1;
        if (isDuplicate) {
            timeMap.set(key, count - 1);
            return false;
        }
        return true;
    });

    const newEventCount = dedupedMetrics.length - existingMetrics.length;
    if (dedupedMetrics.length > X_AXIS_TICK_COUNT * newEventCount) {
        dedupedMetrics.splice(0, newEventCount);
    }

    return dedupedMetrics;
};

/**
 * Revenue / Second
 *
 * @param existingMetrics The existing metrics for Revenue / Second
 * @param stats The current state of the stats coming in from AdStats
 * @param timeZoneCode The selected time zone code
 * @returns The Revenue per second metrics with a new set of metrics added for the current time
 */
const getRevenueMetrics = (existingMetrics, stats: SeatChartStats, timeZoneCode: string) => {
    const time = moment(stats.time).tz(timeZoneCode).format("HH:mm:ss");
    const newMetrics = [
        ...existingMetrics,
        {
            time,
            value2: stats?.netRev || 0,
            name2: NET_REVENUE_LABEL,
        },
    ];

    const timeMap = new Map<string, number>();
    newMetrics.forEach((metric) => {
        const key = `${metric.time}-${metric.name1}`;
        const count = timeMap.get(key) ?? 0;
        timeMap.set(key, count + 1);
    });
    const dedupedMetrics = newMetrics.filter((metric) => {
        const key = `${metric.time}-${metric.name1}`;
        const count = timeMap.get(key) ?? 0;
        const isDuplicate = count > 1;
        if (isDuplicate) {
            timeMap.set(key, count - 1);
            return false;
        }
        return true;
    });

    const newEventCount = dedupedMetrics.length - existingMetrics.length;
    if (dedupedMetrics.length > X_AXIS_TICK_COUNT * newEventCount) {
        dedupedMetrics.splice(0, newEventCount);
    }

    return dedupedMetrics;
};

export const PerformanceStatsGraph: FC<Props> = ({
    adStat,
    preferredCurrency,
    currencyConversions,
    currencyConversionMode,
    chartId = "live-chart",
}) => {
    const { isTremorUser } = useUserAccess();
    const { context } = useSeatAuthContext();
    const revenueType = RevenueTypes.NET_REVENUE;
    const previousStat = useRef<SeatChartStats>();
    const eventsMetric = useRef<ValueMetric1[]>([]);
    const revMetrics = useRef<ValueMetric2[]>([]);
    const timeZoneCode = useAppSelector(selectUserTimezone);
    const timeZoneCodeRef = useRef<string>();
    const previousOTimeRef = useRef<number>();

    const isTimeZoneChangeRequest = timeZoneCodeRef.current && timeZoneCode !== timeZoneCodeRef.current;
    if (isTimeZoneChangeRequest) {
        previousStat.current = undefined;
        eventsMetric.current = [];
        revMetrics.current = [];
        timeZoneCodeRef.current = undefined;
    }

    const statData = useMemo<{ chartStats: SeatChartStats; oTime: number } | undefined>(() => {
        if (!adStat) {
            return;
        }
        return {
            chartStats: {
                time: adStat.ntime,
                requests: getRequests(adStat),
                adPodRequests: getPodRequests(adStat),
                playlistRequests: getPlaylistRequests(adStat),
                fills: getFills(adStat, preferredCurrency, currencyConversionMode),
                impressions: getImpressions(adStat, preferredCurrency, currencyConversionMode),
                netRev: getTotalRevenue(
                    adStat,
                    preferredCurrency,
                    currencyConversions,
                    currencyConversionMode,
                    revenueType
                ),
                podSlotRequests: getPodSlotsRequests(adStat),
                fallThroughs: getFallThroughs(adStat),
                errors: getErrors(adStat, isTremorUser),
                mokas: getMokas(adStat),
                fallbacks: getFallbacks(adStat),
                seatRejections: getSeatRejections(adStat, isTremorUser),
                userRejections: getUserRejections(adStat),
                blackListed: getBlackListed(adStat),
                lsaPotentialFills: getLsaPotentialFills(adStat),
                lsaPodRequests: getLsaPodRequests(adStat),
                lsaFills: getLsaFills(adStat, preferredCurrency, currencyConversionMode),
                lsaSlotRequests: getLsaSlotRequests(adStat),
                lsaImps: getLsaImps(adStat, preferredCurrency, currencyConversionMode),
            },
            oTime: adStat.otime,
        };
    }, [adStat, preferredCurrency, currencyConversions, currencyConversionMode, revenueType, isTremorUser]);
    const stats = statData?.chartStats;
    const oTime = statData?.oTime;

    const haveRecievedTwoConsecutiveStats = stats && previousStat.current && oTime && previousOTimeRef.current;
    if (haveRecievedTwoConsecutiveStats) {
        const statsHaveSameTimeZone = oTime === previousOTimeRef.current;
        if (statsHaveSameTimeZone && previousStat.current) {
            const isNewStatBucket = stats.time > previousStat.current.time;
            if (isNewStatBucket) {
                const statDiff = {
                    time: stats.time,
                    requests: stats.requests - previousStat.current.requests,
                    adPodRequests: stats.adPodRequests - previousStat.current.adPodRequests,
                    playlistRequests: stats.playlistRequests - previousStat.current.playlistRequests,
                    fills: stats.fills - previousStat.current.fills,
                    impressions: stats.impressions - previousStat.current.impressions,
                    netRev: stats.netRev - previousStat.current.netRev,
                    podSlotRequests: stats.podSlotRequests - previousStat.current.podSlotRequests,
                    fallThroughs: stats.fallThroughs - previousStat.current.fallThroughs,
                    errors: stats.errors - previousStat.current.errors,
                    mokas: stats.mokas - previousStat.current.mokas,
                    fallbacks: stats.fallbacks - previousStat.current.fallbacks,
                    seatRejections: stats.seatRejections - previousStat.current.seatRejections,
                    userRejections: stats.userRejections - previousStat.current.userRejections,
                    blackListed: stats.blackListed - previousStat.current.blackListed,
                    lsaPotentialFills: stats.lsaPotentialFills - previousStat.current.lsaPotentialFills,
                    lsaPodRequests: stats.lsaPodRequests - previousStat.current.lsaPodRequests,
                    lsaFills: stats.lsaFills - previousStat.current.lsaFills,
                    lsaSlotRequests: stats.lsaSlotRequests - previousStat.current.lsaSlotRequests,
                    lsaImps: stats.lsaImps - previousStat.current.lsaImps,
                };
                eventsMetric.current = getEventMetrics(
                    eventsMetric.current,
                    statDiff,
                    isTremorUser,
                    timeZoneCode,
                    context?.lsaEnabled
                );
                revMetrics.current = getRevenueMetrics(revMetrics.current, statDiff, timeZoneCode);
            }
        }
    }

    previousStat.current = stats;
    previousOTimeRef.current = oTime;
    timeZoneCodeRef.current = timeZoneCode;

    if (!eventsMetric.current.length || !revMetrics.current.length || isTimeZoneChangeRequest) {
        return <Loading position="relative" />;
    }

    return (
        <TogglableChart
            metricOne={eventsMetric.current}
            metricTwo={revMetrics.current}
            chartRenderer={(filteredMetricOne, filteredMetricTwo) => (
                <MixCountAreaCurrencyLineChart
                    chartId={chartId}
                    metricOne={filteredMetricOne}
                    metricTwo={filteredMetricTwo}
                    metricOneYAxisTitle="Events / Sec"
                    metricTwoYAxisTitle="Rev / Sec"
                />
            )}
        />
    );
};
