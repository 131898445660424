import { Card } from "antd";
import React, { FC } from "react";
import { LabelValue } from "@app/core/services";
import { PrefilterLabel } from "@app/features/inventory/components/Fields/WaterfallPrefilteringSection/Fields";
import { InheritedPrefilterLabels } from "@app/features/inventory/components/Fields/WaterfallPrefilteringSection/InheritedPrefilterLabels/InheritedPrefilterLabels";

interface WaterfallPrefilteringSectionProps {
    inheritedPrefilterLabels?: LabelValue[];
}

export const WaterfallPrefilteringSection: FC<WaterfallPrefilteringSectionProps> = ({ inheritedPrefilterLabels }) => {
    return (
        <Card bordered={false}>
            <PrefilterLabel />
            <InheritedPrefilterLabels prefilterLabels={inheritedPrefilterLabels} />
        </Card>
    );
};
