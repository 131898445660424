import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "@app/core/store";
import {
    CREATE_DEAL_FORM_ITEMS_NAME,
    DEAL_TYPES,
    PACING_DEFAULT_ACTIVE_KEY,
    TARGETING_DEFAULT_ACTIVE_KEY,
    TRANSPARENCY_OVERRIDE_VALUE,
    TRANSPARENCY_SPECIFIC_VALUE,
    CREATE_AD_SOURCE_TYPES_ADD,
    CREATE_AD_SOURCE_TYPES_COPY,
    CREATE_AD_SOURCE_TYPES_NEW,
    ENRICHMENT_COST_DEFAULT_ACTIVE_KEY,
    AUDIENCE_LOCK_AD_SOURCE_ENABLED_ID,
} from "@app/features/deals/constants";
import { useUserAccess, useSeatAuthContext } from "@app/core/auth";

export const useDealFormCreateAdSource = () => {
    const demandSectionRef = useRef(null);
    const { context } = useSeatAuthContext();
    const { showFallbackOpportunity, isOztamEnabled } = useUserAccess();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const executeScroll = () => demandSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    const [activeSections, setActiveSections] = useState<string[] | string>([
        PACING_DEFAULT_ACTIVE_KEY,
        TARGETING_DEFAULT_ACTIVE_KEY,
        ENRICHMENT_COST_DEFAULT_ACTIVE_KEY,
    ]);

    const isAdSourceDailyImpressionCapEnabled = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED]
    );

    const dealType = useAppSelector((state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE]);
    const adSourceMode = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION]
    );
    const adSourcePacingType = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE]
    );

    const domainName = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME]
    );
    const bundleId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_BUNDLE_ID]
    );
    const includedExtendedIds = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]
    );

    const copiedAdSourceId = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID]
    );

    const isDeliveryScheduleEnabled = Boolean(
        useAppSelector(
            (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULE_ENABLED]
        )
    );

    const isGuarantee = dealType === DEAL_TYPES.GUARANTEE;
    const isAuction = dealType === DEAL_TYPES.AUCTION;
    const hasFloorSettings = isAuction;
    const adSourceTotalImpressionsIsShown = isGuarantee;
    const hasDomainNameOverride = domainName === TRANSPARENCY_OVERRIDE_VALUE;
    const hasBundleIdOverride = bundleId === TRANSPARENCY_OVERRIDE_VALUE;
    const hasExtendedUserId = includedExtendedIds === TRANSPARENCY_SPECIFIC_VALUE;
    const hasAdSourceAudienceLockEnabled = context?.audienceLockType.id === AUDIENCE_LOCK_AD_SOURCE_ENABLED_ID;
    const hasExistingAdSource =
        adSourceMode === CREATE_AD_SOURCE_TYPES_COPY || adSourceMode === CREATE_AD_SOURCE_TYPES_ADD;
    const hasCreateFields =
        adSourceMode === CREATE_AD_SOURCE_TYPES_NEW ||
        (copiedAdSourceId && adSourceMode === CREATE_AD_SOURCE_TYPES_COPY);
    const hasOztamEnabled = isOztamEnabled;
    const { isTremorUser, showAdResponsePriceOverride } = useUserAccess();
    const hasAdminOptions = hasCreateFields && isTremorUser;
    const adSourceAdResponsePriceOverrideShown = showAdResponsePriceOverride && isAuction;

    useEffect(() => {
        if (!isGuarantee) {
            setActiveSections((prevState) => {
                if (Array.isArray(prevState)) {
                    return prevState.filter((item) => item !== PACING_DEFAULT_ACTIVE_KEY);
                }
                return prevState;
            });
        } else {
            setActiveSections([
                PACING_DEFAULT_ACTIVE_KEY,
                TARGETING_DEFAULT_ACTIVE_KEY,
                ENRICHMENT_COST_DEFAULT_ACTIVE_KEY,
            ]);
        }
    }, [isGuarantee]);

    return {
        activeSections,
        isAdSourceDailyImpressionCapEnabled,
        adSourceTotalImpressionsIsShown,
        setActiveSections,
        hasFloorSettings,
        hasDomainNameOverride,
        hasBundleIdOverride,
        hasExtendedUserId,
        hasOztamEnabled,
        hasAdSourceAudienceLockEnabled,
        hasExistingAdSource,
        hasCreateFields,
        hasAdminOptions,
        demandSectionRef,
        executeScroll,
        adSourceAdResponsePriceOverrideShown,
        isProgrammaticGuaranteed: isGuarantee,
        adSourceTypeId: dealType,
        pacingTypeId: adSourcePacingType,
        showFallbackOpportunity,
        isDeliveryScheduleEnabled,
    };
};
