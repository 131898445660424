import { NONE } from "@app/core/components/constants";
import { AdUnit, Seat } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { AD_UNIT_PLAYLIST_CONFIGURATION_LABELS, FORM_ITEM_OFFSET } from "../../../constants";

const SEAT_POD_DEDUPING_LEVEL_MAP = {
    1: "High",
    2: "Medium",
    3: "Low",
};

type SeparationGroups = Pick<AdUnit, "separationGroups">;

export const FillModeRow: FC<{ name: string }> = ({ name }) => (
    <Row gutter={GUTTER}>
        <Col span={8}>
            <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.fillMode} className={FORM_ITEM_OFFSET}>
                {name}
            </Item>
        </Col>
    </Row>
);

export const PodEnforcement: FC<{ name: string }> = ({ name }) => (
    <Row gutter={GUTTER}>
        <Col span={8}>
            <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.podEnforcement} className={FORM_ITEM_OFFSET}>
                {name}
            </Item>
        </Col>
    </Row>
);

export const PodDeDupeLevel: FC<{ podDeDupeLevel?: number; seat: Seat | null }> = ({ podDeDupeLevel, seat }) => {
    if (!seat?.seatFlags) {
        return null;
    }

    const POD_DEDUPING_LEVEL_NONE_LABEL = `None (Currently set to ${
        SEAT_POD_DEDUPING_LEVEL_MAP[seat?.seatFlags?.podDeDupeMode]
    } at seat level)`;

    return (
        <Row gutter={GUTTER}>
            <Col span={8}>
                <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.podDeDupeMode} className={FORM_ITEM_OFFSET}>
                    {podDeDupeLevel ? SEAT_POD_DEDUPING_LEVEL_MAP[podDeDupeLevel] : POD_DEDUPING_LEVEL_NONE_LABEL}
                </Item>
            </Col>
        </Row>
    );
};

export const SeparationGroups: FC<{
    separationGroups: {
        id: number;
        name: string;
        iabCategories: unknown[];
        industries: unknown[];
    }[];
}> = ({ separationGroups }) => (
    <Row gutter={GUTTER}>
        <Col span={8}>
            <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.competSeparationG} className={FORM_ITEM_OFFSET}>
                {separationGroups?.length
                    ? separationGroups.map(({ name, iabCategories, id }) => (
                          <Tag key={id}>
                              {name} [Type: {iabCategories.length ? "IAB Categories" : "Industry"}]
                          </Tag>
                      ))
                    : NONE}
            </Item>
        </Col>
    </Row>
);
const { Item } = Form;

interface Props {
    adUnit: AdUnit;
    seat: Seat | null;
}

export const PlaylistConfiguration: FC<Props> = ({ adUnit, seat }) => {
    return (
        <div data-sdet="ad-unit-playlist-configuration">
            <FillModeRow name={adUnit?.adPodFillMode?.name} />
            <PodEnforcement name={adUnit?.podEnforcement?.name} />
            <PodDeDupeLevel podDeDupeLevel={adUnit?.adUnitFlags?.podDeDupeMode} seat={seat} />
            <SeparationGroups separationGroups={adUnit.separationGroups} />
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxAd} className={FORM_ITEM_OFFSET}>
                        {typeof adUnit?.maxAdsPerAdvertiser === "number" ? adUnit.maxAdsPerAdvertiser : NONE}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.playlistDef} className={FORM_ITEM_OFFSET}>
                        {adUnit?.playlistDefinitionMode?.name}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
