import { FC } from "react";
import { Seat } from "@app/core/services";
import { Card, Col, Form, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import { getYesNoLabel } from "@app/core/utils/labels";
import { INVENTORY_CARD_BODY_STYLE } from "@app/features/inventory/constants";
import { BooleanRadioGroup } from "@app/core/components/Fields/BooleanRadioGroup";
import { INVENTORY_FORM_FIELDS, SEAT_DETAILS_SECTION_COL_SIZES } from "@app/features/inventory/InventorySeat/constants";
import { ContentMetadataKeys } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields";
import { PodDedupeMode } from "../../../InventorySeatEditPage/AdvancedFeaturesForm/AdvancedFeaturesFormFields/PodDedupingLevel";

interface CTVToolsSectionProps {
    seat: Seat;
    edit?: boolean;
}

const INTERNAL_COL_SIZES = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 };

export const CTVToolsSection: FC<CTVToolsSectionProps> = ({ seat, edit = false }) => {
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const hasInternalAccess: boolean = isSysAdmin || isPubAcctMgr;
    return (
        <Card bordered={false}>
            <Row gutter={16}>
                <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                    <ContentMetadataKeys seat={seat} edit={edit} />
                    <PodDedupeMode seat={seat} edit={edit} />
                </Col>
                {hasInternalAccess && (
                    <Col {...SEAT_DETAILS_SECTION_COL_SIZES}>
                        <Card type="inner" title="Internal Only" bodyStyle={INVENTORY_CARD_BODY_STYLE}>
                            <Row gutter={16}>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.CONTENT_METADATA_ENABLED.label}
                                        name={INVENTORY_FORM_FIELDS.CONTENT_METADATA_ENABLED.name}
                                    >
                                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.contentMetadataEnabled)}
                                    </Form.Item>
                                </Col>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.AD_POD_ENABLED.label}
                                        name={INVENTORY_FORM_FIELDS.AD_POD_ENABLED.name}
                                    >
                                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.adPodEnabled)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.LINEAR_ENABLED.label}
                                        name={INVENTORY_FORM_FIELDS.LINEAR_ENABLED.name}
                                    >
                                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.linearEnabled)}
                                    </Form.Item>
                                </Col>
                                <Col {...INTERNAL_COL_SIZES}>
                                    <Form.Item
                                        label={INVENTORY_FORM_FIELDS.LSA_ENABLED.label}
                                        name={INVENTORY_FORM_FIELDS.LSA_ENABLED.name}
                                    >
                                        {edit ? <BooleanRadioGroup /> : getYesNoLabel(seat.lsaEnabled)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
