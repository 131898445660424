import {
    consoleApi,
    CONTENT_METADATA_CHANNELS_TAG,
    CONTENT_METADATA_CONTENT_LENGTHS_TAG,
    CONTENT_METADATA_GENRES_TAG,
    CONTENT_METADATA_GENRE_TOKENS_TAG,
    CONTENT_METADATA_NETWORKS_TAG,
    CONTENT_METADATA_PRODUCERS_TAG,
    CONTENT_METADATA_RATINGS_TAG,
    CONTENT_METADATA_TITLES_TAG,
    CONTENT_METADATA_TV_SERIES_TAG,
} from "./console";
import { toQueryString } from "./utils";

interface ContentMetadataEntity {
    name: string | null;
    seatId: number | null;
    seatName: string | null;
}

export const UNKNOWN = "Unknown";

export interface ContentMetadataChannels extends ContentMetadataEntity {
    type: "channel" | "Unknown";
}

export interface ContentMetadataGenres extends ContentMetadataEntity {
    type: "genre" | "Unknown";
}

export interface ContentMetadataNetworks extends ContentMetadataEntity {
    type: "network" | "Unknown";
}

export interface ContentMetadataProducers extends ContentMetadataEntity {
    type: "producer" | "Unknown";
}

export interface ContentMetadataRatings extends ContentMetadataEntity {
    type: "rating" | "Unknown";
}

export interface ContentMetadataGenreToken {
    creationDate: string;
    updateDate: string;
    id: number | null;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: "Seat";
    } | null;
    name: string;
}

export interface ContentMetadataTitles {
    videoId: string | null;
    videoTitle: string | null;
    seatId?: number | null;
    seatName?: string | null;
    type?: "title" | "Unknown";
}

export interface ContentMetadataTvSeries {
    seriesName: string | null;
    season: string | null;
    episode: string | null;
    seatId?: number | null;
    seatName?: string | null;
    type?: "series" | "Unknown";
}

export interface ContentMetadataTvSeriesValidation {
    valid: ContentMetadataTvSeries[];
    invalid: unknown[];
}

export interface ContentMetadataContentLength {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    minExclusiveLengthSec: number;
    maxInclusiveLengthSec: number;
    sort?: number;
    entityType: "VideoContentLength";
}

export interface ContentMetadataSearchParams {
    keyword?: string;
    max?: number;
    page?: number;
    addUnknown?: boolean;
    seatId: number;
}

interface ContentMetadataPayloadParams {
    seatId: number;
    body: string[];
}

const DEFAULT_PARAMS = {
    page: 1,
    max: 50,
    keyword: "",
    addUnknown: true,
};

export const contentMetadataApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getContentMetadataChannels: builder.query<ContentMetadataChannels[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/channels;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_CHANNELS_TAG, id: seatId }],
        }),
        getContentMetadataContentLengths: builder.query<ContentMetadataContentLength[], void>({
            query: () => `contentMetadata/contentLengths`,
            providesTags: [CONTENT_METADATA_CONTENT_LENGTHS_TAG],
        }),
        getContentMetadataGenres: builder.query<ContentMetadataGenres[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/genres;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_GENRES_TAG, id: seatId }],
        }),
        getContentMetadataGenreTokens: builder.query<ContentMetadataGenreToken[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/genreTokens;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_GENRE_TOKENS_TAG, id: seatId }],
        }),
        getContentMetadataNetworks: builder.query<ContentMetadataNetworks[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/networks;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_NETWORKS_TAG, id: seatId }],
        }),
        getContentMetadataProducers: builder.query<ContentMetadataProducers[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/producers;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_PRODUCERS_TAG, id: seatId }],
        }),
        getContentMetadataRatings: builder.query<ContentMetadataRatings[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/ratings;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_RATINGS_TAG, id: seatId }],
        }),
        getContentMetadataTitles: builder.query<ContentMetadataTitles[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) => {
                return `contentMetadata/search/titles;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_TITLES_TAG, id: seatId }],
        }),
        getContentMetadataTvSeries: builder.query<ContentMetadataTvSeries[], ContentMetadataSearchParams>({
            query: (params: ContentMetadataSearchParams) =>
                `contentMetadata/search/series;${toQueryString({ ...DEFAULT_PARAMS, ...params })}`,
            providesTags: (_, __, { seatId }) => [{ type: CONTENT_METADATA_TV_SERIES_TAG, id: seatId }],
        }),
        validateContentMetadataChannels: builder.mutation<ContentMetadataChannels[], ContentMetadataPayloadParams>({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateChannels;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
        validateContentMetadataGenres: builder.mutation<ContentMetadataGenres[], ContentMetadataPayloadParams>({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateGenres;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
        validateContentMetadataNetworks: builder.mutation<ContentMetadataNetworks[], ContentMetadataPayloadParams>({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateNetworks;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
        validateContentMetadataTitles: builder.mutation<ContentMetadataTitles[], ContentMetadataPayloadParams>({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateTitles;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
        validateContentMetadataTvSeries: builder.mutation<
            ContentMetadataTvSeriesValidation,
            ContentMetadataPayloadParams
        >({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateTvSeries;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
        validateContentMetadataRatings: builder.mutation<ContentMetadataRatings[], ContentMetadataPayloadParams>({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateRatings;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
        validateContentMetadataProducers: builder.mutation<ContentMetadataProducers[], ContentMetadataPayloadParams>({
            query: ({ seatId, body }: ContentMetadataPayloadParams) => {
                return {
                    url: `contentMetadata/validateProducers;seatId=${seatId}`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetContentMetadataChannelsQuery,
    useGetContentMetadataContentLengthsQuery,
    useGetContentMetadataGenreTokensQuery,
    useGetContentMetadataGenresQuery,
    useGetContentMetadataNetworksQuery,
    useGetContentMetadataProducersQuery,
    useGetContentMetadataRatingsQuery,
    useGetContentMetadataTitlesQuery,
    useGetContentMetadataTvSeriesQuery,
    useValidateContentMetadataChannelsMutation,
    useValidateContentMetadataGenresMutation,
    useValidateContentMetadataNetworksMutation,
    useValidateContentMetadataProducersMutation,
    useValidateContentMetadataRatingsMutation,
    useValidateContentMetadataTitlesMutation,
    useValidateContentMetadataTvSeriesMutation,
} = contentMetadataApi;
