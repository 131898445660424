import { css } from "@emotion/css";
import { Card, Col, Divider, Row, Typography } from "antd";
import { FC } from "react";
import { usePacingAdminDiagnostics } from "./usePacingAdminDiagnostics";

const { Text, Paragraph } = Typography;

const classListOffset = css`
    padding-left: 1rem;
`;

const classListDivider = css`
    margin: 12px 0;
`;

interface Props {
    id: number;
}

export const PacingAdminDiagnostics: FC<Props> = ({ id }) => {
    const {
        hasAccess,
        isLoading,
        totalImps,
        impsToday,
        todaysGoal,
        dailyAvails,
        lastUpdated,
        deliveryPct,
        yesterdayOpportunity,
        dailyAvailsLastUpdated,
    } = usePacingAdminDiagnostics(id);

    if (!hasAccess) {
        return null;
    }

    return (
        <Card
            data-sdet="pacing-admin-diagnostics"
            title="Admin Pacing Diagnostics"
            type="inner"
            style={{ width: "100%" }}
            bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
            loading={isLoading}
        >
            <Row align="middle" className={classListOffset}>
                <Col span={6}>Daily Avails:</Col>
                <Col span={6}>
                    <Text>Daily Avails Est.</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {dailyAvails}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    <Text>Daily Avails Last Updated</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {dailyAvailsLastUpdated}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    <Text>Yesterday Opportunity</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {yesterdayOpportunity}
                    </Paragraph>
                </Col>
            </Row>

            <Divider className={classListDivider} />

            <Row align="middle" className={classListOffset}>
                <Col span={6}>AdServer Imp Counts:</Col>
                <Col span={6}>
                    <Text>Total Imps.</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {totalImps}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    <Text>Today Imps.</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {impsToday}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    <Text>Last Updated</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {lastUpdated}
                    </Paragraph>
                </Col>
            </Row>

            <Divider className={classListDivider} />

            <Row align="middle" className={classListOffset}>
                <Col span={6}>Calculation Status:</Col>
                <Col span={6}>
                    <Text>Delivery Pct.</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {deliveryPct}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    <Text>Today’s Goal</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {todaysGoal}
                    </Paragraph>
                </Col>
                <Col span={6}>
                    <Text>Last Historical Stats Update</Text>
                    <Paragraph strong style={{ marginBottom: 0 }}>
                        {lastUpdated}
                    </Paragraph>
                </Col>
            </Row>
        </Card>
    );
};
