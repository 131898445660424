import { DeleteOutlined, FilterFilled, PlusOutlined } from "@ant-design/icons";
import { InventoryDistributionGroup, Label } from "@app/core/services";
import { VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Button, Card, Col, Form, InputNumber, Row, Space } from "antd";
import { useDistributionGroupsOptions } from "./useDistributionGroupsOptions";
import { FC } from "react";

interface Props {
    isEditing: boolean;
    isHidden: boolean;
    label: string;
    name: string;
    onChange?: (values: InventoryDistributionGroup[]) => void;
    value: InventoryDistributionGroup[];
    isFixedAuctionPrice?: boolean;
}

const getThrottleCapFieldLabel = (index, isFixedAuctionPrice) => {
    if (index !== 0) {
        return undefined;
    }
    if (isFixedAuctionPrice) {
        return "Throttle";
    }
    return "Impressions";
};

export const InventoryDistributionGroupsField: FC<Props> = ({
    isEditing,
    isHidden,
    label,
    name,
    onChange,
    value,
    isFixedAuctionPrice,
}) => {
    const {
        handleAddGroup,
        handleRemoveGroup,
        handleUpdateGroupImpressions,
        handleUpdateGroupLabel,
        isFetching,
        options,
        valueIdSet,
    } = useDistributionGroupsOptions(value, onChange);

    if (isHidden) {
        return null;
    }

    return (
        <Form.Item label={label} {...VERTICAL_LAYOUT_FIX}>
            <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Form.List name={name} {...VERTICAL_LAYOUT_FIX}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, i) => {
                                    const cur = value[i];
                                    return (
                                        <Row gutter={[8, 8]} key={field.key}>
                                            <Col xs={isEditing ? 12 : 11}>
                                                <Form.Item
                                                    label={i === 0 ? "Label" : undefined}
                                                    name={[field.name, "labelValue"]}
                                                    rules={[{ required: true, message: "Label is required" }]}
                                                    style={{ marginBottom: 8 }}
                                                    {...VERTICAL_LAYOUT_FIX}
                                                >
                                                    <SelectWithValue<{ id: number; label: Label | null; value: string }>
                                                        style={{ width: "100%" }}
                                                        disabled={isEditing}
                                                        fieldAsLabel={(option) => (
                                                            <>
                                                                {option.label?.enablePrefilter && <FilterFilled />}{" "}
                                                                {option.label?.key}
                                                            </>
                                                        )}
                                                        fieldAsValue={(option) => Number(option.label?.id)}
                                                        options={options.map((option) => ({
                                                            ...option,
                                                            disabled:
                                                                valueIdSet.has(option.label.id) &&
                                                                cur?.labelValue?.label?.id !== option.label.id,
                                                        }))}
                                                        loading={isFetching}
                                                        placeholder="Select…"
                                                        onChange={(value) => handleUpdateGroupLabel(i, value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={isEditing ? 12 : 11}>
                                                <Form.Item
                                                    label={getThrottleCapFieldLabel(i, isFixedAuctionPrice)}
                                                    name={[field.name, isFixedAuctionPrice ? "throttle" : "cap"]}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (typeof value !== "number") {
                                                                    return Promise.reject("Impressions is required");
                                                                }
                                                                if (value <= 0) {
                                                                    return Promise.reject(
                                                                        "Impressions must be greater than 0"
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                    style={{ marginBottom: 8 }}
                                                    {...VERTICAL_LAYOUT_FIX}
                                                >
                                                    <InputNumber
                                                        placeholder="Enter Impressions"
                                                        style={{ width: "100%" }}
                                                        onChange={(value) => handleUpdateGroupImpressions(i, value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {!isEditing && (
                                                <Col xs={2}>
                                                    <Form.Item
                                                        label={i === 0 ? " " : undefined}
                                                        style={{ marginBottom: 8 }}
                                                        {...VERTICAL_LAYOUT_FIX}
                                                    >
                                                        <Button
                                                            onClick={() => handleRemoveGroup(remove, field.name, i)}
                                                            icon={<DeleteOutlined />}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Row>
                                    );
                                })}
                                {!isEditing && (
                                    <Button
                                        type="link"
                                        onClick={() => handleAddGroup(add)}
                                        icon={<PlusOutlined />}
                                        disabled={!options.length}
                                    >
                                        Add Distribution Group
                                    </Button>
                                )}
                            </>
                        )}
                    </Form.List>
                </Space>
            </Card>
        </Form.Item>
    );
};
