import { Moment } from "moment-timezone";
import { format } from "@rubicon/utils";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { END_DATE_INDEX, FIRST_SCHEDULE_INDEX, MINIMUM_SCHEDULES, SCHEDULE, START_DATE_INDEX } from "./constants";
import { FormPacingDeliverySchedule } from "./types";
import { sortMiddleSchedules } from "./utils";
const {
    PACING_DELIVERY_SCHEDULE_DATES: { label: DATE_RANGE },
    PACING_DELIVERY_SCHEDULE_IMPRESSION_GOAL: { label: IMPRESSION_GOAL },
    START_DATE: { label: START_DATE },
    END_DATE: { label: END_DATE },
    TOTAL_IMPRESSIONS: { label: TOTAL_IMPRESSIONS },
} = AD_SOURCE_FIELDS;

export const validateAtLeastTwoSchedules = async (schedules: FormPacingDeliverySchedule[]): Promise<void> => {
    // the UI shouldn't allow this to happen but leave here for debugging purposes
    const hasMissingSchedules = schedules.length < MINIMUM_SCHEDULES;
    if (hasMissingSchedules) {
        throw new Error(`At least ${MINIMUM_SCHEDULES} ${SCHEDULE}s are required.`);
    }
};

export const validateScheduleDates = async (
    schedules: FormPacingDeliverySchedule[],
    adSourceStartDate: Moment | null,
    adSourceEndDate: Moment | null,
    setOverlappingDateRanges: (overlappingDateRows: Set<number>) => void
): Promise<void> => {
    setOverlappingDateRanges(new Set<number>());

    const hasMissingDates = schedules.some(({ dates: [startDate, endDate] }) => !startDate || !endDate);
    if (hasMissingDates) {
        // don't throw here because inline field validation on the date picker is sufficient
        return;
    }

    const sortedDates = sortMiddleSchedules(schedules).map(({ dates }) => dates) as [Moment, Moment][];

    const overlappingDateRanges: Set<number> = sortedDates.reduce((scheduleIndexSet, [_, endDate], scheduleIndex) => {
        const nextScheduleIndex = scheduleIndex + 1;
        const nextStartDate = sortedDates[nextScheduleIndex]?.[START_DATE_INDEX];

        if (!nextStartDate) {
            return scheduleIndexSet;
        }

        const endDateStartOfHour = endDate.clone().startOf("hour");
        if (endDate.isSame(endDateStartOfHour) && endDate.isSameOrBefore(nextStartDate)) {
            return scheduleIndexSet;
        }

        const endDateNextWholeHour = endDateStartOfHour.clone().add(1, "hour");
        const isOverlapping = endDateNextWholeHour.isAfter(nextStartDate);
        if (isOverlapping) {
            scheduleIndexSet.add(scheduleIndex);
            scheduleIndexSet.add(nextScheduleIndex);
        }

        return scheduleIndexSet;
    }, new Set<number>());

    if (overlappingDateRanges.size) {
        setOverlappingDateRanges(overlappingDateRanges);
    }

    if (overlappingDateRanges.size) {
        throw new Error(
            `${SCHEDULE} ${DATE_RANGE}s cannot overlap. ${SCHEDULE} ${START_DATE}s must start in the next whole hour after the previous ${SCHEDULE}'s ${END_DATE}.`
        );
    }

    // the UI shouldn't allow this to happen but leave here for debugging purposes
    const firstScheduleStartDate = sortedDates[FIRST_SCHEDULE_INDEX][START_DATE_INDEX];
    if (!firstScheduleStartDate.isSame(adSourceStartDate)) {
        throw new Error(`The earliest ${SCHEDULE}'s ${START_DATE} must equal the Ad Source's ${START_DATE}.`);
    }

    // the UI shouldn't allow this to happen but leave here for debugging purposes
    const lastScheduleEndDate = sortedDates[schedules.length - 1][END_DATE_INDEX];
    if (!lastScheduleEndDate.isSame(adSourceEndDate)) {
        throw new Error(`The latest ${SCHEDULE}'s ${END_DATE} must equal the Ad Source's ${END_DATE}.`);
    }
};

export const validateAllOrNoneImpressionGoals = async (
    schedules: FormPacingDeliverySchedule[],
    totalImpressions: number | null | undefined
): Promise<void> => {
    const impressionGoals = schedules.map(({ impressionGoal }) => impressionGoal);
    const hasSomeImpressionGoals = impressionGoals.some(Boolean);
    const hasAllImpressionGoals = impressionGoals.every(Boolean);

    const hasIncompleteImpressionGoals = hasSomeImpressionGoals && !hasAllImpressionGoals;
    if (hasIncompleteImpressionGoals) {
        // don't throw here because inline field validation on the input is sufficient
        return;
    }

    const scheduleImpressionGoalSum = impressionGoals.reduce(
        (sum, impressionGoal) => Number(sum) + Number(impressionGoal),
        0
    );
    const hasInvalidImpressionGoalsSum = hasAllImpressionGoals && totalImpressions !== scheduleImpressionGoalSum;
    if (hasInvalidImpressionGoalsSum) {
        throw new Error(
            `When any ${SCHEDULE} has ${IMPRESSION_GOAL}s set, ${IMPRESSION_GOAL}s are required for every ${SCHEDULE} and must sum to the Ad Source / Deal's ${TOTAL_IMPRESSIONS}. (${scheduleImpressionGoalSum} ${SCHEDULE} Impressions, ${format.asSelf(
                totalImpressions
            )} Ad Source Impressions)`
        );
    }
};

export const validateDateRangeIsRequired = async ([
    startDate,
    endDate,
]: FormPacingDeliverySchedule["dates"]): Promise<void> => {
    if (!startDate && !endDate) {
        throw new Error(`${START_DATE} and ${END_DATE} is required.`);
    }
    if (!startDate) {
        throw new Error(`${START_DATE} is required.`);
    }
    if (!endDate) {
        throw new Error(`${END_DATE} is required.`);
    }
};

export const validateDateRangeIsOverlapping = async (dateRangeIsOverlapping: boolean) => {
    if (dateRangeIsOverlapping) {
        throw new Error(`${DATE_RANGE}s cannot overlap.`);
    }
};

export const validateEndDateIsAfterStartDate = async ([
    startDate,
    endDate,
]: FormPacingDeliverySchedule["dates"]): Promise<void> => {
    if (!startDate || !endDate) {
        return;
    }

    if (endDate.isSameOrBefore(startDate)) {
        throw new Error(`${END_DATE} must be later than ${START_DATE}.`);
    }
};

export const validateImpressionGoalIsRequired = async (
    impressionGoal: number | null,
    isRequired: boolean
): Promise<void> => {
    if (!impressionGoal && isRequired) {
        throw new Error(`${IMPRESSION_GOAL} is required.`);
    }
};
