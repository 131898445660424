import { FC } from "react";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { Card, Col, Row } from "antd";
import { MinMaxDurationSelect } from "@rubicon/components-streaming";
import { CREATE_DEAL_FORM_ITEMS_NAME, GUTTER } from "@app/features/deals/constants";
import {
    AdSourceEndDateField,
    AdSourceInventoryDistributionGroupsField,
    AdSourceStartDateField,
    AdSourceTimeZoneField,
    AdSourceTotalImpressionsField,
} from "./Fields";
import { DealFormMode } from "../../../types";

const { AD_SOURCE_MIN_DURATION, AD_SOURCE_MAX_DURATION } = CREATE_DEAL_FORM_ITEMS_NAME;

export interface AdSourceDeliveryDetailsSectionProps {
    adSourceTotalImpressionsIsShown: boolean;
    mode: DealFormMode;
}

export const AdSourceDeliveryDetailsSection: FC<AdSourceDeliveryDetailsSectionProps> = ({
    adSourceTotalImpressionsIsShown,
    mode,
}) => {
    return (
        <Card
            bordered={false}
            styles={{ body: cardBodyStyle }}
            data-sdet="deal-form-ad-source-delivery-details-section"
        >
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={6} xl={4} xxl={3}>
                    <AdSourceStartDateField />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={4} xxl={3}>
                    <AdSourceEndDateField />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={18}>
                    <AdSourceTimeZoneField />
                </Col>
                {adSourceTotalImpressionsIsShown && (
                    <Col span={24}>
                        <AdSourceTotalImpressionsField />
                    </Col>
                )}
            </Row>
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                    <MinMaxDurationSelect
                        name={AD_SOURCE_MIN_DURATION}
                        type="min"
                        oppositeName={AD_SOURCE_MAX_DURATION}
                        min={5}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                    <MinMaxDurationSelect
                        name={AD_SOURCE_MAX_DURATION}
                        type="max"
                        oppositeName={AD_SOURCE_MIN_DURATION}
                    />
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                    <AdSourceInventoryDistributionGroupsField mode={mode} />
                </Col>
            </Row>
        </Card>
    );
};
