import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FORM_FIELDS } from "./constants";
import { LabeledValue } from "antd/lib/select";
import { FormInstance, notification } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { DEFAULT_FALLBACK_MESSAGE } from "@app/features/inventory/constants";
import { BrandSupply, useUpdateSupplyBrandSafetyMutation } from "@app/core/services/console";
import { useSeatTree } from "@app/features/inventory/InventoryBrandSafety/SeatTree/useSeatTree";
import { setEditActiveKey, setIsActionsDisabled } from "@app/features/inventory/InventoryBrandSafety/reducer";

export interface SupplyDetailsFormValues {
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: LabeledValue[];
    [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: string;
    [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: LabeledValue | null;
}

interface UseSupplyBrandSafetyEditForm {
    supplyForm: FormInstance<SupplyDetailsFormValues>;
    handleSubmit: () => void;
    handleDelete: () => void;
    resetFormsFields: () => void;
    initialValues: Store | undefined;
    isDeleting: boolean;
    isUpdating: boolean;
}

export const getInitialValuesFromSupply = (supply: BrandSupply | null): Store | undefined => {
    if (!supply) return undefined;

    return {
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAIN_LISTS.name]: supply.seatAdvertiserDomainFilterListDefs.map((list) => ({
            value: list.id,
            label: list.name,
        })),
        [FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: supply.advertiserBlockDomains.join("\n"),
        [FORM_FIELDS.BLOCKED_BUYER_SEAT_LIST.name]: supply.buyerSeatList
            ? { value: supply.buyerSeatList.id, label: supply.buyerSeatList.name }
            : null,
    };
};

export const useSupplyBrandSafetyEditForm = (supply: BrandSupply): UseSupplyBrandSafetyEditForm => {
    const dispatch = useDispatch();
    const { supplyForm, resetFormsFields } = useSeatTree();
    const initialValues = getInitialValuesFromSupply(supply);
    const [updateSupplyBrandSafety] = useUpdateSupplyBrandSafetyMutation();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleSubmit = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsUpdating(true);
        const { blockedAdvertiserDomainLists, blockedAdvertiserDomains, blockedBuyerSeatList } =
            supplyForm.getFieldsValue(Object.values(FORM_FIELDS).map((field) => field.name));
        try {
            await updateSupplyBrandSafety({
                id: supply.id,
                advertiserBlockDomains: blockedAdvertiserDomains.length ? blockedAdvertiserDomains?.split("\n") : null,
                buyerSeatList: blockedBuyerSeatList ? { id: blockedBuyerSeatList.value as number } : null,
                seatAdvertiserDomainFilterListDefs: blockedAdvertiserDomainLists.length
                    ? blockedAdvertiserDomainLists.map(({ value }) => ({ id: value as number }))
                    : null,
            }).unwrap();
            dispatch(setEditActiveKey(null));
            notification.success({ message: "Supply updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            resetFormsFields();
            dispatch(setIsActionsDisabled(false));
            setIsUpdating(false);
        }
    };

    const handleDelete = async (): Promise<void> => {
        dispatch(setIsActionsDisabled(true));
        setIsDeleting(true);
        try {
            await updateSupplyBrandSafety({
                id: supply.id,
                buyerSeatList: null,
                advertiserBlockDomains: [],
                seatAdvertiserDomainFilterListDefs: [],
            }).unwrap();
            notification.success({ message: "Supply updated successfully" });
        } catch (error) {
            notification.error({ message: error.data.errorDescription || DEFAULT_FALLBACK_MESSAGE });
        } finally {
            dispatch(setIsActionsDisabled(false));
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        if (initialValues && !isUpdating) {
            supplyForm.setFieldsValue(initialValues);
        }
    }, [initialValues, isUpdating, supplyForm]);

    return {
        supplyForm,
        isUpdating,
        isDeleting,
        handleDelete,
        handleSubmit,
        resetFormsFields,
        initialValues,
    };
};
