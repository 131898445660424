import { Form } from "antd";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { FormPacingDeliverySchedule } from "../PacingDeliverySchedule";

const { useWatch } = Form;

interface UsePacingFormData {
    adSourceTypeId: number | undefined;
    pacingTypeId: number | undefined;
    isDailyImpressionEnabled: boolean | undefined;
    totalImpressions: number | undefined;
    deliverySchedules: FormPacingDeliverySchedule[] | undefined;
    isDeliveryScheduleEnabled: boolean | undefined;
}

export const usePacingFormData = (): UsePacingFormData => {
    const adSourceTypeId = useWatch(AD_SOURCE_FIELDS.TYPE.name)?.id;
    const pacingTypeId = useWatch(AD_SOURCE_FIELDS.PACING_TYPE.name)?.id;
    const isDailyImpressionEnabled = useWatch(AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP_ENABLED.name);
    const totalImpressions = useWatch(AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name);
    const deliverySchedules = useWatch(AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULES.name);
    const isDeliveryScheduleEnabled = useWatch(AD_SOURCE_FIELDS.PACING_DELIVERY_SCHEDULE_ENABLED.name);

    return {
        adSourceTypeId,
        pacingTypeId,
        isDailyImpressionEnabled,
        totalImpressions,
        deliverySchedules,
        isDeliveryScheduleEnabled,
    };
};
