import { useMemo } from "react";
import { ColumnKey, DemandTableRecord } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";
import moment from "moment-timezone";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { format } from "@rubicon/utils";

type CompareFunction = (a: DemandTableRecord, b: DemandTableRecord) => number;
const { DEFAULT_DASHES } = format.constants;
const extractNumberIfExists = (value: string | number): number | string => {
    if (typeof value === "number") {
        return value;
    }

    const extractedNumber = Number(value.match(/\d/g)?.join(""));
    return isNaN(extractedNumber) ? value : extractedNumber;
};

export const useDemandDealsTable = <T extends { searchIndex: string }>(dataSource: T[], searchTerm: string): T[] => {
    return useMemo(() => {
        const search = searchTerm.toLowerCase().trim();
        return dataSource.filter((item) => item.searchIndex.toLowerCase().includes(search));
    }, [dataSource, searchTerm]);
};

export const sortDeals = <T>(
    deals: T[],
    sortField: { columnKey: string; order: "ascend" | "descend" },
    compareFunctions: Record<string, (a: T, b: T) => number>
): T[] => {
    const sortOrderMultiplier = sortField.order === "ascend" ? 1 : -1;
    const compareFunction = compareFunctions[sortField.columnKey];
    if (compareFunction) {
        return deals.sort((a, b) => compareFunction(a, b) * sortOrderMultiplier);
    }
    return deals;
};

export interface SortField {
    columnKey: string;
    order: "ascend" | "descend";
}

export const compareFunctionsByColumnKey: Record<Exclude<ColumnKey, "currency" | "actions" | "ec">, CompareFunction> = {
    name: (a, b) => a.name.localeCompare(b.name),
    network: (a, b) => a.network.localeCompare(b.network),
    buyerSeat: (a, b) => {
        const buyerSeatA = a.buyerSeat;
        const buyerSeatB = b.buyerSeat;

        if (buyerSeatA === DEFAULT_DASHES && buyerSeatB === DEFAULT_DASHES) return 0;
        if (buyerSeatA === DEFAULT_DASHES) return 1;
        if (buyerSeatB === DEFAULT_DASHES) return -1;

        return a.buyerSeat.localeCompare(b.buyerSeat);
    },
    rate: (a, b) => {
        const aRate = extractNumberIfExists(a.rate || 0);
        const bRate = extractNumberIfExists(b.rate || 0);

        const aRateIsNumber = typeof aRate === "number";
        const bRateIsNumber = typeof bRate === "number";

        if (aRateIsNumber && bRateIsNumber) return bRate - aRate;

        if (!aRateIsNumber && !bRateIsNumber) return aRate.localeCompare(bRate);
        if (!aRateIsNumber) return 1;
        if (!bRateIsNumber) return -1;

        return 0;
    },
    startDate: (a, b) => {
        const dateA = moment(a.startDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
        const dateB = moment(b.startDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);

        if (dateA.isBefore(dateB)) return 1;
        if (dateA.isAfter(dateB)) return -1;

        return 0;
    },
    endDate: (a, b) => {
        const dateA = moment(a.endDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
        const dateB = moment(b.endDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);

        const dateAValid = dateA.isValid();
        const dateBValid = dateB.isValid();

        if (!dateAValid && !dateBValid) return String(a.endDate).localeCompare(String(b.endDate));
        if (!dateAValid) return 1;
        if (!dateBValid) return -1;

        if (dateA.isBefore(dateB)) return 1;
        if (dateA.isAfter(dateB)) return -1;

        return 0;
    },
    freqCapping: (a, b) => a.freqCapping.localeCompare(b.freqCapping) * -1,
    targeting: (a, b) => a.targeting.localeCompare(b.targeting) * -1,
    internalStatus: (a, b) => a.internalStatus.localeCompare(b.internalStatus),
    buyerStatus: (a, b) => {
        const buyerStatusA = a.buyerStatus;
        const buyerStatusB = b.buyerStatus;

        if (buyerStatusA === DEFAULT_DASHES && buyerStatusB === DEFAULT_DASHES) return 0;
        if (buyerStatusA === DEFAULT_DASHES) return 1;
        if (buyerStatusB === DEFAULT_DASHES) return -1;

        return a.buyerStatus.localeCompare(b.buyerStatus);
    },
} as const;
